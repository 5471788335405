import { DrawerDefault, SearchEmpty } from "@kentarepo/kcf-assets";
import React from "react";
import { IDownloadsByHearingFile } from "./interface";
import { ContentList } from "./styles";
import { handleList } from "./constants";
import { usePermissions } from "../../hooks/permission";
import { FORMS_HEARING } from "../../utils/entities/permission";

export const DownloadsByHearingFile: React.FC<IDownloadsByHearingFile> = ({
  id,
  title,
  openDrawer,
  emptyMessage,
  listDownloads,
  tooltipMessage,
  setItemClicked,
  setOpenDrawer,
  onApply,
  onClose,
}) => {

  const {    
    export: exportFiles, 
  } = usePermissions(FORMS_HEARING.HEARING_FILE);

  return (
    <DrawerDefault
      id={id}
      title={title}
      onApply={onApply}
      onClose={onClose}
      openDrawer={openDrawer}
      setOpenDrawer={setOpenDrawer}
      haveFooter={false}
      paddingleft={20}
      paddingright={20}
      heightBody={78}
      children={
        <>
          {exportFiles && listDownloads && listDownloads.length > 0 ? (
            <ContentList>
              {handleList(listDownloads, setItemClicked, tooltipMessage)}
            </ContentList>
          ) : (
            <SearchEmpty typeIcon="file" textMessage={emptyMessage} />
          )}
        </>
      }
    />
  );
};
