import { Box } from "@mui/material";
import styled from "styled-components";

export const ContainerAdmin = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ContainerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35vh;
`;