import React, { useState } from "react";
import {
  DivEmail,
  DivLogo,
  LeftFormContainer,
  LinkContainer,
  LoadingContainer,
} from "./styles";
import {
  Button,
  LoadingComponent,
  TextLink,
  Grid,
  Multiline,
  BaseLabel,
  SimpleToast,
} from "@kentarepo/kcf-assets";
import { Axios } from "axios";
import {
  customerCertificateServices,
  stringFormat,
} from "@kentarepo/kcf-services";
import { LogOperation } from "../../../utils/entities/logOperation";

type statusToast = "success" | "warning" | "error";

interface ICustomerCertificate {
  data: boolean;
  errors: [];
  message: string;
  ok: boolean;
}

export const CertificateForm: React.FC<{
  api: Axios;
  navigate?: any;
  navigateTo?: string;
  url?: string;
}> = ({ api, navigate, navigateTo }) => {
  const [loading, _setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [value, setValue] = useState<string>("");

  const [triggerToast, setTriggerToast] = useState<number>(0);
  const [toastMessage, setToastMessage] = useState("");
  const [typeToast, setTypeToast] = useState<statusToast>("warning");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (stringFormat.isEmptyOrSpaces(value)) {
      setToastMessage("Preencha o campo destacado");
      setTypeToast("error");
      setError(true);
      setTriggerToast(triggerToast + 1);
      return;
    } else {
      setError(false);
      const customerCertificate = customerCertificateServices(api);

      const data: ICustomerCertificate =
        await customerCertificate.importCertificate({
          textCrypted: value,
        });

      if (data.ok) {
        setToastMessage(data.message);
        setTypeToast("success");
        setTriggerToast(triggerToast + 1);
        if (navigate) navigate(navigateTo ?? "/");
        await LogOperation.postLogOperation("CustomerCertificate", 7, 7);
      } else {
        setToastMessage(data.message);
        setTypeToast("error");
        setTriggerToast(triggerToast + 1);
        return;
      }
    }
  };

  return (
    <LeftFormContainer>
      <SimpleToast
        trigger={triggerToast}
        id="simpleToastCertificate"
        title="Certificado"
        description={toastMessage}
        type={typeToast}
        handleCloseToast={() => setTriggerToast(0)}
      />
      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid alignContent="center">
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              //marginTop: "-54px",
              //marginTop: "-30px",
              marginTop: "-23px",
            }}
          >
            <DivLogo>
              <BaseLabel
                children={"Importar certificado"}
                fontSize="18px"
                fontWeight="700"
                marginBottom="14px"
              />
              <BaseLabel
                children={"Informe código do certificado"}
                fontSize="14px"
                fontWeight="500"
                marginBottom="150px"
              />
            </DivLogo>
            <DivEmail>
              <Multiline
                label=""
                name=""
                onChange={(e) => setValue(e)}
                value={value}
                showLabelBase={false}
                marginBottom="30px"
                error={error}
                height="464px"
              />
            </DivEmail>
            <Button id="sign-in_button" type="submit" marginBottom="16px">
              Continuar
            </Button>
          </Grid>
        </Grid>
      </form>
      <LinkContainer type="submit" onClick={() => navigate("/")}>
        <TextLink>Voltar ao login</TextLink>
      </LinkContainer>
      {loading && (
        <LoadingContainer>
          <LoadingComponent loading={loading} />
        </LoadingContainer>
      )}
    </LeftFormContainer>
  );
};
