import React, { Fragment, useEffect, useState } from "react";

import { Container, Content } from "./styles";
import { IListRecorded } from "./interface";
import { statusRecorded, ticksToTime } from "../LeftList/util";
import { CreateRecord } from "../../cruds/Drawer/CreateRecord";
import { AxiosInstance } from "axios";
import { PageHeader, RecordCard, SearchEmpty } from "@kentarepo/kcf-assets";
import { SessionLocalStorage } from "@kentarepo/kcf-templates";
import { formatDateTime, recordersServices } from "@kentarepo/kcf-services";
import { usePermissions } from "../../../hooks/permission";
import { FORMS_HEARING } from "../../../utils/entities/permission";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { useQuery } from "../../../utils/get";
import { hearingFileService } from "../../../services/hearingFile";
import { LoadSpinner } from "../../LoadSpinner";

const label = "Gravações";

export const ListRecorded: React.FC<IListRecorded> = ({
  audienceId,
  placeId,
  api,
  selectedRecordId,
  setData,
  setSelectRecord,
  addToast,
  disabled,
  showAddButton = true,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [recordedList, setRecordedList] = useState<any[]>([]);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [date, setDate] = useState<string | undefined>();
  const [time, setTime] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const { read, create } = usePermissions(FORMS_HEARING.HEARING_FILE);
  const dispatch = useDispatch();
  const query = useQuery();

  useEffect(() => {
    (async () => {
      const hearingFileId = query.get("hearingFileId");
      setRecordedList([]);
      dispatch(showLoading());
      if (audienceId && placeId) {
        const apiService = recordersServices(api);
        const { data, success } = await apiService.getListByAudienceId({
          audienceId,
        });

        if (success) {
          if (setData) {
            setData(data);
          }

          setRecordedList(data?.hearingInProgress);

          if (hearingFileId) {
            const hearingFileFinded: any = data?.hearingInProgress?.find(
              (x) => x.id === Number(hearingFileId)
            );

            if (hearingFileFinded && setSelectRecord)
              setSelectRecord(hearingFileFinded);
          }
        }
        dispatch(hideLoading());
      }
    })();
  }, [audienceId, placeId, openDrawer]);

  const handleGetUrlByHearingFileId = async (item: any) => {
    setLoading(true);
    try {
      const url = await hearingFileService.getUrlById(item.id);

      item.filename = url.body.filename;

      if (setSelectRecord) {
        setSelectRecord(item);
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Container>
        <PageHeader
          quantity={read ? recordedList?.length : 0}
          id="header"
          label={label}
          background="secondary"
          addClick={
            audienceId && showAddButton && create
              ? () => setOpenDrawer(true)
              : undefined
          }
          disabled={disabled}
        />

        {read ? (
          <Content>
            {recordedList?.length ? (
              recordedList.map((item, index) => (
                <RecordCard
                  key={index}
                  selected={item?.id === selectedRecordId}
                  duration={ticksToTime(item.duration)}
                  title={formatDateTime.getFullDate(item.startDate)}
                  subTitle={formatDateTime.getFullTime(item.startDate)}
                  labelStatus={statusRecorded(item.status).status}
                  type={statusRecorded(item.status).type}
                  onClick={
                    !disabled && setSelectRecord
                      ? () => handleGetUrlByHearingFileId(item)
                      : undefined
                  }
                  disabled={read ? disabled : true}
                />
              ))
            ) : (
              <SearchEmpty
                textMessage="Nenhuma gravação ainda"
                typeIcon="video"
                marginTop="40px"
              />
            )}
          </Content>
        ) : (
          <SearchEmpty
            textMessage="Sem permissão para seu perfil"
            typeIcon="avatar"
            marginTop="40px"
          />
        )}
      </Container>
      <CreateRecord
        addToast={addToast}
        title={label.toLocaleLowerCase()}
        api={api as AxiosInstance}
        isOpen={create ? openDrawer : false}
        data={{
          hearingId: audienceId,
          userId: SessionLocalStorage.getUserCustomerId() || 0,
        }}
        isEdit={false}
        setClose={() => setOpenDrawer(false)}
        date={date}
        setDate={setDate}
        time={time}
        setTime={setTime}
      />
      <LoadSpinner loading={loading} />
    </Fragment>
  );
};
