/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateInput, Switch } from "@kentarepo/kcf-assets";
import { AccessValidated, ContainerFormOneLabelSwitch, ContainerVaras } from "../styles";
import { useEffect, useState } from "react";
import { DropdownGlobal } from "../../../../../components/DropdownGlobal";

import { AttendantRoleService } from "../../../../../services/attendantRole";
import { returnResponse } from "../../../../../services/utils";
import { AxiosError } from "axios";
import DateValidate from "../../../../../utils/dateValidate";

interface UserCreateProps {
  currentSelectedType?: any;
  customerId?: any;
  setProfileId: (id: number) => unknown;
  setUnitLabel: (value: any) => void;
  expiredDate: any;
  setExpiredDate: (date: any) => unknown;
  active: boolean;
  onChangeSwitch: (value: any) => void;
  onSelectType: (value: any) => void;
  setProfileLabel: (value: any) => void;
  userId?: any;
  isEditCreated: boolean;
  unitLabel?: string;
  isEdit: boolean;
  profileLabel: string | undefined
  selectedType: number | undefined
  setSelectedType: (e: number | undefined) => void
  setCourtLabel: (e: string | undefined) => void
  setCourtId: (e: number| undefined) => void
  courtLabel: string | undefined
  userIdEdit: number
  requiredCourt:boolean
  requiredUnit:boolean
  requiredProfile:boolean
  setUnitOptions:(e:any)=>void
}

export function FormVinculeUnit(props: UserCreateProps) {
  const [newData, setNewData] = useState<any[]>([]);

  const [listProfiles, setListProfiles] = useState<any[]>([]);

  async function getDataUnit() {
    const { data } = await AttendantRoleService.getAllPlace(
      props?.customerId,
      props.userId!
    );
    setNewData(data.data);
  }
  const isUserId = props.userIdEdit ?? undefined
  
  const type = props?.selectedType ?? undefined

  const getProfiles = async () => {
    try {
      const { body, status } = await AttendantRoleService.getAllByCustomerId2(
        props?.customerId,
        isUserId,
        type,
      );
      
      if (body === undefined) {
        props.setUnitOptions([])
      } else {
        props.setUnitOptions(props.selectedType === 1 ? body : body[0].districts)
      }
      
      if (status) {
        setListProfiles(body);
      }

    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  };

  useEffect(() => {
    getDataUnit();
  }, [])

  useEffect(() => {
    if (props.selectedType !== null) {
      getProfiles()
    }
  }, [props.selectedType, props.currentSelectedType]);

  const filteredDistrict = (districts: any) => {
    if (!districts) {
      return []
    }
    const filteredDistrict = districts.find((district: any) => district.name === props.unitLabel);
    return filteredDistrict ? filteredDistrict.courts : [];
  }

  const unitOption: any = () => {
    if (props.selectedType === null) {
      return []
    }
    if (props.selectedType === 1) {
      return listProfiles
    } else {
      return listProfiles ? listProfiles[0]?.districts : []
    }
  }

  return (
    <ContainerVaras>
      <ContainerFormOneLabelSwitch>
        <Switch
          checked={props.active}
          onChange={() => props.onChangeSwitch(!props.active)}
          label="Ativo"
        />
      </ContainerFormOneLabelSwitch>

      <DropdownGlobal
        labelName="Perfil"
        wantAnObject
        options={newData}
        error={props.requiredProfile}
        required
        editDropdown="Selecione"
        defaultValue={props.profileLabel ?? "Selecione"}
        showDefaultValue
        disabled={props.isEdit && props.isEditCreated}
        width="380px"
        setSelected={(item: any) => {
          props.setSelectedType(item.type)
          props.setProfileLabel(item.name);
          props.setProfileId(item.id);
          props.setUnitLabel(undefined);
          props.onSelectType(undefined);
          props.setCourtLabel(undefined);
          props.setCourtId(undefined);
        }}
      />

      <DropdownGlobal
        labelName={props.selectedType === 1 ? 'Unidades' : 'Comarcas'}
        wantAnObject
        options={unitOption() || []}
        error={props.requiredUnit}
        required
        editDropdown="Selecione"
        defaultValue={props.unitLabel ?? "Selecione"}
        showDefaultValue
        disabled={props.isEdit && props.isEditCreated}
        width="380px"
        setSelected={(selectedItem: any) => {
          props.setUnitLabel(selectedItem?.name);
          props.onSelectType(selectedItem?.id);
          props.setCourtLabel(undefined);
          props.setCourtId(undefined);
        }}
      />

      {(props.selectedType === 0 && listProfiles?.length > 0||props.selectedType === 0 && props.isEditCreated) &&
        <DropdownGlobal
          labelName={'Varas'}
          wantAnObject
          options={filteredDistrict(listProfiles?.length > 0 && listProfiles[0]?.districts)}
          error={props.requiredCourt}
          required
          editDropdown="Selecione"
          defaultValue={props.courtLabel ?? 'Selecione'}
          disabled={props.isEdit && props.isEditCreated}
          showDefaultValue
          setSelected={(selectedItem: any) => {
            props.setCourtLabel(selectedItem?.name);
            props.setCourtId(selectedItem?.id);
          }}
        />
      }

      <AccessValidated>
        <DateInput
          label="Data de expiração (opcional)"
          setValue={(date: any) => props.setExpiredDate(date)}
          value={props.expiredDate}
          error={DateValidate(props.expiredDate)}
        />
      </AccessValidated>
    </ContainerVaras>
  )
}