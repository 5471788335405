import { FormLogin } from "../LoginForm";
import { IShowForm } from "./interface";
import { CertificateForm } from "../CertificateForm";

export const showForm = ({
  variant,
  navigate,
  navigateToLogin,
  navigateToForgotPassword,
  navigateToImportCertificate,
  api,
  url,
  addToast,
  afterSignFunction,
}: IShowForm) => {
  switch (variant) {
    case "login":
      return (
        <FormLogin
          api={api}
          addToast={addToast}
          navigate={navigate}
          navigateToLogin={navigateToLogin}
          navigateToForgotPassword={navigateToForgotPassword}
          navigateToImportCertificate={navigateToImportCertificate}
          afterSignFunction={afterSignFunction}
        />
      );
    case "forgot_password":
      // insira o template aqui
      return (
        <div>
          <strong>forgot_password</strong>
        </div>
      );
    case "certificate":
      // insira o template aqui
      return (
        <CertificateForm
          api={api}
          navigate={navigate}
          navigateTo={"/"}
          url={url}
        />
      );
    default:
      return <span>Ainda não criado</span>;
  }
};
