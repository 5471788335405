import React from "react";
import { showForm } from "../utils";
import { FormContainer } from "./styles";
import { IForm } from "../interface";

export const Form: React.FC<IForm> = ({
  variant,
  navigate,
  api,
  navigateToLogin,
  navigateToForgotPassword,
  navigateToImportCertificate,
  addToast,
  afterSignFunction
}) => {
  return (
    <FormContainer>
      {showForm({ variant, navigate, navigateToLogin, navigateToForgotPassword, navigateToImportCertificate, api, addToast, afterSignFunction })}
    </FormContainer>
  );
};
