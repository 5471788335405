export const ADD_NEW_ATTACHMENT = "@AUDIENCE/ADD_NEW_ATTACHMENT";
export const SET_ATTACHMENT_LIST = "@AUDIENCE/SET_ATTACHMENT_LIST";
export const ADD_ATTACHMENT_UPLOADED = "@AUDIENCE/ADD_ATTACHMENT_UPLOADED";
export const CLEAN_ATTACHMENT_UPLOADS = "@AUDIENCE/CLEAN_ATTACHMENT_UPLOADS";
export const REMOVE_ATTACHMENT_FILE = "@AUDIENCE/REMOVE_ATTACHMENT_FILE";

export const EDIT_ATTACHMENT_FILE = "@AUDIENCE/EDIT_ATTACHMENT_FILE";
export const CLOSE_MODAL_ATTACHMENT_FILE = "@AUDIENCE/CLOSE_MODAL_ATTACHMENT_FILE";
export const ADD_WITH_FILE_ID = "@AUDIENCE/ADD_WITH_FILE_ID";
export const CLEAN_ATTACHMENT_DELETE_LIST = "@AUDIENCE/CLEAN_ATTACHMENT_DELETE_LIST";
export const REMOVE_ONE_BY_ONE_ATTACHMENT_TO_DELETE = "@AUDIENCE/REMOVE_ONE_BY_ONE_ATTACHMENT_TO_DELETE";
