import { styled } from "styled-components";

export const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1500;
  color: black;
`;