import styled from "styled-components";

interface ICircle {
  selected?: boolean;
}

interface IshowModelInput {
  showModelInput?: boolean;
}

export const Container = styled.div`
  height: 79vh;
  display: flex;
  flex-direction: column;
`;

export const FooterContainer = styled.div`
  display: flex;
  height: 40px;
  background: #d1dbe3;
  padding: 24px 30px;
  width: calc(100% - 54px);
  justify-content: flex-end;
`;


export const ButtonContainer = styled.div`
  width: 74px;
`

export const ContainerTiny = styled.div<IshowModelInput>`
  height: calc(100vh - 279px);

  @media (min-width: 1024px) and (max-width: 1600px) {
    height: ${(props) => (props.showModelInput ? 'calc(89vh - 202px)' : 'calc(100vh - 279px)')};  
  }
`

export const OptionListContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e6e6e6;
  padding: 2px;
`;

export const ContainerItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 26px;
  align-items: center;
  cursor: pointer;

  & + div {
    margin-left: 10px;
  }
`;

export const Circle = styled.div<ICircle>`
  width: ${(props) => (props.selected ? "8px" : "20px")};
  height: ${(props) => (props.selected ? "8px" : "20px")};
  border-radius: 50%;
  border: ${(props) =>
    props.selected ? "8px solid #1D9BF0" : "2px solid #cbcbcb"};
  margin-right: 10px;

  background: #fff;
`;

export const ItemTitle = styled.span`
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const TimeContainer = styled.div`
  margin-top: 0;
  width: 100%;
`;

export const TimeTitle = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #1a1a1a;
`;

export const Time = styled.strong`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #1a1a1a;
`;

export const ContainerStatus = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const ContainerButtonRefresh = styled.div`
  position: absolute;
  cursor: pointer;
  right: 0px;
  padding: 10px;
`;

export const ContainerTemplate = styled.div`
  width: 100%;
  padding: 0 20px;
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 20px;
  flex-direction: column;
`;
