import React, { useState } from "react";
import {
  Arrow,
  Container,
  ContentWrapper,
  ExpandableLabelProps,
  Label,
} from "./styles";

export const ExpandableLabel: React.FC<ExpandableLabelProps> = ({
  children,
  title,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Container onClick={toggleExpand}>
        <Arrow expanded={expanded} />
        <Label>{title === "Relatórios" ? "Permissões" : title}</Label>
      </Container>
      {expanded && <ContentWrapper>{children}</ContentWrapper>}
    </div>
  );
};
