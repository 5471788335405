/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { Container, ContainerBody, ContainerList } from "./styles";
import { useToast } from "../../../../hooks/toast";
import { getValueFromlocalStorage } from "../../../../utils/sessionStorageEncrypt";
import { ReactPaginateKenta } from "@kentarepo/kcf-templates";
import { ContainerLeftMenu } from "../../Administration/styles";
import { ReportMenu } from "../ReportMenu";
import { FORMS_RELATORIOS } from "../../../../utils/entities/permission";
import { usePermissions } from "../../../../hooks/permission";
import { useDispatch } from "react-redux";
import { ReportsService } from "../../../../services/reportsService";
import {
  hideLoading,
  showLoading,
} from "../../../../store/modules/login/actions";
import {
  Button,
  DateInput,
  IWord,
  ListEmpty,
  PageHeader,
  ReportCard,
  SearchEmpty,
} from "@kentarepo/kcf-assets";
import { LoadSpinner } from "../../../../features/LoadSpinner";

interface reportFieldsProps {
  description: string
  createIn: string
  version: string
}

export function LogErros() {
  const today = new Date().toISOString().split("T")[0];
  const [listOfLogs, setListOfLogs] = useState([]);
  const [initialDate, setInitialDate] = useState<any>(today);
  const [endDate, setEndDate] = useState<any>(today);
  const [loading, setLoading] = useState<boolean>(false);
  const reportTitle: IWord[] = [
    { label: "Descrição", width: "68.5%", date: false },
    { label: "Data", width: "14%", date: false },
    { label: "Versão", width: "15%", date: false },
  ];
  const reportFields = ({ description, createIn, version }: reportFieldsProps) => {
    return [
      { label: description, width: "70%", date: false },
      { label: createIn, width: "15%", date: true },
      { label: version, width: "15%", date: false },
    ]
  };

  const user = getValueFromlocalStorage("@Kenta:user");
  let customerId: any = null;
  const { addToast } = useToast();
  const dispatch = useDispatch();

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }

  const inputRefNew = React.useRef<HTMLInputElement>(null);

  const { read } = usePermissions(FORMS_RELATORIOS.LOG_ERROR);

  useEffect(() => {
    if (inputRefNew.current) {
      inputRefNew.current.focus();
    }
  }, []);

  const currentPageData = listOfLogs.map((item: any, index: number) => {
    return (
      <ReportCard
        id={"report-card" + index}
        selected={false}
        height={100}
        disabled={true}
        words={reportFields(item)}
        fontSize={"12px"}
        title={false}
        children={<></>}
      />
    );
  });

  async function searchReportLogs() {
    if (!customerId) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa de um customer id",
      });
      return;
    }
    if (!initialDate) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa selecionar uma data inicial",
      });
      return;
    }
    if (!endDate) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa selecionar uma data final",
      });
      return;
    }

    if (initialDate > endDate) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Data inicial não pode ser maior que a data final",
      });
      return;
    }

    setLoading(true);
    dispatch(showLoading());
    const { status, data } = await ReportsService.getAllLogErros(
      customerId,
      initialDate + " 00:00",
      endDate + " 23:59",
      null
    );

    if (status && data && data.data) setListOfLogs(data.data);
    if (status && data && !data.data) setListOfLogs([]);
    setLoading(false);
    dispatch(hideLoading());
  }

  return (
    <Container>
      <ContainerLeftMenu>
        <ReportMenu />
      </ContainerLeftMenu>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <PageHeader
          quantity={listOfLogs.length > 0 ? listOfLogs.length : 0}
          id="header"
          label={"Log de Erros"}
          background="secondary"
          addClick={undefined}
          disabled={false}
        />
        <ContainerBody>
          {read ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <div style={{ marginLeft: 20 }}>
                  <DateInput
                    label="Data inicial"
                    required={false}
                    value={initialDate}
                    setValue={setInitialDate}
                    width={200}
                  />
                </div>
                <div style={{ marginLeft: 20 }}>
                  <DateInput
                    label="Data Final"
                    required={false}
                    value={endDate}
                    setValue={setEndDate}
                    width={200}
                  />
                </div>

                <div style={{ marginLeft: 12, marginTop: 25 }}>
                  <Button onClick={() => searchReportLogs()}>
                    Gerar relatório
                  </Button>
                </div>
              </div>
              <ContainerList>
                {!loading ? (
                  listOfLogs && listOfLogs.length > 0 ? (
                    <>
                      <ReportCard
                        id="report-card-1"
                        selected={false}
                        height={100}
                        disabled={true}
                        words={reportTitle}
                        fontSize={"14px"}
                        fontWeight="700"
                        title
                        children={<></>}
                        background="#D1DBE3"
                      />
                      <ReactPaginateKenta
                        list={currentPageData}
                        title="Log de erros"
                        height="52vh"
                        onChangePage={() => { }}
                      />
                    </>
                  ) : (
                    <ListEmpty title="relatório" typeImg="file" />
                  )
                ) : (
                  <LoadSpinner loading={loading} />
                )}
              </ContainerList>
            </>
          ) : (
            <SearchEmpty
              textMessage={"Sem permissão para seu perfi"}
              typeIcon={"avatar"}
              marginTop="30px"
            />
          )}
        </ContainerBody>
      </div>
    </Container>
  );
}
