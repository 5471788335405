import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 350px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  height: calc(100vh - 170px);
  overflow-y: auto;
  overflow-x: hidden;
  gap: 4px;
`;
