/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { InputComponent } from "../../../../components/Details/InputTime";

import { Container, TimeContainer } from "./styles";
import { DropdownGlobal } from "../../../../components/DropdownGlobal";
import { useQuery } from "../../../../utils/get";
import api from "../../../../services/api";
import { Multiline } from "@kentarepo/kcf-assets";

type IMarkingChildrenModal = {
  marking: any;
  toEditItem: any;
  isEdit: boolean;
  error: boolean;
  subject: string;
  setSubject: (e: string) => unknown;
  setAttendantId: (id: any) => unknown;
  attendantId: number | undefined;
  isBegining: boolean;
};

export const MarkingChildrenModal: React.FC<IMarkingChildrenModal> = ({
  marking,
  subject,
  setSubject,
  isBegining,
  setAttendantId,
  attendantId,
  isEdit,
}) => {
  const [attedants, setAttedants] = useState([]);
  const [loadingAttendant, setLoadingAttendant] = useState(false);
  const [defaultAttedantName, setDefaultAttedantName] = useState("");
  const query = useQuery();

  const isRequiredList = {
    attendant: true,
    selectedTime: true,
    durationTime: true,
    description: true,
  };

  const hearingId = query.get("hearingId");
  
  useEffect(() => {
    if (!isEdit && marking.playerState.offset2 === "00:00" && isBegining) {
      setSubject("");
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (hearingId) {
        try {
          setLoadingAttendant(true);
          const { data } = await api.get(
            `/Attendant/GetAllByHearingId?hearingId=${hearingId}`
          );

          if (attendantId && data?.data?.length) {
            setDefaultAttedantName(
              data.data.find((x: any) => x?.id === attendantId)?.name
            );
          }

          setAttedants(data.data);
          setLoadingAttendant(false);
        } catch (error) {
          console.log("Error no attedant >>>> ", error);
        }
      }
    })();
  }, [hearingId]);

  return (
    <Container>
      <TimeContainer>
        <InputComponent
          defaultValue={marking.playerState.offset1}
          placeholder={marking.playerState.offset1}
          disabled={true}
          required={isRequiredList.selectedTime}
          onChange={() => {}}
          label="Inicio"
        />
        <InputComponent
          defaultValue={marking.playerState.offset2}
          placeholder={marking.playerState.offset2}
          disabled={true}
          required={isRequiredList.durationTime}
          onChange={() => {}}
          label="Fim"
          style={{ marginLeft: 24 }}
        />
      </TimeContainer>
      <DropdownGlobal
        width="424px"
        labelName="Participante"
        required
        options={attedants}
        defaultValue={loadingAttendant ? "Carregando ..." : defaultAttedantName}
        showDefaultValue={
          (isEdit && Boolean(defaultAttedantName)) ||
          (!isBegining && Boolean(defaultAttedantName)) ||
          loadingAttendant
        }
        disabled={false}
        wantAnObject
        setSelected={(e: any) => setAttendantId(e?.id)}
      />

      <div style={{ marginTop: 10, width: "424px" }}>
        <Multiline
          label={"Descrição"}
          name="markDescriptionName"
          onChange={setSubject}
          value={subject}
          required
        />
      </div>
    </Container>
  );
};
