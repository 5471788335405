import React, { useEffect, useState } from "react";
import { stylesGlobalDefault } from "../../global/globalStyle";
import {
  Container,
  Box,
  HeaderBox,
  Icon,
  Divider,
  Graphbox,
  GraphHeader,
  DateFilter,
  DateCells,
  GraphPiebox,
  Graphs,
  PieChartBox,
  BarChartBox,
  PieGraphHeader,
  DividerCustom,
  GraphLegends,
  OnGoing,
  Processes,
  SmallIcon,
  ProcessesLabel,
  BoxData,
  Title,
  TitleData,
  ProcessData
} from "./styles";
import LanguageIcon from '@mui/icons-material/Language';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MonitorIcon from '@mui/icons-material/Monitor';
import GroupIcon from '@mui/icons-material/Group';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import DescriptionIcon from '@mui/icons-material/Description';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ApexColumnChart from "./ColumnChart";
import ApexPieChart from "./ApxPieChart";

import { SessionLocalStorage } from "@kentarepo/kcf-templates";
import { dataAnalysisServices } from "../../services/DataAnalysis";
import { CustomerCertificateService } from "../../services/customerCertificate";

const formatDateToYYYYMMDD = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const Dashboard: React.FC = () => {

  const [loading, setLoading] = useState<boolean>(true);
  const [dashboardData, setDashboardData] = useState<any[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState('Semana');
  const [certificateData, setCertificateData] = useState<any>(null);
  const [transcriptionData, setTranscriptionData] = useState<any>(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      const userCustomerIdLocalStorage = SessionLocalStorage.getCustomerIdByUser();
      if (userCustomerIdLocalStorage) {
        setLoading(true);
        try {
          const [dashboardResponse, certificateResponse] = await Promise.all([
            dataAnalysisServices.GetAllForDashboardMaster(userCustomerIdLocalStorage),
            CustomerCertificateService.getAll(userCustomerIdLocalStorage)
          ]);

          if (dashboardResponse.success) {
            setDashboardData(dashboardResponse.data);
          } else {
            console.error("Erro ao buscar dados do dashboard:", dashboardResponse.data);
          }

          if (certificateResponse.status) {
            setCertificateData(certificateResponse.data);
          } else {
            console.error("Erro ao buscar dados do certificado:", certificateResponse.message);
          }
        } catch (error) {
          console.error("Erro nas requisições:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchDashboardData();
  }, []);

  useEffect(() => {
    const fetchTranscriptionData = async () => {
      if (certificateData) {
        const currentDate = formatDateToYYYYMMDD(new Date());
        try {
          const transcriptionResponse = await dataAnalysisServices.GetUsedTranscriptionAmount(certificateData.data.cnpj, currentDate);
          if (transcriptionResponse.success) {
            setTranscriptionData(transcriptionResponse.data);
          } else {
            console.error("Erro ao buscar dados da transcrição:", transcriptionResponse.data);
          }
        } catch (error) {
          console.error("Erro ao buscar dados da transcrição:", error);
        }
      }
    };

    fetchTranscriptionData();
  }, [certificateData]);

  const groupDataByChart = (data: any[]) => {
    if (!data || data.length === 0) return {};
    return data.reduce((acc: any, current) => {
      if (!acc[current.chart]) {
        acc[current.chart] = [];
      }
      acc[current.chart].push(current);
      return acc;
    }, {});
  };

  const groupedData = groupDataByChart(dashboardData);

  const processData = (groupedData: any) => {
    if (!groupedData?.C5) {
      return { c5Descriptions: [], c5Totals: [], c4Totals: [] };
    }

    const uniqueC5 = groupedData.C5.filter((item: any, index: number, self: any) =>
      index === self.findIndex((i: any) => i.description === item.description)
    );

    const c5Descriptions = uniqueC5.map((item: any) => item.description);
    const c5Totals = uniqueC5.map((item: any) => parseInt(item.total, 10));

    const c4Totals = c5Descriptions.map((description: string) => {
      const match = groupedData.C4?.find((item: any) => item.description === description);
      return match ? parseInt(match.total, 10) : 0;
    });

    return {
      c5Descriptions,
      c5Totals,
      c4Totals,
    };
  };

  const { c5Descriptions, c5Totals, c4Totals } = processData(groupedData);
  const handlePeriodChange = (period: string) => {
    setSelectedPeriod(period);
  };

  const getCategoriesData = () => {
    if (groupedData?.C5) {
      return c5Descriptions
    }
    return [];
  };

  const getSeriesData = () => {
    if (selectedPeriod === 'Semana') {
      return [
        {
          name: 'OPERAÇÕES',
          data: c5Totals,
        },
        {
          name: 'OCORRÊNCIAS',
          data: c4Totals.length > 0 ? c4Totals : Array(c5Descriptions.length).fill(0),
        }
      ];
    }
    else if (selectedPeriod === 'Mês') {
      return [
        {
          name: 'OPERAÇÕES',
          data: [500, 700, 800]
        },
        {
          name: 'OCORRÊNCIAS',
          data: [100, 200, 300]
        }
      ];
    } else {
      return [
        {
          name: 'OPERAÇÕES',
          data: [1500, 1900, 1700]
        },
        {
          name: 'OCORRÊNCIAS',
          data: [500, 700, 600]
        }
      ];
    }
  };

  const getPieChartSeries1 = [
    groupedData?.C3?.[0]?.total ? parseInt(groupedData.C3[0].total, 10) : 0,
    groupedData?.C8?.[0]?.total ? parseInt(groupedData.C8[0].total, 10) : 0
  ];
  const getPieChartLabels1 = ['LOGADOS', 'LIMITE']

  const getPieChartSeries2 = [
    transcriptionData?.data?.creditUsed ? parseInt(transcriptionData?.data?.creditUsed, 10) : 0,
    transcriptionData?.data?.creditAmount ? parseInt(transcriptionData?.data?.creditAmount, 10) : 0
  ]
  const getPieChartLabels2 = ['CONSUMIDOS', 'SALDO']

  if (loading) {
    return <div>Carregando dados...</div>;
  }

  if (dashboardData.length === 0) {
    return <div>Nenhum dado disponível.</div>;
  }

  const capitalizeFirstLetter = (text: string | number) => {
    const stringValue = String(text);
    return stringValue.charAt(0).toUpperCase() + stringValue.slice(1).toLowerCase();
  };


  return (
    <Container>
      <HeaderBox>
        <Box>
          <Box>
            <Icon>
              <LanguageIcon sx={{ color: stylesGlobalDefault.titleMenuColor, fontSize: "35px" }} />
            </Icon>
            <BoxData>
              <Title>Web</Title>
              <TitleData>
                {groupedData.C2?.find((item: any) => item.description === "WEB")?.total || 0}
              </TitleData>
            </BoxData>
          </Box>
          <Divider />
          <Box>
            <Icon>
              <PhoneIphoneIcon sx={{ color: stylesGlobalDefault.titleMenuColor, fontSize: "35px" }} />
            </Icon>
            <BoxData>
              <Title>Mobile</Title>
              <TitleData>
                {groupedData.C2?.find((item: any) => item.description === "MOBILE")?.total || 0}
              </TitleData>
            </BoxData>
          </Box>
        </Box>
        <DividerCustom />
        <Box>
          <Box>
            <Icon>
              <MonitorIcon sx={{ color: stylesGlobalDefault.titleMenuColor, fontSize: "35px" }} />
            </Icon>
            <BoxData>
              <Title>Desktop</Title>
              <TitleData>
                {groupedData.C2?.find((item: any) => item.description === "DESKTOP")?.total || 0}
              </TitleData>
            </BoxData>
          </Box>
          <Divider />
          <Box>
            <Icon>
              <GroupIcon sx={{ color: stylesGlobalDefault.titleMenuColor, fontSize: "35px" }} />
            </Icon>
            <BoxData>
              <Title>Usuários logados</Title>
              <TitleData>{groupedData.C3[0]?.total}</TitleData>
            </BoxData>
          </Box>
        </Box>
      </HeaderBox>
      <Graphs>
        <Graphbox>
          <GraphHeader>
            Desempenho operacional
            <DateFilter>
              <DateCells
                onClick={() => handlePeriodChange('Semana')}
                style={selectedPeriod === 'Semana' ? { border: "1px solid #D9D9D9" } : {}}
              >
                Semana
              </DateCells>
              <DateCells
                onClick={() => handlePeriodChange('Mês')}
                style={selectedPeriod === 'Mês' ? { border: "1px solid #D9D9D9" } : {}}
              >
                Mês
              </DateCells>
              <DateCells
                onClick={() => handlePeriodChange('Ano')}
                style={selectedPeriod === 'Ano' ? { border: "1px solid #D9D9D9" } : {}}
              >
                Ano
              </DateCells>
              <CalendarMonthIcon sx={{ color: stylesGlobalDefault.titleMenuColor, fontSize: "55px", marginRight: '-20px' }} />
            </DateFilter>
          </GraphHeader>
          <BarChartBox>
            {c5Descriptions.length > 0 ? (
              <ApexColumnChart
                categories={getCategoriesData()}
                series={getSeriesData()}
              />
            ) : (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                padding: '20px',
                fontSize: "24px",
                fontWeight: '600',
                color: stylesGlobalDefault.titleMenuColor,
              }}>
                Nenhum dado disponível para exibir no gráfico.
              </div>
            )}
          </BarChartBox>
        </Graphbox>

        <GraphPiebox>
          <PieChartBox >
            <GraphHeader>
              <PieGraphHeader>
                Certificado de uso - Modalidade: Controle de acesso simultâneo
              </PieGraphHeader>
            </GraphHeader>
            <ApexPieChart series={getPieChartSeries1} labels={getPieChartLabels1} />
          </PieChartBox>
          <PieChartBox >
            <GraphHeader>
              <PieGraphHeader>
                Minutagem de transcrição automática
              </PieGraphHeader>
            </GraphHeader>
            <ApexPieChart series={getPieChartSeries2} labels={getPieChartLabels2} />
          </PieChartBox>
        </GraphPiebox>
      </Graphs>

      <GraphLegends>
        <strong>Operações:</strong> São todas as ações realizadas pelo usuário como: Login, Logoff, Inserção, Importação, Exportação, etc.
      </GraphLegends>
      <GraphLegends>
        <strong>Ocorrências:</strong> São todos os erros de sistema capturados no log de erros.
      </GraphLegends>

      <OnGoing>
        <GraphHeader>
          Processos em andamento
        </GraphHeader>

        {groupedData?.C6?.map((process: any, index: number) => (
          <Processes key={index}>
            <ProcessesLabel>
              <SmallIcon>
                {process.description.includes("Audiência") ? (
                  <VideoCameraBackIcon sx={{ color: stylesGlobalDefault.titleMenuColor, fontSize: "25px" }} />
                ) : process.description.includes("TRANSCRIÇÕES") ? (
                  <DescriptionIcon sx={{ color: stylesGlobalDefault.titleMenuColor, fontSize: "25px" }} />
                ) : (
                  <ContentCutIcon sx={{ color: stylesGlobalDefault.titleMenuColor, fontSize: "25px" }} />
                )}
              </SmallIcon>
              {capitalizeFirstLetter(process.description)}
            </ProcessesLabel>
            <ProcessData>
              <TitleData>{process.total || 0}</TitleData>
              <MoreVertIcon sx={{ color: stylesGlobalDefault.inputsBorderFocusOff, fontSize: "40px", margin: '10px' }} />
            </ProcessData>
          </Processes>
        ))}

        {(!groupedData?.C6 || groupedData.C6.length === 0) && (
          <div>Nenhum processo em andamento.</div>
        )}
      </OnGoing>

      {loading && <div>Carregando dados...</div>}
    </Container>
  );
};