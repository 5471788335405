import { useEffect, useState } from "react";
import CheckIcon from "../../assets/svg/v.svg";
import { Container, Title, Wrapper } from "./style";

export interface IResultCheckBox {
  id: number;
  isChecked: boolean;
  label: string;
  disabled?: boolean;
  attendantRoleList?:number
}

interface ICheckBoxGlobal {
  label: string;
  checked: boolean;
  onChecked: (click: IResultCheckBox) => unknown;
  style?: any;
  id: number;
  activeId?: any;
  disabled?: boolean;
}

export function CheckBoxGlobal(props: ICheckBoxGlobal) {
  const [active, setActive] = useState(props.checked);

  function handleClick() {
    const newActiveValue = !active;
    setActive(newActiveValue);

    let checkBoxClicked: IResultCheckBox = {
      id: props?.id,
      isChecked: newActiveValue,
      label: props.label,
      disabled: props.disabled,
    };

    props.onChecked(checkBoxClicked);
  }

  useEffect(() => {
    setActive(props.checked);
  }, [props.checked]);

  return (
    <Container style={props.style}>
      <Wrapper active={active} disabled={props.disabled} onClick={handleClick}>
        <img src={CheckIcon} width={12} alt="" />
      </Wrapper>
      <Title disabled={props.disabled}>{props.label}</Title>
    </Container>
  );
}
