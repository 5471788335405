import { Box } from "@mui/material";
import { styled } from "styled-components";

interface ITextForgotPasswordInfo {
  paddingTop?: string;
  paddingBottom?: string;
  marginTop?: string;
  marginBottom?: string;
}

export const TextForgotPasswordInfo = styled(Box)<ITextForgotPasswordInfo>`
  text-align: center;
  margin: 0px !important;
  padding-top: ${(props) => props.paddingTop ?? "0px"};
  padding-bottom: ${(props) => props.paddingBottom ?? "0px"};
  margin-top: ${(props) => props.marginTop ?? "0px"};
  margin-bottom: ${(props) => props.marginBottom ?? "0px"};
`;
