import React from 'react';
import { PageHeader, SearchEmpty } from "@kentarepo/kcf-assets";

export const NotFoundPage: React.FC = () => {
   return (
    <>
      <PageHeader label="Nenhuma página encontrada" background="secondary" />
      <SearchEmpty textMessage="Nenhuma página encontrada" typeIcon="file" marginTop="50px"/>
    </>
  );
}