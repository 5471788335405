import React, { useEffect } from "react";
import { MenuList, PageHeader } from "@kentarepo/kcf-assets";
import { Container, Content } from "./styles";
import { ILeftMenu } from "./interface";

export const LeftMenu: React.FC<ILeftMenu> = ({
  menuTitle,
  currentLocale,
  menuList,
  backgroundHeader = "secondary",
  navigate,
  listMenusOpen,
}) => {
  useEffect(() => {}, [listMenusOpen]);

  const getMenuOpen = (item: string) => {
    if (listMenusOpen.includes(item.toLocaleLowerCase())) {
      return item.toLocaleLowerCase();
    }
    return "";
  };

  const handleClickMenu = (item: string) => {
    const lowerCaseItem = item.toLocaleLowerCase();

    if (listMenusOpen.includes(lowerCaseItem)) {
      const index = listMenusOpen.indexOf(lowerCaseItem);
      if (index !== -1) {
        listMenusOpen.splice(index, 1);
      }
    } else {
      listMenusOpen.push(lowerCaseItem);
    }
  };

  return (
    <Container>
      <PageHeader label={menuTitle} background={backgroundHeader} />
      <Content>
        {menuList.map((item) => (
          <MenuList
            currentLocale={currentLocale}
            title={item.title}
            handleOpen={() => handleClickMenu(item.title)}
            open={getMenuOpen(item.title)}
            list={item.navigation}
            navigate={navigate}
          />
        ))}
      </Content>
    </Container>
  );
};
