import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import { LeftTemplate } from "../../../features/auth/LeftTemplate";
import { ChangePassword as ChangePasswordTemplate } from "../../../features/auth/ChangePassword";

export const ChangePassword: React.FC = () => {
  const [changePass, setChangePass] = useState<boolean>(false);
  const [succeseMessageReturnAPI, setSuccessMessageReturnAPI] =
    useState<string>("Senha alterada com sucesso");
  const { guid } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();

  useEffect(() => {
    if (changePass) {
      setChangePass(false);
      navigate("/");

      addToast({
        type: "success",
        title: "Senha alterada",
        description: succeseMessageReturnAPI,
      });
    }
  }, [changePass]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <LeftTemplate backgroundImage="default" />
      <ChangePasswordTemplate
        api={api}
        guid={guid ?? ""}
        navigate={navigate}
        navigateTo="/"
        setChangedPassword={setChangePass}
        setSuccessMessageReturnAPI={setSuccessMessageReturnAPI}
      />
    </div>
  );
};
