import { IUserCustomerEntryData, stringFormat } from "@kentarepo/kcf-services";
import { Axios } from "axios";
import { SHA1 } from "crypto-js";
import { LogOperation } from "../../../utils/entities/logOperation";

export const verifyPasswordRoles = (
  passwordOld: string,
  passwordOldConfirm: string
): [boolean, string] => {
  const [passIsStrongPasswordOld] = stringFormat.isStrongPassword(passwordOld);
  const [passIsStrongPasswordOldConfirm] =
    stringFormat.isStrongPassword(passwordOldConfirm);

  if (!passIsStrongPasswordOld || !passIsStrongPasswordOldConfirm) {
    return [false, "Preencha os campos conforme a regra acima"];
  } else if (passwordOld !== passwordOldConfirm) {
    return [false, "As senhas não correspondem"];
  } else {
    return [true, ""];
  }
};

export const updateUserAPI = async (
  api: Axios,
  user: IUserCustomerEntryData,
  newPassword: string | null,
  newPicture: string | null
) => {
  if (newPassword !== null && newPicture !== null) {
    const body = [
      {
        op: "replace",
        value: SHA1(newPassword).toString().toUpperCase(),
        path: "password",
      },
      {
        op: "replace",
        value: newPicture,
        path: "picture",
      },
      {
        op: "2",
        value: LogOperation.getDevice(),
        path: "device",
      },
      {
        op: "2",
        value: LogOperation.getMachineIP(),
        path: "ip",
      },
      {
        op: "2",
        value: LogOperation.getPoliceUnitId(),
        path: "logPoliceUnitId",
      },
      {
        op: "2",
        value: LogOperation.getUserId(),
        path: "logUserId",
      }
    ];

    const { data, status } = await api.patch(
      `/UserCustomer/Update?id=${user?.id}`,
      body
    );
    return { data, status };
  } else if (newPassword !== null) {
    const body = [
      {
        op: "replace",
        value: SHA1(newPassword).toString().toUpperCase(),
        path: "password",
      },
      {
        op: "2",
        value: LogOperation.getDevice(),
        path: "device",
      },
      {
        op: "2",
        value: LogOperation.getMachineIP(),
        path: "ip",
      },
      {
        op: "2",
        value: LogOperation.getPoliceUnitId(),
        path: "logPoliceUnitId",
      },
      {
        op: "2",
        value: LogOperation.getUserId(),
        path: "logUserId",
      }
    ];

    const { data, status } = await api.patch(
      `/UserCustomer/Update?id=${user?.id}`,
      body
    );
    return { data, status };

  } else if (newPicture !== null) {
    const body = [
      {
        op: "replace",
        value: newPicture,
        path: "picture",
      },
      {
        op: "2",
        value: LogOperation.getDevice(),
        path: "device",
      },
      {
        op: "2",
        value: LogOperation.getMachineIP(),
        path: "ip",
      },
      {
        op: "2",
        value: LogOperation.getPoliceUnitId(),
        path: "logPoliceUnitId",
      },
      {
        op: "2",
        value: LogOperation.getUserId(),
        path: "logUserId",
      }
    ];

    const { data, status } = await api.patch(
      `/UserCustomer/Update?id=${user?.id}`,
      body
    );

    return { data, status };
  } else {
    return { data: null, status: 400 };
  }
};
