import styled from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 6px;
  background: ${stylesGlobalDefault.gray};
`;

export const Progress = styled.input`
  width: 100%;
  height: 6px;
  accent-color: ${stylesGlobalDefault.orange};

  cursor: pointer;
`;
