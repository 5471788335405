/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import api from "../../services/api";
import {
  getValueFromlocalStorage,
  insertTolocalStorage,
} from "../../utils/sessionStorageEncrypt";
import moment from 'moment-timezone';

// Id	  Operation	  Description
// 1	  1	          Inserção
// 2	  2	          Atualização
// 3	  3	          Exclusão
// 4	  4	          Login
// 5	  5	          Logoff
// 6	  6	          Exportação
// 7	  7	          Importação
// 8	  8	          Gravação
// 9	  9	          Visualização
// 10	  10	        Publicação

export const LogOperation = {
  async postLogOperation(
    systemObject: string,
    operation: number,
    registerId: number
  ) {
    const dateOperation = moment.tz('America/Sao_Paulo');
    try {
      const postLogger = {
        policeUnitId: this.getPoliceUnitId(),
        userId: this.getUserId(),
        registerId: registerId,
        date: dateOperation.format('YYYY-MM-DDTHH:mm:ss'),
        systemObject: systemObject,
        operation: operation,
        device: this.getDevice(),
        ip: this.getMachineIP(),
        createIn: dateOperation.format('YYYY-MM-DDTHH:mm:ss')
      };
      await api.post("/LogOperation/Add", postLogger);
    } catch (error) {
      console.log("error", error);
    }
  },
  getUserId: (): any => {
    const user = getValueFromlocalStorage("@Kenta:user");
    let userId: any = null;
    if (user) {
      const user_parsed = JSON.parse(user);
      userId = user_parsed?.id;
    }

    return userId;
  },
  getCustomerId: (): number => {
    const user = getValueFromlocalStorage("@Kenta:user");
    let userCustomerId: any = null;
    if (user) {
      const user_parsed = JSON.parse(user);
      userCustomerId = user_parsed?.customerId;
    }

    return userCustomerId;
  },
  getDevice: (): string => {
    return "PC";
  },
  getPoliceUnitId: () => {
    const unitStorage = getValueFromlocalStorage("@Kenta:profile");

    if (unitStorage) {
      const unitObject = JSON.parse(unitStorage);
      return unitObject.placeId;
    }

    return null;
  },
  getMachineIP: (): any => {
    return getValueFromlocalStorage("@Kenta@MachineIP");
  },
  setMachineIP: async () => {
    try {
      const res = await axios.get("https://api.ipify.org?format=json");

      if (res.data) {
        insertTolocalStorage("@Kenta@MachineIP", res.data.ip);
      } else {
        insertTolocalStorage("@KentaMachineIP", "Não encontrado");
      }
    } catch (error: any) {
      if (error.code === "ERR_NETWORK") {
        insertTolocalStorage("@Kenta@MachineIP", "bloqueado rede");
      } else {
        insertTolocalStorage("@Kenta@MachineIP", "Não encontrado");
      }
    }
  },
};
