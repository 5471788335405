import React from "react";

import { Container, ContainerVideo } from "./styles";
import { VideoPlayer } from "../../Video";
import { IFullView } from "./interface";
import { PageHeader } from "@kentarepo/kcf-assets";
import RightTemplate from "../RightTemplate";
import { useLocation, useNavigate } from "react-router";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";

export const FullView: React.FC<IFullView> = ({
  title,
  transcription,
  summary,
  mark,
  ata,
  videoComponente,
  setPage,
  loadingAba
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dataLocation = location.state;
  const defaultPage = Number(getValueFromlocalStorage("choosePage"));

  return (
    <Container>
      <PageHeader
        label={title}
        background="primary"
        backClick={() => navigate(`/audiences`, { state: dataLocation })}
      />
      <ContainerVideo>
        <VideoPlayer {...videoComponente} />
        <RightTemplate
          transcription={transcription}
          summary={summary}
          mark={mark}
          ata={ata}
          defaultPage={defaultPage}
          setPage={setPage}
          loadingAba={loadingAba}
        />
      </ContainerVideo>
    </Container>
  );
};
