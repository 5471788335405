import { getValueFromlocalStorage } from "../utils/sessionStorageEncrypt";
export interface IUser {
  id: number;
  name: string;
  email: string;
  password: string;
  picture: string;
  customerId: number;
  emailValidated: boolean;
  termAccepted: boolean;
  createIn: string;
  updateIn: string;
  active: boolean;
  hash?: string;
  token?: string;
  device: string | null;
  ip: string | null;
  logPoliceUnitId: number;
  logUserId: number;
}

export const userUtils = {
  getJsonUser() {
    const userJson = getValueFromlocalStorage("@Kenta:user");

    if (userJson) {
      const userReturn: IUser = JSON.parse(userJson);
      return userReturn;
    }
    return null;
  },
  getUserName() {
    const user = this.getJsonUser();
    if (user != null) {
      return user.name;
    }
  },
  getEmail() {
    const user = this.getJsonUser();
    if (user != null) {
      return user.email;
    }
  },
  getCustomerIdByUser() {
    const userJson = getValueFromlocalStorage("@Kenta:user");
    if (userJson) {
      const userReturn: IUser = JSON.parse(userJson);
      return userReturn?.customerId;
    }
    return null;
  },
  getUserId () {
    const userJson = getValueFromlocalStorage("@Kenta:user");
    if (userJson) {
      const userReturn: IUser = JSON.parse(userJson);
      return userReturn?.id;
    }
    return null;
  }
};
