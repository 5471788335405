/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from "axios";
import api from "../api";
import { returnResponse } from "../utils";
import { LogOperation } from "../../utils/entities/logOperation";

export const HearingService = {
  postNewAudience: async (payload: any) => {
    try {
      const { data } = await api.post<any>(`Hearing/Add`, payload);

      return {
        status: true,
        data: data.data,
      };
    } catch (error: any) {
      return {
        status: false,
        data: error.response.data.message,
      };
    }
  },

  putNewAudience: async (payload: any) => {
    return await api.put<any>(`Hearing/Update`, payload);
  },

  getHearingById: async (hearingId: number) => {
    try {
      const { data } = await api.get(
        `/Hearing/GetAllHearingInformationsByHearingId?hearingId=${hearingId}`
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: "Houve um erro ao pegar o video gravado.",
      };
    }
  },
  deleteHearingDto: async (body: any) => {
    try {
      const { data } = await api.delete("/Hearing/DeleteObject", body);

      return {
        success: true,
        body: data,
      };
    } catch (error: any) {
      return {
        success: false,
        body: error.response,
      };
    }
  },
  deleteHearingDelete: async (id: number) => {
    try {
      const { data } = await api.delete(`/Hearing/Delete?id=${id}`);

      return {
        success: true,
        body: data,
      };
    } catch (error: any) {
      return {
        success: false,
        body: error.response,
      };
    }
  },
  deleteHearing: async (authToken: string | null, hearingId: number) => {
    if (!authToken) {
      return {
        status: false,
        message: "Não é possível inativar o registro sem o token correto.",
      };
    }

    if (!hearingId) {
      return {
        status: false,
        message: "Não é possível inativar o registro sem o dado correto",
      };
    }

    try {
      const bodyDeleteHearing = [
        {
          op: "replace",
          value: false,
          path: "active",
        },
      ];
      const { data } = await api.patch(
        `Hearing/Update?id=${hearingId}`,
        bodyDeleteHearing
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: "Houve um erro ao deletar o registro.",
      };
    }
  },
  createHearingRecord: async (authToken: string, hearingId: number) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível criar a sala de video sem o token de autenticação.",
      };
    }

    if (!hearingId) {
      return {
        status: false,
        message: "Não é possível criar a sala de video sem o id da sala",
      };
    }

    try {
      const { data } = await api.get(
        `/Hearing/CreateMeeting?hearingId=${hearingId}`
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      console.log(error);

      return {
        status: false,
        message: "Houve um erro ao criar a sala de video.",
      };
    }
  },
  openHearingRecord: async (authToken: string, hearingFileId: number) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível criar a sala de video sem o token de autenticação.",
      };
    }

    if (!hearingFileId) {
      return {
        status: false,
        message: "Não é possível criar a sala de video sem o id da sala",
      };
    }

    try {
      const { data } = await api.get(
        `/Hearing/OpenMeeting?hearingFileId=${hearingFileId}`
      );
      await LogOperation.postLogOperation("Hearing", 1, 1);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      console.log(error);

      return {
        status: false,
        message: "Houve um erro ao criar a sala de video.",
      };
    }
  },
  getByHearingId: async (authToken: string, hearingId: number) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível criar a sala de video sem o token de autenticação.",
      };
    }

    if (!hearingId) {
      return {
        status: false,
        message: "Não é possível criar a sala de video sem o id da sala",
      };
    }

    try {
      const { data } = await api.get(`HearingTokens/GetByHearingId`, {
        params: {
          hearingId: hearingId,
        },
      });

      if (data.length > 0) {
        const dataResponse = {
          token: data.authToken,
          sessionToken: data.deviceToken,
          urlModerator: data.internalLink,
          urlAttendee: data.externalLink,
          videoRoom: {
            id: data.roomId,
          },
        };

        return {
          status: true,
          data: dataResponse,
        };
      } else {
        return {
          status: false,
          message: "Nenhuma sala encontrada",
        };
      }
    } catch (error) {
      return {
        status: false,
        message: "Houve um erro ao criar a sala de video.",
      };
    }
  },
  getByHearingFileId: async (authToken: string, hearingFileId: number) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível criar a sala de video sem o token de autenticação.",
      };
    }

    if (!hearingFileId) {
      return {
        status: false,
        message: "Não é possível criar a sala de video sem o id da sala",
      };
    }

    try {
      const { data } = await api.get(`HearingTokens/GetByHearingFileId`, {
        params: {
          hearingFileId: hearingFileId,
        },
      });

      if (data.ok === true) {
        const dataResponse = {
          token: data.data.authToken,
          sessionToken: data.data.deviceToken,
          urlModerator: data.data.internalLink,
          urlAttendee: data.data.externalLink,
          videoRoom: {
            id: data.data.roomId,
          },
        };

        return {
          status: true,
          data: dataResponse,
        };
      } else {
        return {
          status: false,
          message: "Nenhuma sala encontrada",
        };
      }
    } catch (error) {
      return {
        status: false,
        message: "Houve um erro ao criar a sala de video.",
      };
    }
  },

  getAllByFilters: async (placeId: number) => {
    try {
      const { data } = await api.get(
        `/Hearing/GetAllByFilters?placeId=${placeId}&active=true`
      );
      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: "Houve um error ao pegar o placeId",
      };
    }
  },

  stopVideoRecording: async (
    roomId: number,
    authToken: string,
    deviceToken: string
  ) => {
    try {
      if (!authToken || !deviceToken) {
        return {
          status: false,
          message: "Autenticação inválida.",
        };
      }

      const { data } = await api.post(
        `/Hearing/StopVideoRecord`,
        {
          device: LogOperation.getDevice(),
          ip: LogOperation.getMachineIP(),
          logPoliceUnitId: LogOperation.getPoliceUnitId(),
          logUserId: LogOperation.getUserId(),
        },
        {
          params: {
            roomId: roomId,
          },
        }
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: "Houve um erro ao pegar o video gravado.",
      };
    }
  },
  deleteRoom: async (
    roomId: number,
    authToken: string,
    deviceToken: string
  ) => {
    try {
      if (!authToken || !deviceToken) {
        return {
          status: false,
          message: "Autenticação inválida.",
        };
      }

      const { data } = await api.post(
        `/Hearing/DeleteRoom`,
        {
          device: LogOperation.getDevice(),
          ip: LogOperation.getMachineIP(),
          logPoliceUnitId: LogOperation.getPoliceUnitId(),
          logUserId: LogOperation.getUserId(),
        },
        {
          params: {
            roomId: roomId,
          },
        }
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: "Houve um erro ao pegar o video gravado.",
      };
    }
  },
  startVideoRecording: async (
    roomId: number,
    authToken: string,
    deviceToken: string
  ) => {
    try {
      if (!authToken || !deviceToken) {
        return {
          status: false,
          message: "Autenticação inválida.",
        };
      }

      const { data } = await api.post(
        `/Hearing/StartVideoRecord`,
        {
          device: LogOperation.getDevice(),
          ip: LogOperation.getMachineIP(),
          logPoliceUnitId: LogOperation.getPoliceUnitId(),
          logUserId: LogOperation.getUserId(),
        },
        {
          params: {
            roomId: roomId,
          },
        }
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getHearingDto: async (hearingId: number) => {
    try {
      const { data } = await api.get(
        `/Hearing/GetHearingDTO?hearingId=${hearingId}`
      );

      return {
        success: true,
        body: data.data,
      };
    } catch (error) {
      return {
        success: true,
        body: {},
      };
    }
  },

  getUserInRoom: async (roomId: number) => {
    try {
      const { data } = await api.get(
        `/Hearing/GetUserInRoom?roomId=${roomId}`
      );

      return {
        success: true,
        body: data,
      };
    } catch (error) {
      return {
        success: true,
        body: {},
      };
    }
  },

  renewTokens: async (authToken: string, roomId: number) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível criar a sala de video sem o token de autenticação.",
      };
    }
    if (!roomId) {
      return {
        status: false,
        message: "Não é possivel fazer o renew token sem o roomid .",
      };
    }

    try {
      const { data } = await api.get(`Hearing/RenewTokens?roomId=${roomId}`);

      const dataResponse = {
        authToken: data.authToken,
        deviceToken: data.deviceToken,
      };

      return {
        status: true,
        data: dataResponse,
      };
    } catch (error) {
      return {
        status: false,
        message: "Houve um erro ao criar a sala de video.",
      };
    }
  },
  logoutUna: async () => {
    try {
      if (window.location.hostname === "localhost") {
        await api.get(`http://localhost:5173/una/auth/v1/logout`, {});
      } else {
        const host = import.meta.env.VITE_APP_BASEURL_SITE;
        await api.get(`${host}/una/auth/v1/logout`, {});
      }
    } catch (error) {
      /* empty */
    }
  },

  post: async (authToken: string | null, hearingId: any) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível criar a sala de video sem o token de autenticação.",
      };
    }
    try {
      const body = {
        ...hearingId,
      };
      const { data } = await api.post<any>(`/Hearing/Add`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  getById: async (id: number) => {
    try {
      const { data } = await api.get<any>(`/Hearing/Get?id=${id}`);

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};
