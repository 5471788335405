import React, { useEffect, useState } from "react";
import { TextLink, LoadingComponent, InputAuth } from "@kentarepo/kcf-assets";

import {
  LeftFormContainer,
  LinkContainer,
  Logo,
  LoadingContainer,
  DivImportCertificate,
  ContainerLogo,
  ContainerForgot,
  Container,
  Form,
  Button,
} from "./styles";
import kentaLogo from "../../../assets/KENTA_4.0.png";

import { signInServices } from "@kentarepo/kcf-services";
import {
  deleteFromlocalStorage,
  insertTolocalStorage,
} from "../utils/sessionStorageEncrypt";
import { ISignIn } from "./interface";

type typesInput = "email" | "password" | "text" | undefined;

export const FormLogin: React.FC<ISignIn> = ({
  api,
  navigate,
  navigateToLogin,
  navigateToForgotPassword,
  navigateToImportCertificate,
  addToast,
  afterSignFunction,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });
  const apiService = signInServices(api);
  const [passwordType, setPasswordType] = useState<typesInput>("password");

  useEffect(() => {
    deleteFromlocalStorage("@Kenta:user");
    deleteFromlocalStorage("@Kenta:token");
    deleteFromlocalStorage("@Kenta:expireIn");
    deleteFromlocalStorage("@Kenta:access_Token");
    deleteFromlocalStorage("@Kenta:refresh_Token");
  }, []);

  const signIn = async (e: any) => {
    e.preventDefault();
    try {
      if (!email.trim() || !password || !email.trim().includes("@")) {
        setErrors({
          email: !email || !email.includes("@"),
          password: !password,
        });
        addToast({
          type: "warning",
          title: "Preencha todos os campos",
          description:
            "Para fazer o login é necessário preencher todos os campos",
        });
        return;
      }

      setLoading(true);
      const { data, success } = await apiService.post({
        email: email,
        password: password,
      });

      if (!success) {
        addToast({
          type: "warning",
          title: "Aviso",
          description: data?.message ?? "Usuário ou e-mail não encontrado",
        });
        setLoading(false);
        return;
      }

      if (success) {
        const user = {
          ...data.userCustomer,
          hash: data.userCustomerAccess.hash,
          token: data.tokenResponse,
        };

        insertTolocalStorage("@Kenta:user", JSON.stringify(user));
        insertTolocalStorage(
          `@Kenta:token`,
          JSON.stringify(data.tokenResponse)
        );

        const dtNow: Date = new Date();

        insertTolocalStorage(
          "@Kenta:expireIn",
          String(new Date(dtNow.getTime() + 30 * 60 * 1000))
        );

        insertTolocalStorage(
          "@Kenta:access_Token",
          data?.tokenResponse?.access_Token
        );
        insertTolocalStorage(
          "@Kenta:refresh_Token",
          data?.tokenResponse?.refresh_Token
        );

        if (afterSignFunction) {
          const routeToNavigate = await afterSignFunction({ user });
          if (routeToNavigate) {
            setLoading(false);
            if (navigate) navigate(routeToNavigate ?? navigateToLogin ?? "/");

            return;
          }
        } else {
          setLoading(false);

          if (navigate) navigate(navigateToLogin ?? "/");
        }
      }

      setLoading(false);
    } catch (error) {
      addToast({
        type: "error",
        title: "Aviso",
        description: "Ocorreu um erro inesperado",
      });
    }
  };

  return (
    <LeftFormContainer>
      <ContainerLogo >
        <Logo src={kentaLogo} />
      </ContainerLogo>

      <Form onSubmit={signIn} >
        <Container>
          <InputAuth
            maxLength={200}
            error={!!errors.email && (!email || !email.includes("@"))}
            label="Seu e-mail"
            type="text"
            disabled={loading}
            value={email}
            autoFocus={true}
            onChange={setEmail}
            marginTop="0.5px"            
          />
          <InputAuth
            maxLength={50}
            error={!!errors.password && !password}
            label="Sua senha"
            setType={(type: typesInput) => setPasswordType(type)}
            disabled={loading}
            value={password}
            autoFocus={false}
            onChange={setPassword}
            icon={true}
            type={passwordType}
            marginBottom="29px"
            marginTop="-0.5px"
          />
        </Container>
        <Button id="sign-in_button" disabled={loading} type="submit" style={{marginTop:"1px", marginBottom:"2px"}}>
          Entrar
        </Button>
      </Form>

      <ContainerForgot >
        <LinkContainer
          type="button"
          onClick={() => navigate(navigateToForgotPassword)}
        >
          <TextLink>Esqueci a minha senha</TextLink>
        </LinkContainer>

        <DivImportCertificate >
          <LinkContainer
            onClick={() => navigate(navigateToImportCertificate)}
          >
            <TextLink>Importar certificado</TextLink>
          </LinkContainer>
        </DivImportCertificate>
      </ContainerForgot>
      {loading && (
        <LoadingContainer>
          <LoadingComponent loading={loading} />
        </LoadingContainer>
      )}
    </LeftFormContainer>
  );
};
