/* eslint-disable @typescript-eslint/no-explicit-any */
import { userCreateDistrictTypes } from "../actions/actios.types";

interface IUserCreateDistrict {
  userCreateDistrictList: {
    userData?: any[];
    userNewData?: any;
    errorName?: boolean;
    errorEmail?: boolean;
  }
  isEdit: boolean;
}

const initialState: IUserCreateDistrict = {
  userCreateDistrictList: {
    userData: [],
  },
  isEdit: false,
};

const userCreateDistrictReducer = (
  state = initialState,
  action: any
): IUserCreateDistrict => {
  switch (action.type) {
    case userCreateDistrictTypes.SET_USERCREATE_DISTRICT:
      return {
        ...state,
        userCreateDistrictList: action.payload
      };
    default:
      return state;
  }
};
export default userCreateDistrictReducer;
