import React from "react";

import { ButtonClickCard } from "@kentarepo/kcf-assets";
import { IHearingFileDownloads } from "@kentarepo/kcf-services";
import { convertType } from "../../utils/format";

const fileSize = (bytes: number) => {
  const total = (bytes / 1024).toFixed(2);
  if (Number(total) > 1000) {
    return (bytes / 1048576).toFixed(2) + " MB ";
  }
  return total + " KB";
};

const typeFile = (tipo: number) => {
  if (tipo === 1) {
    return "Vídeo";
  }
  if (tipo === 2) {
    return "Image";
  }
  if (tipo === 3) {
    return "Áudio";
  }
  if (tipo === 4) {
    return "Documento";
  }
  if (tipo === 5) {
    return "Vídeo anexado";
  }
  if (tipo === 6) {
    return "Video cortado";
  }
};

export const handleList = (
  listNotifications: Array<IHearingFileDownloads>,
  setItemClicked: React.Dispatch<React.SetStateAction<IHearingFileDownloads | null>>,
  tooltipMessage: string
) => {
  return listNotifications.map((item) => {
    return (
      <ButtonClickCard
        title={item.filename}
        subTitle={`${convertType(item.type) || typeFile(item.type)}, ${fileSize(item.size)} `}
        tooltipMessagem={tooltipMessage}
        onClick={() => setItemClicked(item)}
        typeButton="download"
        typeIcon={item.type === 1 ? "video" : "file"}
      />
    );
  });
};
