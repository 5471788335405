export const getCameras = async (): Promise<Array<any>> => {
  try {
    // Solicita permissão de mídia para acessar a câmera
    await navigator.mediaDevices.getUserMedia({ video: true });

    // Obtém a lista de dispositivos
    const devices = await navigator.mediaDevices.enumerateDevices();

    // Filtra apenas os dispositivos de vídeo (câmeras)
    const videoDevices = devices.filter(device => device.kind === 'videoinput');

    return videoDevices
  } catch (err) {
    console.error('Erro ao obter dispositivos de câmera:', err);
    return []
  }
};