/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import api from "../../../services/api";

import { LoadingComponent, PageHeader } from "@kentarepo/kcf-assets";
import { LoadingContainer } from "../MyAccount/styled";
import { Administration } from "../Administration";
// import {
//   permitionsCrud,
//   ContainerCrud,
//   IModelFormBase,
//   IFormOneLabel,
//   httpType,
// } from "@kentarepo/kcf-templates";
import {
  ContainerAdmin,
  ContainerItemAdmin,
  ContainerLeftMenu,
} from "../Administration/styles";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { IFormOneLabel } from "../../../features/forms/FormOneLabel/interface";
import { IModelFormBase } from "../../../features/forms/optionsForms";
import { ContainerCrud, permitionsCrud } from "../../../features/cruds";
import {httpType} from "../../../global/types";
import { usePermissions } from "../../../hooks/permission";
import { FORMS_ADMIN } from "../../../utils/entities/permission";

export const HearingType: React.FC = () => {
  const [onSubmitForm, setOnSubmitForm] = useState<number>(0);
  const [objectRetorned, setObjectRetorned] = useState<IFormOneLabel | null>(
    null
  );
  const [httpType, setHttpType] = useState<httpType>("post");
  const [listAttendant, setListAttendant] = useState<Array<IFormOneLabel>>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const userStorage = getValueFromlocalStorage("@Kenta:user");

  const { read, create, edit } = usePermissions(FORMS_ADMIN.HEARING_TYPE)

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    setLoading(true);
    const attendantRoles = await api.get(
      `HearingType/GetAllByCustomerId?customerId=${getCustomerIdLocalStorage()}`
    );
  
    setListAttendant(attendantRoles.data.data ?? []);
    setLoading(false);
  }

  const getCustomerIdLocalStorage = () => {
    if (userStorage) {
      const user_parsed = JSON.parse(userStorage);
      return user_parsed.customerId;
    }
  }

  const modelFormBase: IModelFormBase = {
    api: api,
    endPoint: "/HearingType/",
    httpType: httpType,
    setHttpType: setHttpType,
    list: listAttendant,
    onSubmitForm: onSubmitForm,
    setOnSubmitForm: setOnSubmitForm,
    objectRetorned: objectRetorned,
    setObjectRetorned: setObjectRetorned,
  };  

  const formOneLabel: IFormOneLabel = {
    active: true,
    customerId: getCustomerIdLocalStorage(),
    displayLabel: "Tipo de audiência",
    id: 0,
    name: "",
    modelFormBase: modelFormBase,
    titleToast: "Tipo de audiência",
  };

  const permissions: permitionsCrud = {
    inactivate: false,
    toCreate: create,
    toEdit: edit,
    toRead: read,
  };

  return (
    <>
      <ContainerAdmin>
        <ContainerLeftMenu>
          <Administration />
        </ContainerLeftMenu>
        <ContainerItemAdmin>
          {loading ? (
            <>
              <PageHeader
                quantity={0}
                id="headerAdminArea"
                label={"Tipos de audiências"}
                background="secondary"
                addClick={undefined}
              />
              <LoadingContainer>
                <LoadingComponent loading={loading} />
              </LoadingContainer>
            </>
          ) : (
            <ContainerCrud
              list={listAttendant}
              title={"Tipos de audiências"}
              emptyListMessage="Nenhum tipo de audiência encontrado"
              permissions={permissions}
              customProsForm={formOneLabel}
              modelFormBase={modelFormBase}
              optionForm="FormOneLabel"
              loading={loading}
              isAudience="Tipo de audiência"
            />
          )}
        </ContainerItemAdmin>
      </ContainerAdmin>
    </>
  );
};
