import React from "react";

import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import { LeftTemplate } from "../../../features/auth/LeftTemplate";
import { ForgotPassword as ForgotPasswordTemplate } from "../../../features/auth/ForgotPassword";

export const ForgotPassword: React.FC = () => {
  const { addToast } = useToast()
  const navigate = useNavigate();

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <LeftTemplate backgroundImage="default" />
      <ForgotPasswordTemplate api={api} navigate={navigate} navigateTo="/" addToast={addToast} />
    </div>
  );
};
