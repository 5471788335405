import styled from 'styled-components';
import { stylesGlobalDefault } from '../../../../global/globalStyle';

interface IStatus {
    active?: boolean;
    isActive?: boolean
    disabled: boolean
  }
  
export const CardContainer = styled.div<IStatus>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px -40px;
  width: 100% - 40px;
  background: ${stylesGlobalDefault.backgroundListItemColor};
  margin-bottom: 4px;
  border-radius: 8px;
  padding: 20px;
  border: 2px solid ${({isActive})=> 
  
  isActive? '#1d9bf0' : stylesGlobalDefault.backgroundListItemColor };
  
  cursor: ${({disabled})=> disabled ? "default" : "pointer" };

  &:hover {

    border: ${({disabled})=> disabled ? "default" :  "2px solid #1d9bf0" };
    //border: 2px solid #1d9bf0;
  }  
  
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  
`;
export const ContainerTitleWithNumber = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelCard = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: #000;
`;

export const StatusContainer = styled.div<IStatus>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  background: ${(props) =>
    props.active
      ? stylesGlobalDefault.activedItem
      : stylesGlobalDefault.inactivedItem};
  padding: 4px 8px;
  border-radius: 20px;

  strong {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    text-transform: uppercase;
    color: #fff;
  }
`;
