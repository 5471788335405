import styled from 'styled-components';
import { stylesGlobalDefault } from '../../../global/globalStyle';

export const defaultColor =  stylesGlobalDefault.orange ;

export const ContainerSchedule = styled.div`
  display: inline-table;
  vertical-align: middle;

  width: ${(props) => props.itemType};
  height: ${(props) => props.itemType};
  margin-left: 4px;

  background: ${(props) => props.color};
  border-radius: ${(props) => props.itemProp};

  flex: initial;
  order: 1;
  flex-grow: 0;

  margin-left: 4px;
`;

export const Scheduled = styled.span`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: ${(props) => props.color};
  font-size: 15px;
`;