/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { keyframes } from 'styled-components';

// Keyframe animation for the spinner rotation
const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Styled component for the spinner
const Spinner = styled.div`
  border: 4px solid #f3f3f3; /* Light gray border */
  border-top: 4px solid #3498db; /* Blue border on top */
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: ${spinAnimation} 1s linear infinite; /* Apply the rotation animation */
`;

// Usage example
export const SpinnerProgress = ({style}:any) => {
  return <Spinner style={style} />;
};


