/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { IMyAccount } from "./interface";
import { DrawerDefault, strongLevel } from "@kentarepo/kcf-assets";
import { passwordHTML, photoHTML } from "./constants";
import { stringFormat } from "@kentarepo/kcf-services";

export const MyAccount: React.FC<IMyAccount> = ({
  openDrawer,
  setOpenDrawer,
  handleApplyChangeMyAccount,
  //photo part
  img,
  setImg,
  setFileImg,
  userStorage,
  storageBaseUrl,
  userPicture,
  containerNameUnit,
  addToast,
  //password
  currentPassword,
  setCurrentPassword,
  newPassword,
  setNewPassword,
  confirmNewPassword,
  setConfirmNewPassword,
  errors,
  errorsCurrentPassword,

}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [statusPassword, setStatusPassword] = useState<strongLevel>("weak");

  const onChangeNewPassword = (event: string) => {
    setNewPassword(event);
    const [_passwordChange, strongLvlChange] =
      stringFormat.isStrongPassword(event);
    
    const lvlBar = convertLevelBar(strongLvlChange);
    setStatusPassword(lvlBar);
  };

  const convertLevelBar = (param: string): strongLevel => {
    switch (param) {
      case "Forte":
        return "strong";
      case "Média":
        return "medium";
      default:
        return "weak";
    }
  };

  const passwordAuxHTML = () => {
    return (
      <>
        {photoHTML(
          img,
          setImg,
          setFileImg,
          userStorage,
          storageBaseUrl,
          userPicture,
          containerNameUnit,
          addToast
        )}
        {passwordHTML(
          currentPassword,
          setCurrentPassword,
          newPassword,
          onChangeNewPassword,
          confirmNewPassword,
          setConfirmNewPassword,
          showPassword,
          setShowPassword,
          errors,
          errorsCurrentPassword,
          statusPassword
        )}
      </>
    );
  };

  return (
    <DrawerDefault 
      title={"Minha conta"} 
      openDrawer={openDrawer} 
      setOpenDrawer={setOpenDrawer}
      onClose={() => setOpenDrawer(false)}
      onApply={handleApplyChangeMyAccount}
      children={passwordAuxHTML()} 
    />
  );
};
