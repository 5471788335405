import { Box } from "@mui/material";
import styled from "styled-components";
import { stylesGlobalDefault } from "../../../../global/globalStyle";

export const ContainerFormOneLabel = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const ContainerFormOneLabelSwitch = styled(Box)`
  margin-bottom: 12px;
  display: flex;
  margin-top: -10px;
  margin-left: -9px;
`;

export const UnitArea = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 30px 0;
`;
export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ListProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

export const Spacer = styled.div`
  width: 100%;
  border-bottom: 1px solid rgb(209, 219, 227);
  padding-bottom: 24px;
  margin-bottom: 26.5px;
`;

export const ContainerVaras = styled.div`
  //display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-items: stretch;
`;
export const ContainerTitleWithNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;
export const Title = styled.h3`
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: ${stylesGlobalDefault.blackMenu1};
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

export const AccessValidated = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const ListUnitArea = styled(Box)`
  max-height: 350px;
  height: 350px;
  min-height: 350px;
  overflow-y: auto;
  padding-right: 10px;

  @media (max-width: 1440px) and (max-height: 900px) {
    max-height: 200px; // ajuste conforme necessário para MacBook de 13"
    height: 200px;
    min-height: 200px;
  }

  @media (max-width: 1366px) and (max-height: 768px) {
    max-height: 250px;
    height: 250px;
    min-height: 250px;
  }

  @media (max-width: 1024px) {
    max-height: 150px; // ajuste conforme necessário
    height: 150px;
    min-height: 150px;
  }

  @media (max-width: 768px) {
    max-height: 100px; // ajuste conforme necessário
    height: 100px;
    min-height: 100px;
  }
`;
