import { AxiosError } from "axios";
import api from "../api";
import { returnResponse } from "../utils";
import { LogOperation } from "../../utils/entities/logOperation";

export const hearingTypeAttendantRole = {

  getAllRelationsProcedureCustomerTypeAttendantRole: async (
    customerId: number,
    hearingType: number
  ) => {

    if (!customerId || !hearingType) {
      return {
        status: false,
        message: "Não é possivel vincular sem o customer e o procedurecustomer",
      };
    }

    try {
      const { data } = await api.get(
        `/HearingTypeAttendantRole/GetHearingTypeAttendantRoleRelation`,
        {
          params: {
            customerId,
            hearingType: hearingType,
          },
        }
      );

      return {
        success: true,
        data: data,
      };

    } catch (error) {

      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data.message,
        });

        return {
          success: false,
          severity,
          status,
          body: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  vincularHearingTypeEAttendantRole: async (
    customerId: number,
    hearingTypeId: number,
    attendantRoleId: number
  ) => {

    if (!customerId || !hearingTypeId || !attendantRoleId) {
      return {
        status: false,
        message: "Não é possivel vincular sem o customer e o procedurecustomer",
      };
    }

    try {
      const { data } = await api.post(
        `/HearingTypeAttendantRole/Add`,
        {
          customerId,
          hearingTypeId,
          attendantRoleId,
          device: LogOperation.getDevice(),
          ip: LogOperation.getMachineIP(),
          logPoliceUnitId: LogOperation.getPoliceUnitId(),
          logUserId: LogOperation.getUserId(),
        }
      );
      return {
        success: true,
        body: data.message,
      };
    } catch (error) {

      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data.message,
        });

        return {
          success: false,
          severity,
          status,
          body: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  deletarVinculoHearingTypeEAttendantRole: async (id: number) => {

    if (!id) {
      return {
        status: false,
        message: "Não é possivel deletar",
      };
    }

    try {
      await api.delete(`/HearingTypeAttendantRole/Delete`, {
        params: {
          id,
        },
      });

      return {
        success: true,
      };
    } catch (error) {

      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data.message,
        });

        return {
          success: false,
          severity,
          status,
          body: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  getAll: async (customerId: number, active: number) => {    
    const situation = active === undefined ||active === 2 ? '' : `&active=${active}`
    try {
      const { data } = await api.get<any>(
        `/HearingType/GetAllByCustomerId?customerId=${customerId}${situation}`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {

      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }
    }
  },
};
