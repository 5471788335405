import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";

export interface shortcut {
  shortcut: string;
  method: string;
}

export const handlePlayStop = (
  startPause: boolean,
  toggleVideoPlay: () => void,
  toggleVideoPause: () => void
) => {
  if (startPause) {
    toggleVideoPlay();
  } else {
    toggleVideoPause();
  }
  startPause = !startPause;
};

export const handleActionBackTime = (
  toggleFiveSeconds: (type: "advance" | "back") => void
) => {
  toggleFiveSeconds("back");
};

export const handleActionNextTime = (
  toggleFiveSeconds: (type: "advance" | "back") => void
) => {
  toggleFiveSeconds("advance");
};


export const handleSlowSpeed = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $videoPlayer: React.MutableRefObject<any>,
  toggleRate: (rate: number) => void
) => {
  const speedCurrente = $videoPlayer.current.playbackRate;
  if (speedCurrente === 0.5) {
    return;
  }
  toggleRate(speedCurrente - 0.25);
};


export const handleFastSpeed = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $videoPlayer: React.MutableRefObject<any>,
  toggleRate: (rate: number) => void
) => {
  const speedCurrente = $videoPlayer.current.playbackRate;
  if (speedCurrente === 2) {
    return;
  }
  toggleRate(speedCurrente + 0.25);
};

export const handleAddShortCuts = (listShortcuts: Array<shortcut>) => {
  const playStopShortcuts: shortcut = {
    method: "GlobalPlayStop",
    shortcut: getValueFromlocalStorage("GlobalPlayStop") ?? "",
  };

  const actionNextTimeShortcuts: shortcut = {
    method: "GlobalActionNextTime",
    shortcut: getValueFromlocalStorage("GlobalActionNextTime") ?? "",
  };

  const actionBackTimeShortcuts: shortcut = {
    method: "GlobalActionBackTime",
    shortcut: getValueFromlocalStorage("GlobalActionBackTime") ?? "",
  };

  const slowSpeedShortcuts: shortcut = {
    method: "GlobalSlowSpeed",
    shortcut: getValueFromlocalStorage("GlobalSlowSpeed") ?? "",
  };

  const fastSpeedShortcuts: shortcut = {
    method: "GlobalFastSpeed",
    shortcut: getValueFromlocalStorage("GlobalFastSpeed") ?? "",
  };

  listShortcuts.push(playStopShortcuts);
  listShortcuts.push(actionNextTimeShortcuts);
  listShortcuts.push(actionBackTimeShortcuts);
  listShortcuts.push(slowSpeedShortcuts);
  listShortcuts.push(fastSpeedShortcuts);
};
