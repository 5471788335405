/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../../hooks/toast";
import { TipoParticipanteData } from "../../../services/participantes";
import { profilesService } from "../../../services/profiles";
import { IProfile } from "../../../services/profiles/interfaces";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { setUserCreateProfile } from "../../../store/modules/profileCreate/actions";
import { setUserCreateUnit } from "../../../store/modules/userCreateUnit/actions";
import { listSearch } from "../../../services/utils";
import { LogOperation } from "../../../utils/entities/logOperation";
import { Profile } from "./types";
import { AdminArea } from "../../../components/Body/AdminArea";
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { ContainerAdmin, ContainerLeftMenu } from "../Administration/styles";
import { Administration } from "../Administration";
import { LoadingProvider } from "./profile-hook";
import ProfileBodyModal from "../../../features/ProfileBodyModal";
import { DrawerDefault } from "@kentarepo/kcf-assets";
import { stringFormat } from "@kentarepo/kcf-services";

type ISituation = true | false | undefined;

export function Profiles() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [actived, setActived] = useState(true);
  const { loading } = useSelector((state: any) => state.loading);
  const [adminSwitch, setAdminSwitch] = useState<number>(0);
  const [idProfile, setIdProfile] = useState<any>();
  const [requiredName, setRequiredName] = useState(false);
  const [requiredDescription, setRequiredDescription] = useState(false);
  const [situation, setSituation] = useState<ISituation>(true);
  const [search, setSearch] = useState("");
  const [listProfiles, setListProfiles] = useState<Array<IProfile>>(
    [] as Array<IProfile>
  );

  const authToken = getValueFromlocalStorage("@Kenta:access_Token");
  const user = getValueFromlocalStorage("@Kenta:user");
  const { addToast } = useToast();
  const dispatch = useDispatch();

  const editAttendantType = (participantItem: TipoParticipanteData) => {
    setActived(participantItem?.active);
    setIsEdit(true);
    setIdProfile(participantItem?.id);
    let newData: TipoParticipanteData | undefined;
    listProfiles.forEach((item) => {
      if (item?.name === participantItem?.name) {
        newData = item;
      }
    });
    setAdminSwitch(participantItem.type)

    dispatch(
      setUserCreateProfile({
        participantItem: {
          active: participantItem.active,
          customerId: participantItem.customerId,
          id: participantItem?.id,
          name: participantItem?.name,
          description: newData?.description,
        },
      })
    );
  };

  const closeModal = () => {
    setIsEdit(false);
    setOpenModal(false);
    setActived(true);
    setName("");
    setDescription("");
    localStorage.removeItem("newList");
    localStorage.removeItem("newUpdateList");
    localStorage.removeItem("createList");
    localStorage.removeItem("deconsteActionProfile");
    localStorage.removeItem("dataProfile");
    dispatch(
      setUserCreateUnit({
        errorName: false,
        errorEmail: false,
      })
    );
    dispatch(
      setUserCreateProfile({
        participantItem: {
          id: 0,
        },
      })
    );
  };

  async function editProfile() {
    if (!authToken) {
      return;
    }

    if (!name) {
      setRequiredName(true);
    } else {
      setRequiredName(false);
    }

    if (!description) {
      setRequiredDescription(true);
    } else {
      setRequiredDescription(false);
    }

    if (!name || !description) {
      addToast({
        type: "warning",
        title: "Perfil não válido",
        description:
          "Algum dos dados de criação do perfil não é válido, ou não foram adicionadas ações,  verifique novamente..",
      });
      return;
    }

    dispatch(showLoading());

    try {
      const response = await profilesService.changeProfileNomeEDescricao(
        authToken,
        idProfile,
        name,
        description,
        adminSwitch
      );

      if (response.status) {
        addToast({
          type: "success",
          title: `Perfil ${isEdit ? "atualizado" : "cadastrado"}`,
          description: `O perfil foi ${
            isEdit ? "atualizado" : "cadastrado"
          } com sucesso.`,
        });
        await getProfiles();
        await LogOperation.postLogOperation("Profile", 2, idProfile);
      } else {
        addToast({
          type: "error",
          title: `Erro`,
          description: `Não foi possivel atualizar o perfil`,
        });
      }
    } catch (error: any) {
      addToast({
        type: "error",
        title: `Erro`,
        description: `Não foi possivel atualizar o perfil`,
      });
    }
    dispatch(hideLoading());
    closeModal();
  }

  const getProfiles = async () => {
    try {
      const { body, status } = await profilesService.getAllByCustomerId(
        listSearch(situation)
      );

      if (status && body.length) {
        setListProfiles(body);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSitualtion = async (value: any) => {
    await localStorage.setItem("filter", value);
    switch (value) {
      case 1:
        return setSituation(true);
      case 0:
        return setSituation(false);
      case 2:
        return setSituation(undefined);
      default:
        return setSituation(undefined);
    }
  };
  const filteredData = listProfiles.filter((item: any) =>
    item?.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );
  const situationFilteredData = filteredData.filter(
    (item: any) => item.active === situation
  );

  useEffect(() => {
    (async () => {
      dispatch(showLoading());
      await getProfiles();
      dispatch(hideLoading());
    })();
  }, [idProfile, situation]);

  function getProfileObjectToSave(): Profile | null {
    if (!user || !name) {
      return null;
    }

    const userJson = JSON.parse(user);
    const profile = {
      customerId: userJson.customerId,
      name,
      description,
      active: actived,
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    };

    return profile;
  }

  function groupActionsWithPermissions() {
    const actionList = JSON.parse(localStorage.getItem("createList")!);

    if (!actionList || actionList.length <= 0) return [];

    function groupByCustomerId(actionList: any[]) {
      const groupedData = [];
      const customerIdMap: any = {};

      for (const item of actionList) {
        const customerId = item.systemObjectCustomerId;
        const action = { systemActionId: item.actionsList[0].systemActionId };

        if (customerIdMap[customerId]) {
          customerIdMap[customerId].actionList.push(action);
        } else {
          const customerData = {
            systemObjectCustomerId: customerId,
            actionList: [action],
          };
          customerIdMap[customerId] = customerData;
          groupedData.push(customerData);
        }
      }

      return groupedData;
    }

    const groupedData = groupByCustomerId(actionList);

    return groupedData;
  }

  //------------------------------------------------------------------------------------------------------------------------

  function validateObjectProfileWithPermissionsAndActions() {
    const proficonstoSave = getProfileObjectToSave();

    if (name === undefined || stringFormat.isEmptyOrSpaces(name)) {
      setRequiredName(true);
    }  else {
      setRequiredName(false);
    }

    if (description === undefined || stringFormat.isEmptyOrSpaces(description)) {
      setRequiredDescription(true);
    } else {
      setRequiredDescription(false);
    }

    if (
      !proficonstoSave?.active ||
      !proficonstoSave?.customerId ||
      !proficonstoSave?.description ||
      !proficonstoSave?.logPoliceUnitId ||
      !proficonstoSave?.logUserId ||
      requiredName === true ||
      requiredDescription === true
    ) {
      return false;
    }

    const permissionsAndActions = groupActionsWithPermissions();
    
    if (!permissionsAndActions || permissionsAndActions.length <= 0) {
      return false;
    }

    for (const item of permissionsAndActions) {
      for (const action of item.actionList) {
        if (
          Object.values(action).some(
            (value) => value === "" || value === null || value === undefined
          )
        ) {
          return false;
        }
      }
    }

    return true;
  }

  async function createProfile() {
    dispatch(showLoading());

    if (!validateObjectProfileWithPermissionsAndActions() || requiredName || requiredDescription) {
      addToast({
        type: "warning",
        title: "Perfil não válido",
        description:
          "Algum dos dados de criação do perfil não é válido, ou não foram adicionadas ações,  verifique novamente..",
      });
      dispatch(hideLoading());
      return;
    }

    const proficonstoSave = getProfileObjectToSave();

    if (!authToken) {
      return;
    }

    try {
      //Cria o perfil
      const { status, data, message } = await profilesService.createProfile(
        authToken,
        proficonstoSave
      );

      if (status) {
        addToast({
          type: "success",
          title: `Perfil ${isEdit ? "atualizado" : "cadastrado"}`,
          description: `O perfil foi ${
            isEdit ? "atualizado" : "cadastrado"
          } com sucesso.`,
        });
        // [TO DO]
        //await LogOperation.postLogOperation("Profile", 1, data);

        //Cria o relacionamento
        const permissionsAndActions = groupActionsWithPermissions();

        if (permissionsAndActions && permissionsAndActions.length > 0) {
          for (const systemObjectCustomer of permissionsAndActions) {
            const systemObjectUserCustomerProfile =
              await createSystemObjectUserCustomerProfile(
                data,
                systemObjectCustomer.systemObjectCustomerId
              );
            if (systemObjectUserCustomerProfile?.status) {
              const arrayActionWithRelation =
                systemObjectCustomer.actionList.map((item) => {
                  return {
                    systemActionId: item.systemActionId,
                    soUserCustomerProfileId:
                      systemObjectUserCustomerProfile.data,
                  };
                });
              const response = await createActionsByProfileRelated(
                arrayActionWithRelation
              );
              if (!response?.status) {
                addToast({
                  type: "error",
                  title: "Erro",
                  description: "Ocorreu algum erro",
                });
                break;
              }
            }
          }
        }
        closeModal();
      } else {
        addToast({
          type: "warning",
          title: "Aviso",
          description: message,
        });
      }
      setIdProfile(data);
    } catch (error) {
      addToast({
        type: "error",
        title: "Erro",
        description: "Perfil não foi criado",
      });
    }
    dispatch(hideLoading());
  }

  /**
   * Essa criação vai retornar o id do relacionamento do perfil com a devida action
   */
  async function createSystemObjectUserCustomerProfile(
    userCustomerProfileId: number,
    systemObjectCustomerId: number
  ) {
    if (!authToken) {
      return;
    }

    try {
      const { status, data } =
        await profilesService.createRelationProfileAction(
          authToken,
          userCustomerProfileId,
          systemObjectCustomerId
        );

      if (!status) {
        addToast({
          type: "error",
          title: "Erro",
          description: "Perfil relacionamento com action não foi criada",
        });
        return {
          status: false,
          data: null,
        };
      }

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      addToast({
        type: "error",
        title: "Erro",
        description: "Perfil não foi criado",
      });
      return {
        status: false,
        data: null,
      };
    }
  }

  /**
   * Essa criação vai retornar o id do relacionamento do perfil com a devida action
   */
  async function createActionsByProfileRelated(actions: any) {
    if (!authToken) {
      return;
    }

    try {
      const { status, data } =
        await profilesService.createActionByProfileRelation(authToken, actions);

      if (!status) {
        addToast({
          type: "error",
          title: "Erro",
          description: "Perfil relacionamento com action não foi criada",
        });
        return {
          status: false,
          data: null,
        };
      }

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      addToast({
        type: "error",
        title: "Erro",
        description: "Perfil não foi criado",
      });
      return {
        status: false,
        data: null,
      };
    }
  }

  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  function clear() {
    setIsEdit(false);
    setDescription("");
    setName("");
    setOpenModal(false);
    setRequiredName(false);
    setRequiredDescription(false);
    closeModal();
  }

  function openModalClearAdd() {
    clear();
    setOpenModal(true);
  }

  const handleAdminSwitchChange = (active: boolean) => {
    setAdminSwitch(active ? 2 : 0);
  };


  return (
    <ContainerAdmin>
      <ContainerLeftMenu>
        <Administration />
      </ContainerLeftMenu>
      <AdminArea
        title={"Perfis"}
        InputSearchLabel="Buscar por"
        InputSearchOnChange={(text) => setSearch(text)}
        searchText={search}
        loading={loading}
        list={situation === undefined ? filteredData : situationFilteredData}
        headerAddButtonOnClick={() => openModalClearAdd()}
        headerTrashButtonOnClick={() => { }}
        onClick={(item: any) => editAttendantType(item)}
        setItemReturnClick={null}
        setOpenModal={setOpenModal}
        setIsEdit={setIsEdit}
        permissionName={FORMS_ADMIN.PROFILE}
        onChangeSituation={(value: any) => handleSitualtion(value)}
      />
      <DrawerDefault
        openDrawer={isEdit || openModal}
        haveFooter
        setOpenDrawer={() => clear()}
        onClose={() => clear()}
        onApply={isEdit ? editProfile : createProfile}
        title={isEdit ? "Editando perfil" : "Criar perfil"}
      >
        <LoadingProvider>
          <ProfileBodyModal
            onChangeSwitch={() => {
              if (authToken)
                profilesService.changeProfileStatus(
                  authToken,
                  idProfile,
                  !actived
                );
              setActived(!actived);
            }}
            name={name}
            description={description}
            setName={setName}
            setDescription={setDescription}
            active={actived}
            formsListData={() => {}}
            nameError={requiredName}
            descriptionError={requiredDescription}
            isEdit={isEdit}
            userCustomerProfile={idProfile}
            adminSwitchValue={adminSwitch}
            onAdminSwitchChange={handleAdminSwitchChange}
          />
        </LoadingProvider>
      </DrawerDefault>
    </ContainerAdmin>
  );
}
