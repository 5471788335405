import React, { createContext, useContext, useState, ReactNode } from "react";

interface UploadingFileContextType {
  uploadingFile: boolean;
  setUploadingFile: React.Dispatch<React.SetStateAction<boolean>>;
  loadingUpload: boolean;
  setLoadingUpload: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadingFileContext = createContext<UploadingFileContextType | undefined>(undefined);

export const UploadingFileProvider = ({ children }: { children: ReactNode }) => {
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);

  return (
    <UploadingFileContext.Provider value={{ uploadingFile, setUploadingFile, loadingUpload, setLoadingUpload }}>
      {children}
    </UploadingFileContext.Provider>
  );
};

export const useUploadingFile = (): UploadingFileContextType => {
  const context = useContext(UploadingFileContext);
  if (!context) {
    throw new Error("useUploadingFile must be used within a UploadingFileProvider");
  }
  return context;
};
