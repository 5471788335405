import { BodyWaterMark, Box, ContainerCheckboxList, ContainerSelectTypeMark, ContainerWaterMaker } from './styles';
import { DropdownGlobal } from '../../../../../components/DropdownGlobal';
import { listYesOrNoAux } from '../../constants';
import { CheckBoxGlobal } from '../../../../../components/CheckBoxGlobal';
import { InputGeneralFile } from '../../../../../components/InputFileGlobal';

interface WatermarkCustomProps {

  fieldError: boolean
  setEditedValue: any
  selectedYesOrNoName: any
  editedValue: any
  setFile: any
  selectType: any
  typeMark: any
  labelList: any
  setLabelList: any
  filePathMark: any
}

const WatermarkCustom = ({
  fieldError,
  setEditedValue,
  selectedYesOrNoName,
  editedValue,
  setFile,
  selectType,
  typeMark,
  labelList,
  setLabelList,
  filePathMark
}: WatermarkCustomProps) => {
  return (
    <ContainerWaterMaker>
      <DropdownGlobal
        labelName={"Adicionar marca d'agua"}
        options={listYesOrNoAux}
        error={fieldError}
        setSelected={(e: any) => {
          setEditedValue(String(e));
        }}
        required={false}
        editDropdown={selectedYesOrNoName}
        defaultValue={selectedYesOrNoName}
      />
      {(selectedYesOrNoName === "1" || editedValue === "1") && (
        <>
          <InputGeneralFile
            label="Logomarca (upload imagem png 80x102)"
            type="file"
            onChange={(file: any) => setFile(file)}
            defaultValue={filePathMark}
          />
          <BodyWaterMark>
            <span>Layout</span>
            <ContainerSelectTypeMark>
              <Box onClick={() => selectType(1)} marginTop="50px">
                <div>
                  <input
                    defaultChecked={typeMark === 1}
                    checked={typeMark === 1}
                    onChange={() => selectType(1)}
                    type="radio"
                  />
                  <span>Legenda</span>
                </div>
              </Box>
              <Box onClick={() => selectType(2)} alignItems="flex-end" marginTop="50px">
                <div>
                  <input
                    defaultChecked={typeMark === 2}
                    checked={typeMark === 2}
                    onChange={() => selectType(2)}
                    type="radio"
                  />
                  <span>Legenda</span>
                </div>
              </Box>
            </ContainerSelectTypeMark>
            <ContainerSelectTypeMark>
              <Box onClick={() => selectType(3)} marginBottom="50px">
                <div>
                  <span>Legenda</span>
                  <input
                    defaultChecked={typeMark === 3}
                    checked={typeMark === 3}
                    onChange={() => selectType(3)}
                    type="radio"
                  />
                </div>
              </Box>
              <Box onClick={() => selectType(4)} alignItems="flex-end" marginBottom="50px">
                <div>
                  <span>Legenda</span>
                  <input
                    defaultChecked={typeMark === 4}
                    checked={typeMark === 4}
                    onChange={() => selectType(4)}
                    type="radio"
                  />
                </div>
              </Box>
            </ContainerSelectTypeMark>
          </BodyWaterMark>

          <ContainerCheckboxList>
            <CheckBoxGlobal
              checked={labelList.includes("PlaceName")}
              id={1}
              label="Unidade"
              onChecked={
                labelList.includes("PlaceName")
                  ? () =>
                    setLabelList(
                      labelList.filter(
                        (x: string) => x !== "PlaceName"
                      )
                    )
                  : () => setLabelList([...labelList, "PlaceName"])
              }
            />
            <  CheckBoxGlobal
              checked={labelList.includes("ProcedureNumber")}
              id={2}
              label="Número do processo"
              onChecked={
                labelList.includes("ProcedureNumber")
                  ? () =>
                    setLabelList(
                      labelList.filter(
                        (x: string) => x !== "ProcedureNumber"
                      )
                    )
                  : () => setLabelList([...labelList, "ProcedureNumber"])
              }
            />
            <CheckBoxGlobal
              checked={labelList.includes("HearingId")}
              id={3}
              label="Número da gravação"
              onChecked={
                labelList.includes("HearingId")
                  ? () =>
                    setLabelList(
                      labelList.filter((x: string) => x !== "HearingId")
                    )
                  : () => setLabelList([...labelList, "HearingId"])
              }
            />
            <CheckBoxGlobal
              checked={labelList.includes("ExpectedDate")}
              id={4}
              label="Data da gravação"
              onChecked={
                labelList.includes("ExpectedDate")
                  ? () =>
                    setLabelList(
                      labelList.filter(
                        (x: string) => x !== "ExpectedDate"
                      )
                    )
                  : () => setLabelList([...labelList, "ExpectedDate"])
              }
            />
          </ContainerCheckboxList>
        </>
      )}
    </ContainerWaterMaker>
  )
}

export default WatermarkCustom;