import React, { useState } from "react";
import { IChangePassword } from "./interface";
import { SHA1 as sha1 } from "crypto-js";
import { LeftFormContainer, LoadingContainer } from "../styles";
import { toastOption } from "../../../global/types";
import { ChangePasswordStepOne, ChangePasswordStepTwo } from "./constants";

import {
  stringFormat,
  userCustomerAuthenticationServices,
} from "@kentarepo/kcf-services";

import {
  LoadingComponent,
  SimpleToast,
  strongLevel,
} from "@kentarepo/kcf-assets";

export const ChangePassword: React.FC<IChangePassword> = ({
  api,
  guid,
  navigate,
  navigateTo,
  setChangedPassword,
  setSuccessMessageReturnAPI,
}) => {
  const [errors, setErrors] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [statusPassword, setStatusPassword] = useState<strongLevel>("weak");

  const [triggerToast, setTriggerToast] = useState<number>(0);
  const [toastMessage, setToastMessage] = useState("");
  const [typeToast, setTypeToast] = useState<toastOption>("warning");

  const [loading, setLoading] = useState(false);
  const [stepTwo, _setStepTwo] = useState(false);

  const onChangePassword = (event: string) => {
    setPassword(event);
    const [_passwordChange, strongLvlChange] =
      stringFormat.isStrongPassword(event);

    const lvlBar = convertLevelBar(strongLvlChange);
    setStatusPassword(lvlBar);
  };

  const convertLevelBar = (param: string): strongLevel => {
    switch (param) {
      case "Forte":
        return "strong";
      case "Média":
        return "medium";
      default:
        return "weak";
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const [passIsStrong, _strongLvl] = stringFormat.isStrongPassword(password);
    const [passIsStrongConfirm, _strongLvlConfirm] =
      stringFormat.isStrongPassword(confirmPassword);

    if (!passIsStrong || !passIsStrongConfirm) {
      setToastMessage(
        "Para continuar insira a senha com a regra determinada acima"
      );
      setTypeToast("error");
      setErrors(true);
      setTriggerToast(triggerToast + 1);
      return;
    }
    if (password !== confirmPassword) {
      setToastMessage("As senhas não correspondem");
      setTypeToast("error");
      setErrors(true);
      setTriggerToast(triggerToast + 1);
      return;
    }
    if (!guid) {
      setToastMessage("Código GUID não encontrado");
      setTypeToast("error");
      setErrors(true);
      setTriggerToast(triggerToast + 1);
      return;
    }
    try {
      setErrors(false);
      setLoading(true);
      const newPassword = sha1(password).toString().toUpperCase();
      const changepasswordAPI = userCustomerAuthenticationServices(api);
      const response = await changepasswordAPI.changePassword({
        guid: guid,
        password: newPassword,
      });

      if (response.status !== 200) {
        if (response.data.title) {
          setToastMessage(response.data.title);
        } else {
          setToastMessage(response.data);
        }

        setLoading(false);
        setErrors(true);
        setTypeToast("error");
        setTriggerToast(triggerToast + 1);
        return;
      }

      setLoading(false);
      setChangedPassword(true);
      setSuccessMessageReturnAPI(response.data);
    } catch (error) {
      setToastMessage("Ocorreu um erro inesperado");
      setLoading(false);
      setErrors(false);
      setTypeToast("error");
      setTriggerToast(triggerToast + 1);
      return;
    }
  };

  return (
    <>
      <LeftFormContainer>
        <SimpleToast
          trigger={triggerToast}
          id="simpleToastCertificate"
          title="Senha inválida"
          description={toastMessage}
          type={typeToast}
          handleCloseToast={() => setTriggerToast(0)}
        />
        {!stepTwo
          ? ChangePasswordStepOne(
              password,
              onChangePassword,
              confirmPassword,
              setConfirmPassword,
              showPassword,
              setShowPassword,
              errors,
              statusPassword,
              handleSubmit
            )
          : ChangePasswordStepTwo(navigate, navigateTo)}
        {loading && (
          <LoadingContainer>
            <LoadingComponent loading={loading} />
          </LoadingContainer>
        )}
      </LeftFormContainer>
    </>
  );
};
