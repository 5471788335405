/* eslint-disable @typescript-eslint/no-explicit-any */
import { ToastProvider } from './toast';

const AppProvider = ({ children }: any) => (
  <ToastProvider>
    {children}
  </ToastProvider>
);

export default AppProvider;
