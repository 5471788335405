/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import {
  DropdownGlobal,
  IDropDown,
} from "../../../../components/DropdownGlobal";
import { AttendantRoleService } from "../../../../services/attendantRole";
import {
  ContainerFormOneLabel,
  ContainerFormOneLabelSwitch,
  ContainerInputs,
} from "../../StyleCSS/bodyCreateEdit";
import { GeneralInput, Switch } from "@kentarepo/kcf-assets";

interface AttendantCreateEdit {
  valueEdit?: string;
  onChangeName: (e: string) => unknown;
  active: boolean;
  onChangeSwitch?: (e: boolean) => unknown;
  attendantRoleDropSelected?: any;
  attendantRoleName?: string;
  setAttendantRoleDropSelected: (e: number) => unknown;
  attendantRoleId?: number;
  hearingTypeId?: number;
  fieldError: boolean;
  fieldErrorParticipant: boolean;
}

export const CreateEditAttendantInAudience = (
  props: AttendantCreateEdit
): JSX.Element => {
  const [dropDownAttendantRole, setDropDownAttendantRole] = useState<
    IDropDown[]
  >([]);


  useEffect(() => {
    (async () => {
      if (props.hearingTypeId) {
        const response = await AttendantRoleService.getAttendantRoleByHearingType(props.hearingTypeId);
  
        const onlyActives = response.data.data.filter((e: any) => e.active);
        setDropDownAttendantRole(onlyActives);
      }
    })();
  }, [props.hearingTypeId]);

  return (
    <ContainerFormOneLabel>
      <ContainerFormOneLabelSwitch>
        <Switch
          id="react-switch"
          onChange={() =>
            props.onChangeSwitch ? props.onChangeSwitch(!props.active) : null
          }
          checked={props.active}
          label="Ativo"
        />
      </ContainerFormOneLabelSwitch>
      <GeneralInput
        id="name_attendant"
        error={!props.valueEdit && props.fieldError}
        value={props.valueEdit ?? ""}
        required
        data-cy="teste"
        onChange={(e) => props.onChangeName(e)}
        name={"name_attendant"}
        label={"Nome"}
      />
      <ContainerInputs>
        <DropdownGlobal
          id="attedant_role"
          labelName="Tipo de participante"
          options={dropDownAttendantRole}
          error={props.fieldErrorParticipant}
          required
          selected={props.attendantRoleDropSelected}
          setSelected={props.setAttendantRoleDropSelected}
          editDropdown={props.attendantRoleName}
        />
      </ContainerInputs>
    </ContainerFormOneLabel>
  );
};
