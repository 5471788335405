import styled from "styled-components";
import { globalStyle, colors } from "@kentarepo/kcf-assets";

export const Container = styled.div`
  position: absolute;
  width: 145px;
  background: ${colors.neutral_white}; 
  border: 2px solid ${colors.neutral_gray_60};
  margin-right: 70px;
  margin-bottom: -110px;
`;

export const ButtonContainer = styled.button`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 0;
  padding: 7px;
  background: ${colors.neutral_white};
  border: 0;
  cursor: pointer;

  span {
    font-size: 12px;
    font-family: ${globalStyle.fontFamily};
    font-weight: 700;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover {
    background: ${colors.primary_default};
    cursor: pointer;
  }
`;
