/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import {
  getValueFromlocalStorage,
  insertTolocalStorage,
} from "../utils/sessionStorageEncrypt";
import { HearingService } from "./hearing";
export const baseURL = import.meta.env.VITE_APP_BASEURL;
export const storageBaseUrl = import.meta.env.VITE_APP_STORAGE_BASE_URL;
export const baseBlob = import.meta.env.VITE_APP_STORAGE_BASE_URL


const api = axios.create({
  baseURL
});

async function refreshToken({ access_Token, refresh_Token }: any) {
  const { data } = await axios.post(baseURL + "/token/refresh", {
    access_Token,
    refresh_Token,
    platform: 0,
  });

  return {
    new_refresh_Token: data.data.refresh_Token,
    new_access_Token: data.data.access_Token,
  };
}


export const logout = async (hash: string) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    params: {
      hash: hash,
    },
  };
  const response = await api.delete(
    `/UserCustomerAccess/DeleteUserCustomerAccess`,
    config
  );

  await HearingService.logoutUna();

  localStorage.clear();
  return response;
};

api.interceptors.request.use(async (config: any) => {
  const dNow = new Date();

  const expireIn = getValueFromlocalStorage("@Kenta:expireIn");
  const access_Token = getValueFromlocalStorage("@Kenta:access_Token");
  const refresh_Token = getValueFromlocalStorage("@Kenta:refresh_Token");
  let token = getValueFromlocalStorage("@Kenta:access_Token");
  let refresh = false;
  const expired = expireIn ? dNow >= new Date(expireIn) : false;
  if (access_Token && refresh_Token && expired) {
    refresh = true;
  }

  if (refresh) {
    refresh = false;
    const dtNow = new Date();

    insertTolocalStorage(
      "@Kenta:expireIn",
      String(new Date(dtNow.getTime() + 30 * 60 * 1000))
    );
    const { new_refresh_Token, new_access_Token } = await refreshToken({
      access_Token,
      refresh_Token,
    });

    insertTolocalStorage("@Kenta:access_Token", new_access_Token);
    insertTolocalStorage("@Kenta:refresh_Token", new_refresh_Token);

    token = new_access_Token;

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    return config;
  } else {
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return config;
});

export default api