import React, { useState } from "react";
import { DatePickerKenta } from "@kentarepo/kcf-assets";
import { CreateRecordConstants } from "./constants";
import { IContainerRecord } from "./interface";
import { LoadSpinner } from "../../../LoadSpinner";
import {
  IHearingFileBody,
  audienceRecordService,
  hearingFileService,
} from "@kentarepo/kcf-services";

import {
  BaseDrawerModified,
  Container,
  ContainerTwoComponents,
  DrawerFormRightModified,
} from "./styles";
import { LogOperation } from "../../../../utils/entities/logOperation";

export const CreateRecord: React.FC<IContainerRecord> = ({
  title,
  isEdit,
  setClose,
  api,
  isOpen,
  data,
  addToast,
  recordToEdit,
  setRecordEditedReturn,
  date,
  setDate,
  time,
  setTime,
}) => {
  const [dateError, setDateError] = useState<boolean>(false);
  const [timeError, setTimeError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const cleanAll = () => {
    setDateError(false);
    setTimeError(false);
    setDate("");
    setTime("");
  };

  const handleClose = () => {
    cleanAll();
    setClose();
  };

  const handleApplyButton = async () => {
    if (!date || !time) {
      setDateError(!date);
      setTimeError(!time);

      addToast({
        type: "warning",
        title: "Preencha todos os campos",
        description:
          "Para criar uma gravação é necessário preeencher todos os campos",
      });
      return;
    }

    // Removido pois está quebrando a aplicação - não remover o codigo comentado
    // if (!compareDatesIgnoringSeconds(new Date(`${date}T${time}`))) {
    //   setDateError(true);

    //   addToast({
    //     type: "warning",
    //     title: "Data invalida",
    //     description: "Insira uma data valida",
    //   });
    //   return;
    // }

    setDateError(false);
    setTimeError(false);
    setLoading(true);

    if (!isEdit) {
      const audiencesServicesApi = audienceRecordService(api);
      const response = await audiencesServicesApi.createAudienceRecord({
        expectedDate: date && time ? new Date(`${date}T${time}Z`) : new Date(),
        hearingId: data?.hearingId || 0,
        userId: data?.userId || 0,
      });
      await LogOperation.postLogOperation("HearingFile", 1, 1);

      if (response.status) {
        handleClose();
        addToast({
          type: "success",
          title: "Gravação criada",
          description: "Sua gravação foi criada com sucesso",
        });
      }
    } else {
      if (recordToEdit) {
        const hearingFileServiceApi = hearingFileService(api);
        const updateBody: IHearingFileBody = {
          ...recordToEdit,
          updateIn: new Date(),
          expectedDate: new Date(`${date}T${time}Z`),
        };
        const response = await hearingFileServiceApi.update(updateBody);

        if (response.status === 200) {
          setRecordEditedReturn && setRecordEditedReturn(updateBody);
          handleClose();
          addToast({
            type: "success",
            title: "Gravação editada",
            description: response.data.message,
          });
          return;
        } else {
          addToast({
            type: "error",
            title: "Gravação editada",
            description: response.data.message,
          });
        }
      } else {
        addToast({
          type: "error",
          title: "Erro",
          description: "Gravação não encontrada",
        });
        return;
      }
    }
    setLoading(false);
  };

  const onChangeDate = (e: string | undefined) => {
    if (dateError) setDateError(false);
    setDate(e);
  };

  const onChangeTime = (e: string | undefined) => {
    if (timeError) setTimeError(false);
    setTime(e);
  };

  return (
    <>
      <BaseDrawerModified
        setShow={setClose}
        show={isOpen}
        id="drawer-create-record"
      >
        <DrawerFormRightModified
          title={isEdit ? `Editar ${title}` : `Criar ${title}`}
          onApply={handleApplyButton}
          onClose={handleClose}
          buttonLabel="Aplicar"
          haveFooter={true}
          children={
            <Container>
              <ContainerTwoComponents>
                <DatePickerKenta
                  label={CreateRecordConstants.OPEN_DATE}
                  error={dateError}
                  value={date}
                  onChange={onChangeDate}
                  required={true}
                  limitToday={true}
                />
                <DatePickerKenta
                  label={CreateRecordConstants.TIME}
                  error={timeError}
                  value={time}
                  onChange={onChangeTime}
                  required={true}
                  timePicker={true}
                />
              </ContainerTwoComponents>
            </Container>
          }
        />
      </BaseDrawerModified>
      <LoadSpinner loading={loading} />
    </>
  );
};

export default CreateRecord;
