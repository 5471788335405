import { insertTolocalStorage } from "../../utils/sessionStorageEncrypt";
import { SystemConfigurationService } from "../systemConfiguration";

interface IShortcut {
  name: string;
  value: string;
}

export const globalConfigurationsUtils = {
  setLocalstorage: async (customerId: number) => {
    const response = await SystemConfigurationService.getAllByCustomer(
      customerId
    );

    insertTolocalStorage("globalConfiguration", JSON.stringify(response.data));

    const listShortcuts: Array<IShortcut> =
      response.data.length &&
      response.data.find((x: any) => x.name === "Shortcuts")?.value &&
      JSON.parse(response.data.find((x: any) => x.name === "Shortcuts")?.value);

    setShortcutsLoad(listShortcuts);
  },
};

function setShortcutsLoad(listShortcuts: Array<IShortcut>) {
  if (listShortcuts) {
    const playStopAux = listShortcuts.find((x) => x.name === "playStop")?.value;
    const actionBackTimeAux = listShortcuts.find(
      (x) => x.name === "actionBackTime"
    )?.value;
    const actionNextTimeAux = listShortcuts.find(
      (x) => x.name === "actionNextTime"
    )?.value;
    const timeBackNextAux = listShortcuts.find(
      (x) => x.name === "timeBackNext"
    )?.value;
    const slowSpeedAux = listShortcuts.find(
      (x) => x.name === "slowSpeed"
    )?.value;
    const fastSpeedAux = listShortcuts.find(
      (x) => x.name === "fastSpeed"
    )?.value;

    insertTolocalStorage(
      "GlobalPlayStop",
      playStopAux ? playStopAux : "Espaço"
    );
    insertTolocalStorage(
      "GlobalActionBackTime",
      actionBackTimeAux ? actionBackTimeAux : "ArrowLeft"
    );
    insertTolocalStorage(
      "GlobalActionNextTime",
      actionNextTimeAux ? actionNextTimeAux : "ArrowRight"
    );
    insertTolocalStorage(
      "GlobalTimeBackNext",
      timeBackNextAux ? timeBackNextAux : "10"
    );
    insertTolocalStorage(
      "GlobalSlowSpeed",
      slowSpeedAux ? slowSpeedAux : "Ctrl+ArrowUp"
    );
    insertTolocalStorage(
      "GlobalFastSpeed",
      fastSpeedAux ? fastSpeedAux : "Ctrl+ArrowDown"
    );
  } else {
    insertTolocalStorage("GlobalPlayStop", "Espaço");
    insertTolocalStorage("GlobalActionBackTime", "ArrowLeft");
    insertTolocalStorage("GlobalActionNextTime", "ArrowRight");
    insertTolocalStorage("GlobalTimeBackNext", "5");
    insertTolocalStorage("GlobalSlowSpeed", "Ctrl+ArrowUp");
    insertTolocalStorage("GlobalFastSpeed", "Ctrl+ArrowDown");
  }
}
