import { Switch } from '@kentarepo/kcf-assets';
import { DropdownGlobal } from '../../../../../components/DropdownGlobal';
import { loginConfigAttemptAux, loginConfigPeriodAttemptsAux } from '../../constants';
import { useEffect, useState } from 'react';
import { ContainerContent } from './styles';

interface LoginConfigurationCustomProps {
  editedValue:string
  setEditedValue:(e:any)=>void
}

const LoginConfigurationCustom =
  ({
    editedValue,
    setEditedValue
  }: LoginConfigurationCustomProps) => {

    const initialConfig = JSON.parse(editedValue);
    const [loginConfigActive, setLoginConfigActive] = useState(initialConfig.Active)
    const [loginConfigAttempt, setLoginConfigAttempt] = useState(initialConfig.Attempt)
    const [loginConfigPeriodAttempts, setLoginConfigPeriodAttempts] = useState(initialConfig.PeriodAttempts)
    const [loginConfigBlockTime, setLoginConfigBlockTime] = useState(initialConfig.BlockTime)
    
    const updateConfigString = () => {
      const configObject = JSON.parse(editedValue);
      configObject.Active = loginConfigActive;
      configObject.Attempt = loginConfigAttempt;
      configObject.PeriodAttempts = loginConfigPeriodAttempts;
      configObject.BlockTime = loginConfigBlockTime;
      setEditedValue(JSON.stringify(configObject));
    };

    useEffect(() => {
      updateConfigString();
    }, [loginConfigActive, loginConfigAttempt, loginConfigPeriodAttempts, loginConfigBlockTime]);


    return (
      <ContainerContent>
        <Switch
          onChange={() => setLoginConfigActive(!loginConfigActive)}
          checked={loginConfigActive}
          label="Ativo"
        />
        <DropdownGlobal
          labelName={"Numero de tentativas"}
          options={loginConfigAttemptAux}
          error={false}
          setSelected={(e: any) => { setLoginConfigAttempt(e) }}
          required={true}
          editDropdown={initialConfig.Attempt}
          defaultValue={initialConfig.Attempt}
        />
        <DropdownGlobal
          labelName={"Período de tentativas"}
          options={loginConfigPeriodAttemptsAux}
          error={false}
          setSelected={(e: any) => { setLoginConfigPeriodAttempts(e) }}
          required={false}
          editDropdown={initialConfig.PeriodAttempts}
          defaultValue={initialConfig.PeriodAttempts}
        />
        <DropdownGlobal
          labelName={"Tempo de bloqueio"}
          options={loginConfigPeriodAttemptsAux}
          error={false}
          setSelected={(e: any) => { setLoginConfigBlockTime(e) }}
          required={false}
          editDropdown={initialConfig.BlockTime}
          defaultValue={initialConfig.BlockTime}
        />
      </ContainerContent>
    )
  }

export default LoginConfigurationCustom;