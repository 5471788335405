import styled from "styled-components";

interface ITextLocation {
  height?: number;
}


export const ContainerContent = styled.div<ITextLocation>`
  display:flex;
  flex-direction:column;
  height: ${(props) => props.height}%;
`;