/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useToast } from "../../../hooks/toast";
import { AttendantService } from "../../../services/attendant";

import { ContainerLeftMenu } from "../Administration/styles";
import { Administration } from "../Administration";
import { AdminArea } from "../../../components/Body/AdminArea";
import { FORMS_ADMIN } from "../../../utils/entities/permission";

import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { Container } from "./styles";
import { CreateUserComponent } from "../Unit/FormCreateUsers";
import { DrawerDefault } from "@kentarepo/kcf-assets";
import { useDispatch, useSelector } from "react-redux";

import { FormVinculeUnit } from "../Unit/FormCreateUsers/FormVinculeUnit";
import { SetUserCreateDistrict } from "../../../store/modules/userCreateUnit/actions";
import { verifyEmailFormat } from "../../../utils/verifyEmailFormat";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { AttendantRoleService } from "../../../services/attendantRole";
import axios from "axios";
import DateValidate from "../../../utils/dateValidate";
import {
  createNewUnityPost,
  createNewUserPost,
  updateUnityPut,
  updateUserPut,
} from "./service";
import { CustomerCertificateService } from "../../../services/customerCertificate";

export interface AttendantInterface {
  id: number;
  customerId: number;
  attendantRoleId: number;
  active: boolean;
  name: string;
  foreignKey?: string;
  createIn: Date;
  updateIn: Date;
}

export interface UserInterface {
  id: number;
  name: string;
  email: string;
  password: string;
  picture: null;
  customerId: number;
  emailValidated: true;
  termAccepted: true;
  createIn: string;
  updateIn: string;
  active: boolean;
}
interface ICustomerCertificate {
  id: string;
  cnpj: string;
  modality: string;
  title: string;
  description: string;
  amount: string;
  extraAmount: string;
  startDate: string;
  finishDate: string;
}

const CreateUsers: React.FC = () => {
  const { addToast } = useToast();
  const { userCreateDistrictList } = useSelector(
    (state: any) => state.userCreateDistrict
  );
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [unitOptions, setUnitOptions] = useState<any>([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userIdEdit, setUserIdEdit] = useState(0);
  const [requiredEmail, setRequiredEmail] = useState(false);
  const [requiredName, setRequiredName] = useState(false);
  const [unitId, setUnitId] = useState<number>();
  const [isEditCreated, setIsEditCreated] = useState(false);
  const [profileLabel, setProfileLabel] = useState(undefined);
  const [requiredProfile, setRequiredProfile] = useState(false);
  const [requiredUnit, setRequiredunit] = useState(false);
  const [requiredCourt, setRequiredCourt] = useState(false);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [situation, setSituation] = useState<any>(2);
  const [listAttendant, setListAttendant] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isActive, setIsActive] = useState(true);
  const [unitLabel, setUnitLabel] = useState(undefined);
  const [profileId, setProfileId] = useState<any>();
  const [expiredDate, setExpiredDate] = useState<any>();
  const [element, setElement] = useState<UserInterface>();
  const [extraContent, setExtraContent] = useState<any>();
  const [valueActive, setValueActive] = useState(false);
  const [updateItemId, setUpdateItemId] = useState<number>();
  const [customerCertificate, setCustomerCertificated] =
    useState<ICustomerCertificate>();

  const { userCustomerProfile } = useSelector((store: any) => store.profile);
  const [selectedType, setSelectedType] = useState<number | undefined>(
    profileId
  );
  const [courtLabel, setCourtLabel] = useState<string | undefined>();
  const [courtId, setCourtId] = useState<any>();

  const user = getValueFromlocalStorage("@Kenta:user");
  const parsedUser = user ? JSON.parse(user) : null;
  const userId = parsedUser.id;

  useEffect(() => {
    let userCustomerId: any = null;

    if (user) {
      const user_parsed = JSON.parse(user);
      userCustomerId = user_parsed.customerId;
    }
    CustomerCertificateService.getAll(userCustomerId).then((response) => {
      setCustomerCertificated(response.data.data);
    });
  }, []);

  const filteredData =
    listAttendant?.filter((item: any) =>
      item?.name?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase())
    ) ?? [];

  let userCustomerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    userCustomerId = user_parsed.customerId;
  }

  const clear = () => {
    setOpenDrawer(false);
  };

  const getList = async () => {
    setLoading(true);
    let customerId: any = null;

    if (user) {
      const user_parsed = JSON.parse(user);

      customerId = user_parsed.customerId;
      const response = await AttendantService.getAllUserCoustomer(
        customerId,
        situation
      );
      setListAttendant(response?.data?.data);
    }
    setLoading(false);
  };

  const startAddNewUser = () => {
    dispatch(showLoading());
    const lowercasedEmail = email.toLowerCase();
    if (!isEdit && !isEditCreated) {
      createNewUserPost({
        name,
        email: lowercasedEmail,
        userCustomerId,
        isActive,
        userCustomerProfile,
      }).then(async (item) => {
        if (item.status) {
          const responseGetAll = AttendantRoleService.getAllCustomerId(
            userCustomerId,
            1
          );
          const filteredUser = (await responseGetAll).data.data.find(
            (userInList: any) => userInList.email === lowercasedEmail
          );
          setUserIdEdit(filteredUser?.id);
          setRequiredName(false);
          setRequiredEmail(false);
          getList();
          setOpenDrawer(true);
          setIsEdit(true);
          addToast({
            type: "success",
            title: "Criar Usuario",
            description: "Usuario foi criado com sucesso.",
          });
        } else {
          if (!name || !lowercasedEmail) {
            addToast({
              type: "warning",
              title: "Criar usuários",
              description:
                "Para criar um novo usuário é necessário preencher os campos destacados.",
            });
            setRequiredName(true);
            setRequiredEmail(true);
            return;
          }

          if (!verifyEmailFormat(lowercasedEmail)) {
            addToast({
              type: "error",
              title: "Email inválido",
              description: "Verifique se o email digitado é válido",
            });
            setRequiredEmail(true);
            return;
          }

          if (verifyEmailFormat(lowercasedEmail) && name && lowercasedEmail) {
            addToast({
              type: "error",
              title: "Criar Usuario",
              description: item.message,
            });
          }
        }
        dispatch(hideLoading());
      });
    } else {
      setOpenDrawer(true);
    }
  };

  async function onClick(item: UserInterface) {
    setUserIdEdit(item.id);
    setOpenModal(true);
    setName(item.name);
    setIsActive(item.active);
    setElement(item);
    setExtraContent(item);
  }

  const createUnitPost = () => {
    dispatch(showLoading());
    if (DateValidate(expiredDate)) {
      addToast({
        type: "warning",
        title: "Criar unidade",
        description: "A data não pode ser inferior a hoje.",
      });

      return;
    }

    if (profileLabel && unitOptions && unitOptions?.length <= 0) {
      addToast({
        type: "warning",
        title: "Criar Unidade",
        description: "Não existe nenhuma Comarca disponivel para seleção.",
      });
      setRequiredunit(false);

      return;
    }
    createNewUnityPost({
      userId: userIdEdit,
      unitId: selectedType === 0 ? courtId : unitId,
      profileId,
      expiredDate,
      active,
      userCustomerProfile,
    }).then(async (item) => {
      if (item.ok === true) {
        resetToInitialData();
        setOpenDrawer(false);
        const { data } = await AttendantRoleService.getAllByUser(userIdEdit);
        dispatch(
          SetUserCreateDistrict({
            userData: data?.data,
            errorName: !name,
            errorEmail: !email,
          })
        );
        addToast({
          type: "success",
          title: "Cadastro de Unidade.",
          description: "Unidade foi cadastrada com sucesso.",
        });
      } else {
        if (
          !profileLabel ||
          !unitLabel ||
          (selectedType === 0 && !courtLabel)
        ) {
          setRequiredProfile(!profileLabel ? true : false);
          setRequiredunit(!unitLabel ? true : false);
          setRequiredCourt(selectedType === 0 && !courtLabel ? true : false);

          addToast({
            type: "warning",
            title: "Criar Unidade",
            description:
              "Para adicionar uma nova unidade é necessário preencher os campos destacados.",
          });

          return;
        }
      }
      dispatch(hideLoading());
    });
  };

  const updateUser = async () => {
    const lowercasedEmail = email.toLowerCase();

    if (
      customerCertificate &&
      customerCertificate.title === "Cadastro ativo" &&
      filteredData.length >= customerCertificate.amount + 1
    ) {
      addToast({
        type: "warning",
        title: "Cadastro de usuário",
        description: "Já foi atingido o limite máximo de usuários.",
      });
      return;
    }

    updateUserPut({
      element,
      userCustomerId,
      isActive,
      name,
      email: lowercasedEmail,
      userCustomerProfile,
      userId: userIdEdit,
    }).then((item) => {
      dispatch(showLoading());
      addToast({
        type: item.status ? "success" : "error",
        title: "Usuário atualizado",
        description: item.message,
      });

      if (item.status) {
        dispatch(showLoading());
        resetToInitialData();
        setOpenModal(false);
        dispatch(hideLoading());
        getList();
        setIsEditCreated(false);
      } else {
        if (!name) {
          setRequiredName(true);
        }
      }
      dispatch(hideLoading());
    });
  };

  const updateUnit = async () => {
    if (DateValidate(expiredDate)) {
      addToast({
        type: "warning",
        title: "Criar unidade",
        description: "A data não pode ser inferior a hoje.",
      });

      return;
    }
    updateUnityPut({
      updateItemId,
      userId: userIdEdit,
      unitId: selectedType === 0 ? courtId : unitId,
      profileId,
      expiredDate,
      active,
      userCustomerProfile,
    }).then(async (item) => {
      dispatch(showLoading());
      if (item.ok) {
        resetToInitialData();
        setOpenDrawer(false);
        const { data } = await AttendantRoleService.getAllByUser(userIdEdit);
        dispatch(
          SetUserCreateDistrict({
            userData: data?.data,
            errorName: !name,
            errorEmail: !email,
          })
        );
        addToast({
          type: "success",
          title: "Cadastro de Unidade.",
          description: item.message,
        });
        setIsEditCreated(false);
      }
      dispatch(hideLoading());
    });
  };

  useEffect(() => {
    getList();
  }, [situation]);

  async function setActiveAction(action: any) {
    try {
      dispatch(showLoading());

      await AttendantService.putUnit(userId!, valueActive);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.message);
      }
    }

    await handleSelectedAction(action);

    dispatch(hideLoading());
  }

  async function handleSelectedAction(item: any) {
    setUnitLabel(item.id && item.type === 0 ? item.places[0].name : item.place);
    setUnitId(item.unitId);
    setProfileLabel(item.profile);
    setProfileId(item.profileId);
    setCourtLabel(!item.id ? item.courtLabel : item.place);
    setCourtId(item.unitId);
    setExpiredDate(item.expireIn);
    setSelectedType(item.type);
    setActive(item.active);
    setOpenDrawer(true);
    setUpdateItemId(item.id);
    setIsEditCreated(true);
  }

  const resetToInitialData = () => {
    setProfileId(null);
    setProfileLabel(undefined);
    setUnitLabel(undefined);
    setExpiredDate(undefined);
    setCourtLabel(undefined);
    setRequiredEmail(false);
    setRequiredName(false);
    setIsActive(true);
    setRequiredProfile(false);
    setRequiredunit(false);
    setRequiredCourt(false);
    setUnitId(undefined);
    setActive(true);
  };

  const openComponentesClear = () => {
    setOpenDrawer(true);
    resetToInitialData();
  };

  const openModalCleared = () => {
    if (
      customerCertificate &&
      customerCertificate.title === "Cadastro ativo" &&
      filteredData.length >= customerCertificate.amount + 1
    ) {
      addToast({
        type: "warning",
        title: "Cadastro de usuário",
        description: "Já foi atingido o limite máximo de usuários.",
      });
      return;
    } else {
      dispatch(
        SetUserCreateDistrict({
          userData: [],
        })
      );
      setIsEdit(false);
      setName("");
      setEmail("");
      setActive(true);
      setIsActive(true);
      setElement(undefined);
      setExtraContent("");
      setOpenModal(true);
    }
  };

  const closeModal = () => {
    setOpenDrawer(false);
    resetToInitialData();
    setRequiredProfile(false);
    setRequiredunit(false);
    setRequiredCourt(false);
    setActive(true);
    setIsEditCreated(false);
  };

  const closeFirstModal = () => {
    setOpenModal(false);
    setIsEditCreated(false);
    setRequiredName(false);
    setRequiredEmail(false);
  };

  return (
    <Container>
      <ContainerLeftMenu>
        <Administration />
      </ContainerLeftMenu>
      <AdminArea
        loading={loading}
        title={"Usuários"}
        InputSearchLabel="Buscar por"
        InputSearchOnChange={(text) => setSearch(text)}
        searchText={search}
        list={filteredData}
        headerAddButtonOnClick={() => openModalCleared()}
        onClick={(item: any) => onClick(item)}
        setOpenModal={setOpenModal}
        onChangeSituation={(value: any) => setSituation(value)}
        permissionName={FORMS_ADMIN.USER_CUSTOMER}
        headerTrashButtonOnClick={function (): unknown {
          throw new Error("Function not implemented.");
        }}
        setItemReturnClick={setElement}
        setIsEdit={setIsEdit}
      />

      <DrawerDefault
        title={isEdit ? "Editar usuário" : "Cadastrar usuário"}
        openDrawer={openModal}
        setOpenDrawer={() => clear()}
        onClose={() => closeFirstModal()}
        onApply={isEdit ? updateUser : startAddNewUser}
        children={
          <CreateUserComponent
            onClickDelete={setActiveAction}
            items={(value: any) => setValueActive(value)}
            onClick={(item: any) => handleSelectedAction(item)}
            email={email}
            isActive={isActive}
            name={name}
            onChangeEmail={setEmail}
            onChangeName={setName}
            onChangeSwitch={setIsActive}
            userId={element?.id}
            openModalVincule={
              !isEdit ? startAddNewUser : () => setOpenDrawer(true)
            }
            userCreateDistrictList={userCreateDistrictList}
            errorEmail={requiredEmail}
            errorName={requiredName}
            onChangeExtraContent={(e) => setExtraContent(e)}
            extraContent={extraContent}
            isEdit={isEdit}
            isEditCreated={isEditCreated}
          />
        }
      />

      <DrawerDefault
        title={"Dados da unidade"}
        openDrawer={openDrawer}
        setOpenDrawer={() => openComponentesClear()}
        onClose={() => closeModal()}
        onApply={isEdit && !isEditCreated ? createUnitPost : updateUnit}
        children={
          <FormVinculeUnit
            customerId={userCustomerId}
            setProfileId={setProfileId}
            setProfileLabel={setProfileLabel}
            setUnitLabel={setUnitLabel}
            unitLabel={unitLabel}
            expiredDate={expiredDate}
            setExpiredDate={setExpiredDate}
            active={active}
            onChangeSwitch={() => setActive(!active)}
            onSelectType={(selectedType) => setUnitId(selectedType)}
            userId={userId}
            isEditCreated={isEditCreated}
            isEdit={isEdit}
            profileLabel={profileLabel}
            setSelectedType={setSelectedType}
            selectedType={selectedType}
            setCourtLabel={setCourtLabel}
            setCourtId={setCourtId}
            courtLabel={courtLabel}
            userIdEdit={userIdEdit}
            requiredProfile={requiredProfile}
            requiredUnit={requiredUnit}
            requiredCourt={requiredCourt}
            setUnitOptions={setUnitOptions}
          />
        }
      />
    </Container>
  );
};

export default CreateUsers;
