import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { BaseLabel, Multiline } from '@kentarepo/kcf-assets';

interface Props {
  serviceIntegrationAuthentication?: any;
  textFieldValue?: any;
  setTextFieldValue?: any;
  errorSIAuthent: any;
  setErrorSIAuthent: any;
}

const ServiceIntegrationAuthentication: React.FC<Props> = ({
  serviceIntegrationAuthentication,
  textFieldValue,
  setTextFieldValue,
  errorSIAuthent,
  setErrorSIAuthent,
}) => {


  const handleTextFieldChange = (value: string) => {
    setTextFieldValue(value);
    try {
      JSON.parse(value);
      setErrorSIAuthent(null);
    } catch (errorSIAuthent) {
      setErrorSIAuthent('JSON inválido. Por favor, corrija os erros.');
    }
  };

  useEffect(() => {
    setTextFieldValue(serviceIntegrationAuthentication);
  }, [serviceIntegrationAuthentication, setTextFieldValue]);

  return (
    <Grid container spacing={2} style={{ padding: '16px' }}>
      <Grid item xs={12}>
        <BaseLabel
          fontSize="18px"
          fontWeight="700"
          marginBottom="0px"
          marginTop="0px"
          marginLeft="0px"
          marginRight="0px"
          children={"Parâmetros de Acesso ao Serviço"}
        />
      </Grid>
      <Grid item xs={12}>
        <Multiline
          label="Parâmetros"
          value={textFieldValue}
          onChange={handleTextFieldChange}
          error={!!errorSIAuthent}
          helperText={errorSIAuthent}
          name={'serviceIntegrationNotification'}
        />
      </Grid>
    </Grid>
  );
};

export default ServiceIntegrationAuthentication;
