import React, { Fragment } from "react";

import { Container } from "./styles";
import { Dashboard } from "./dashboard";

export const MasterDashboard: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  return (
    <Fragment>
      <Container>
        <Dashboard />
      </Container>
    </Fragment>
  );
};
