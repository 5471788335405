/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";

import * as S from "./styles";
import { IProfile } from "./interface";
import ModalSelectProfile from "./ModalSelectProfile";
import ArrowDown from "../../assets/svg/arrow-down.svg";
import ArrowUp from "../../assets/svg/arrow-up.svg";

export const ProfileMenu: React.FC<{
  profiles: IProfile[];
  handleSelectProfile: (profile: any) => void;
  selected: {
    title: string;
    profileName: string;
    id: number;
  } | null;
}> = ({ profiles, selected, handleSelectProfile }) => {
  const [showSelector, setShowSelector] = useState(false);
  const statusRef = useRef<any>(null);

  useEffect(() => {
    const handleDocumentClick = (e: any) => {
      if (statusRef.current && !statusRef.current.contains(e.target)) {
        setShowSelector(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [showSelector, statusRef]);

  return (
    <S.Container ref={statusRef}>
      <S.ContainerSelected onClick={() => setShowSelector(!showSelector)}>
        <S.SelectedTitle>{`${selected?.title} - ${selected?.profileName}`}</S.SelectedTitle>
        <div>
          <img
            className="icon"
            src={showSelector ? ArrowUp : ArrowDown}
            style={{ cursor: "pointer" }}
            alt=""
          />
        </div>
      </S.ContainerSelected>
      {showSelector ? (
        <ModalSelectProfile
          handleSelectProfile={handleSelectProfile}
          profiles={profiles}
          selected={selected}
          setShowSelector={setShowSelector}
        />
      ) : null}
    </S.Container>
  );
};
