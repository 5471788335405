import { StatusItem } from "./styles";

export const TranscriptionStatus = (status: number) => {
  switch (status) {
    case 0:
      return <StatusItem>Na fila</StatusItem>;
    case 1:
      return <StatusItem>Carregando vídeo</StatusItem>;
    case 2:
      return <StatusItem>Extraindo áudio</StatusItem>;
    case 3:
      return <StatusItem>Transferindo áudio</StatusItem>;
    case 4:
      return <StatusItem>Transcrevendo áudio</StatusItem>;
    case 5:
      return <StatusItem>Encerrando</StatusItem>;
    case 6:
      return <StatusItem>Encerrado</StatusItem>;
    case 7:
      return <StatusItem>Cancelado</StatusItem>;
    case 8:
      return <StatusItem>Erro ao carregar vídeo</StatusItem>;
    case 9:
      return <StatusItem>Erro ao extrair áudio</StatusItem>;
    case 10:
      return <StatusItem>Erro ao transferir áudio</StatusItem>;
    case 11:
      return <StatusItem>Erro ao transcrever áudio</StatusItem>;
    case 12:
      return <StatusItem>Erro ao debitar crédito</StatusItem>;
    case 13:
      return <StatusItem>Erro de processamento</StatusItem>;
    case 14:
      return <StatusItem>Saldo insuficiente</StatusItem>;
    default:
      return <StatusItem>Na fila</StatusItem>;
  }
};
