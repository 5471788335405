/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from "axios";
import api from "../api";
import { returnResponse } from "../utils";
import { LogOperation } from "../../utils/entities/logOperation";

export const transcriptionServices = {
  post: async (router: string, body: any) => {
    try {
      return await api.post(router, body);
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  patch: async ({
    textPlain,
    textPlainSummary,
    id,
  }: {
    textPlain?: string;
    textPlainSummary?: string;
    id: string;
  }) => {
    try {
      const device = LogOperation.getDevice();
      const logUserId = LogOperation.getUserId();
      const { data } = await api.patch(
        `/HearingTranscription/Update?id=${id}`,
        [
          {
            op: "replace",
            value: textPlain ? textPlain : textPlainSummary,
            path: textPlain ? "textPlain" : "textPlainSummary",
          },
          {
            op: "2",
            value: device,
            path: "device",
          },
          {
            op: "2",
            value: logUserId,
            path: "logUserId",
          },
        ]
      );

      return {
        success: true,
        body: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  patchTemplate: async ({
    customerTemplateId,
    id,
  }: {
    customerTemplateId: number;
    id: string;
  }) => {
    try {
      const device = LogOperation.getDevice();
      const ip = LogOperation.getMachineIP();
      const logPoliceUnitId = LogOperation.getPoliceUnitId();
      const logUserId = LogOperation.getUserId();
      const { data } = await api.patch(
        `/HearingTranscription/Update?id=${id}`,
        [
          {
            op: "replace",
            value: customerTemplateId,
            path: "customerTemplateId",
          },
          {
            op: "2",
            value: device,
            path: "device",
          },
          {
            op: "2",
            value: ip,
            path: "ip",
          },
          {
            op: "2",
            value: logPoliceUnitId,
            path: "logPoliceUnitId",
          },
          {
            op: "2",
            value: logUserId,
            path: "logUserId",
          },
        ]
      );

      return {
        success: true,
        body: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  patchDisableAutoTranscription: async (id: string) => {
    try {
      const device = LogOperation.getDevice();
      const logUserId = LogOperation.getUserId();

      const { data } = await api.patch(
        `/HearingTranscription/Update?id=${id}`,
        [
          {
            op: "replace",
            value: false,
            path: "AutoTranscription",
          },
          {
            op: "2",
            value: device,
            path: "device",
          },
          {
            op: "2",
            value: logUserId,
            path: "logUserId",
          },
        ]
      );

      return {
        success: true,
        body: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  patchGeneric: async (value: string, id: string, path: string) => {
    try {
      const replaceBody = [
        {
          op: "replace",
          value: value,
          path: path,
        },
        {
          op: "2",
          value: LogOperation.getDevice(),
          path: "device",
        },
        {
          op: "2",
          value: LogOperation.getMachineIP(),
          path: "ip",
        },
        {
          op: "2",
          value: LogOperation.getPoliceUnitId(),
          path: "logPoliceUnitId",
        },
        {
          op: "2",
          value: LogOperation.getUserId(),
          path: "logUserId",
        },
      ];

      const { data } = await api.patch<any>(
        `/HearingTranscription/Update?id=${id}`,
        replaceBody
      );

      return {
        success: true,
        body: data,
      };
    } catch (error) {
      return {
        success: false,
        body: {},
      };
    }
  },
  transcriptionUpdatePut: async (body: any) => {
    const bodyData = {
      ...body,
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    };
    try {
      return await api.put('/HearingTranscription/Update', bodyData);
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  }
};
