import { hearingFileService } from "@kentarepo/kcf-services";
import api from "../services/api";
import { LogOperation } from "./entities/logOperation";

export const handleDeleteAttachmentList = async (deleteId: number) => {
  if (deleteId) {
    try {
      const hearingFileServiceApi = hearingFileService(api)
      const { status } = await hearingFileServiceApi.deleteById(deleteId);

      if (status === 200 && deleteId) {
        await LogOperation.postLogOperation("HearingFile", 3, deleteId);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

export const cancellUploadList = async (listUpload: any[]) => {
  listUpload.forEach(async (item) => {
    await handleDeleteAttachmentList(item.fileId)
  })
}