import { Box } from "@mui/material";
import styled from "styled-components";

export const ContainerFormOneLabel = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const ContainerFormOneLabelSwitch = styled(Box)`
  margin-bottom: 12px;
  display: flex;
  margin-top: -10px;
  margin-left: -9px;
`;
