import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePermissions } from "../../../../hooks/permission";
import { FORMS_RELATORIOS } from "../../../../utils/entities/permission";
import {
  IListItem,
  IListNavigation,
} from "../../../../features/menus/Left/interface";
import { LeftMenu } from "../../../../features/menus/Left";

// eslint-disable-next-line prefer-const
let listMenusOpen = ["consultar"];

export const ReportMenu: React.FC = () => {
  const currentLocale = useLocation();
  const navigate = useNavigate();

  const { read: readLogError } = usePermissions(FORMS_RELATORIOS.LOG_ERROR);
  const { read: readLogOperation } = usePermissions(
    FORMS_RELATORIOS.LOG_OPERATION
  );
  const { read: readTranscription } = usePermissions(
    FORMS_RELATORIOS.TRASCRIPTION
  );

  useEffect(() => {
    if (currentLocale.pathname === "/report") {
      navigate("/report/transcription");
    }
  }, []);
  
  const getListMenuReports = () => {
    const navigationsAux: Array<IListNavigation> = [];
    if (readTranscription) {
      navigationsAux.push({
        id: "transcriptionId",
        menuTitle: "Transcrições",
        locale: "/report/transcription",
        read: true,
        datacy: "transcricao",
      });
    }
    if (readLogOperation) {
      navigationsAux.push({
        id: "logOperationId",
        menuTitle: "Log de operações",
        locale: "/report/LogOperation",
        read: true,
        datacy: "Operações",
      });
    }
    if (readLogError) {
      navigationsAux.push({
        id: "logErrosId",
        menuTitle: "Log de erros",
        locale: "/report/LogErros",
        read: true,
        datacy: "Erros",
      });
    }

    const list: Array<IListItem> = [
      {
        title: "Consultar",
        navigation: navigationsAux,
      },
    ];

    if (navigationsAux.length === 0) {
      return [];
    }

    return list;
  };

  return (
    <LeftMenu
      currentLocale={currentLocale.pathname}
      type="report"
      menuTitle="Relatórios"
      data-cy="relatorios"
      menuList={getListMenuReports()}
      backgroundHeader="primary"
      navigate={navigate}
      listMenusOpen={listMenusOpen}
    />
  );
};
