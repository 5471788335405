import styled from "styled-components";

export const Container = styled.div`
  height: 72.6vh;
`;

export const FooterContainer = styled.div`
  display: flex;
  height: 40px;
  background: #d1dbe3;
  padding: 24px 30px;
  width: calc(100% - 54px);
  justify-content: flex-end;
`;


export const ButtonContainer = styled.div`
  width: 74px;
`

export const ContainerTiny = styled.div`
  height: auto;
`