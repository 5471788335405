import { httpType } from "../../../global/types";

export const handleAddButton = (
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>,
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>,
  setHttpType: React.Dispatch<React.SetStateAction<httpType>>
) => {
  setOpenDrawer(true);
  setIsEdit(false);
  setHttpType("post");
};

export const handleEditItem = (
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>,
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>,
  setHttpType: React.Dispatch<React.SetStateAction<httpType>>,
) => {
  setOpenDrawer(true);
  setIsEdit(true);
  setHttpType("put");
};

export const clearStates = (
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>,
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>,
  setObjectRetorned: React.Dispatch<any>
) => {
  setOpenDrawer(false);
  setIsEdit(false);
  setObjectRetorned(null);
};
