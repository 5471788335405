import { useEffect, useState } from "react";
import { DropdownGlobal } from "../../../../components/DropdownGlobal";
import { InputGeneral } from "../../../../components/Input";
import { AttendantRoleService } from "../../../../services/attendantRole";
import { maskPhone } from "../../../../utils/format";
import { Switch } from "@kentarepo/kcf-assets";
import {
  Container,
  ContainerBody,
  ContainerInputs,
  ContainerSwitch,
} from "./styles";
import { getValueFromlocalStorage } from "../../../../utils/sessionStorageEncrypt";

interface UnitCreateEdit {
  name: string;
  onChangeName: (e: any) => unknown;
  description: string;
  onChangeDescription: (e: any) => unknown;
  city: string;
  onChangeCity: (e: any) => unknown;
  address: string;
  onChangeAddress: (e: any) => unknown;
  addressNumber: number | undefined;
  onChangeAddressNumber: (e: any) => unknown;
  phone: string;
  onChangePhone: (e: any) => unknown;
  unit: boolean;
  onChangeUnit: (e: any) => unknown;
  extraContent: string;
  onChangeExtraContent: (e: any) => unknown;
  active: boolean;
  onChangeSwitch: (e: boolean) => unknown;
  latLng: string;
  onChangeLatLng: (e: any) => unknown;
  foreignKey: string;
  onChangeForeignKey: (e: any) => unknown;
  setCodeDistrict: (e: number) => unknown;
  codeDistrict: number;
  fieldErrorName: boolean;
  fieldErrorDescription: boolean;
  fieldErrorCity: boolean;
  fieldErrorAddress: boolean;
  fieldErrorAddressNumber: boolean;
  fieldErrorPhone: boolean;
  fieldErrorForeignKey: boolean;
  fieldErrorExtraContent: boolean;
  fieldErrorCodeDistrict: boolean;
}

interface itemDefaultUnit {
  id: number;
  name: string;
}

export const CreateEditUnit = (props: UnitCreateEdit): JSX.Element => {
  const [unitData, setUnitData] = useState([]);
  const user = getValueFromlocalStorage("@Kenta:user");



  const defaultUnit = () => {
    if (props.unit) return "Selecione";
    const foundUnit: any = unitData.find((item: itemDefaultUnit) => {
      return item?.id === props?.codeDistrict;
    });

    return foundUnit ? foundUnit.name : "Selecione";
  };

  const getUnits = async () => {
    if (user) {
      const user_parsed = JSON.parse(user);
      const customerId = user_parsed?.customerId;
      await AttendantRoleService.getAllByCustomerId2(customerId).then((item) => {
        setUnitData(item.body[0].districts);
      });
    }
  };

  useEffect(() => {
    getUnits();
  }, []);

  const handleNumber = (e: string) => {
    const numericValue = e.replace(/[^0-9]/g, "");
    props.onChangeAddressNumber(numericValue);
  }

  return (
    <>
      <Container>
        <ContainerBody>
          <ContainerSwitch>
            <Switch
              label="Ativo"
              checked={props.active}
              onChange={() => props.onChangeSwitch(!props.active)}
            />
          </ContainerSwitch>
          <ContainerInputs>
            <InputGeneral
              id="name_unit"
              error={props.fieldErrorName}
              maxLength={240}
              value={props.name}
              onChange={(e) => props.onChangeName(e)}
              type="text"
              label="Nome"
              required={true}
              startFocus={true}
            />
            <InputGeneral
              id="description_unit"
              marginLeft={12}
              error={props.fieldErrorDescription}
              maxLength={340}
              value={props.description}
              onChange={(e) => props.onChangeDescription(e)}
              type="text"
              label="Descrição"
              required={true}
              startFocus={false}
            />
          </ContainerInputs>
          <ContainerInputs>
            <InputGeneral
              id="city_unit"
              error={props.fieldErrorCity}
              maxLength={240}
              value={props.city}
              onChange={(e) => props.onChangeCity(e)}
              type="text"
              label="Cidade"
              required={true}
              startFocus={false}
            />
            <InputGeneral
              id="address_unit"
              marginLeft={12}
              error={props.fieldErrorAddress}
              maxLength={140}
              value={props.address}
              onChange={(e) => props.onChangeAddress(e)}
              type="text"
              label="Endereço"
              required={true}
              startFocus={false}
            />
          </ContainerInputs>
          <ContainerInputs>
            <InputGeneral
              id="number_unit"
              error={props.fieldErrorAddressNumber}
              maxLength={10}
              value={props.addressNumber}
              onChange={(e) => handleNumber(e)}
              type="text"
              label="Número"
              required={true}
              startFocus={false}
            />
            <InputGeneral
              id="phone_unit"
              marginLeft={12}
              error={props.fieldErrorPhone}
              maxLength={15}
              value={props.phone ? props.phone : ""}
              onChange={(e) => props.onChangePhone(maskPhone(e))}
              label="Telefone"
              required={true}
              startFocus={false}
            />
          </ContainerInputs>
          <ContainerInputs>
            <InputGeneral
              id="external_code_unit"
              error={props.fieldErrorForeignKey}
              maxLength={50}
              value={props.foreignKey}
              onChange={(e) => props.onChangeForeignKey(e)}
              type="text"
              label="Código extrerno"
              required={true}
              startFocus={false}
            />
          </ContainerInputs>
          <ContainerInputs>
            <InputGeneral
              id="external_extra_unit"
              error={props.fieldErrorExtraContent}
              maxLength={45}
              value={props.extraContent}
              onChange={(e) => props.onChangeExtraContent(e)}
              type="text"
              label="Conteudo extra"
              required={true}
              startFocus={false}
            />
          </ContainerInputs>
          <ContainerInputs>
            <Switch
              id="switch_unit"
              label={props.unit ? "Comarca" : "vara"}
              checked={props.unit}
              onChange={() => props.onChangeUnit(!props.active)}
            />
          </ContainerInputs>
          {!props.unit && (
            <ContainerInputs>
              <DropdownGlobal
                id="dropdown_unit"
                labelName={"Comarcas"}
                wantAnObject
                options={unitData || []}
                error={props.fieldErrorCodeDistrict}
                editDropdown="Selecione"
                defaultValue={defaultUnit() ?? "Selecione"}
                showDefaultValue
                disabled={false}
                required={true}
                setSelected={(selectedItem: any) => {
                  props.setCodeDistrict(selectedItem?.id);
                }}
              />
            </ContainerInputs>
          )}
        </ContainerBody>
      </Container>
    </>
  );
};
