import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  //min-width: 400px;
  //width: 100%;
  height: 100vh;
  background: #fff;
  cursor: default;

  padding: 0px 140px; 
  width: 380px;
`;
