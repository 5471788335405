/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";

import {
  IUserCustomerEntryData,
  stringFormat,
  userCustomerServices,
} from "@kentarepo/kcf-services";
import { MyAccount as MyAccountTemplate } from "../../../features/Modal/MyAccount";

import { updateUserAPI, verifyPasswordRoles } from "./constants";

import { v4 } from "uuid";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import moment from "moment";
import { userUtils } from "../../../utils/user";
import { IMyAccount } from "./interface";

import { SHA1 as sha1 } from "crypto-js";
import {
  getValueFromlocalStorage,
  insertTolocalStorage,
} from "../../../utils/sessionStorageEncrypt";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../store/modules/user/actions";
import { IBaseRestAPIResponse } from "../../../services/interface";
import { LoadingComponent } from "@kentarepo/kcf-assets";
import { LoadingContainer } from "./styled";
export const storageBaseUrl = import.meta.env.VITE_APP_STORAGE_BASE_URL;

export const MyAccount: React.FC<IMyAccount> = ({
  openDrawer,
  setOpenDrawer,
  changePicture,
  setChangePicture,
  containerName,
  setContainerName,
  setPhotoName,
}) => {
  const [userAPI, setUserAPI] = useState<IUserCustomerEntryData | null>(null);

  //api part
  const [userPassword, setUserPassword] = useState<string>("");

  //photo part
  const [file, setFile] = useState<File>();
  const [img, setImg] = useState<string>("");
  const { addToast } = useToast();
  const [pictureName, setPictureName] = useState<string>("");
  let photoNameAux: string = "";

  //password part
  const [errors, setErrors] = useState<boolean>(false);
  const [errorsCurrentPassword, setErrorsCurrentPassword] =
    useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  //flags to update
  let updateNewPassword = false;
  let updateNewPhoto = false;

  const dispatch = useDispatch();

  useEffect(() => {
    if (openDrawer) {
      onLoad();
      setErrors(false);
      setErrorsCurrentPassword(false);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
      updateNewPassword = false;
      updateNewPhoto = false;
      setImg("");

      const user = getValueFromlocalStorage("@Kenta:user");
      const parsedUser = user ? JSON.parse(user) : null;

      setPictureName(parsedUser.picture);
    }
  }, [openDrawer]);

  async function onLoad() {
    try {
      const user = getValueFromlocalStorage("@Kenta:user");
      const parsedUser = user ? JSON.parse(user) : null;

      const userCustomerService = userCustomerServices(api);
      const data = await userCustomerService.getUserCustomer(parsedUser.id);

      if (data.status === 200) {
        setUserPassword(data.data.data.password);
        setUserAPI(data.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleApllyButton = async () => {
    try {
      // --------------------------------------------------------------------------------------------------------------------change password option
      setErrorsCurrentPassword(false);
      setErrors(false);
      setLoading(true);

      if (
        !stringFormat.isEmptyOrSpaces(currentPassword) ||
        !stringFormat.isEmptyOrSpaces(newPassword) ||
        !stringFormat.isEmptyOrSpaces(confirmNewPassword)
      ) {
        if (stringFormat.isEmptyOrSpaces(currentPassword)) {
          addToast({
            type: "warning",
            title: "Aviso",
            description: "Digite sua senha atual",
          });
          setErrorsCurrentPassword(true);
          return;
        }

        const verifyPass = currentPassword.trim();
        const verifyPassSHA = sha1(verifyPass).toString().toUpperCase();

        if (userPassword !== verifyPassSHA) {
          addToast({
            type: "warning",
            title: "Aviso",
            description: "Senha atual incorreta",
          });
          setErrorsCurrentPassword(true);
          return;
        }

        const [passed, messageErrorPassword] = verifyPasswordRoles(
          newPassword,
          confirmNewPassword
        );

        if (!passed) {
          addToast({
            type: "warning",
            title: "Aviso",
            description: messageErrorPassword,
          });
          setErrors(true);
          return;
        } else {
          const verifyPass = confirmNewPassword.trim();
          const verifyPassSHA = sha1(verifyPass).toString().toUpperCase();

          if (userPassword === verifyPassSHA) {
            addToast({
              type: "warning",
              title: "Aviso",
              description: "Nova senha é igual a atual",
            });
            setErrors(true);
            setErrorsCurrentPassword(true);
            return;
          }

          if (
            !stringFormat.isEmptyOrSpaces(currentPassword) &&
            !stringFormat.isEmptyOrSpaces(newPassword) &&
            !stringFormat.isEmptyOrSpaces(confirmNewPassword)
          ) {
            updateNewPassword = true;
          }
        }
      }
      // --------------------------------------------------------------------------------------------------------------------change photo option
      if (img !== "" || file !== undefined) {
        updateNewPhoto = true;
      }

      // --------------------------------------------------------------------------------------------------------------------update password and/or photo
      if (updateNewPassword && updateNewPhoto) {
        let newImg = "";

        if (file?.name) {
          newImg = file?.name;
        } else {
          newImg = img;
        }

        if (userAPI) {
          const { status } = await updateUserAPI(
            api,
            userAPI,
            confirmNewPassword,
            newImg
          );

          const dataResturPhoto = await postPhoto();

          if (status === 200 && dataResturPhoto.status === 200) {
            await alterlocalStorage();
            addToast({
              type: "success",
              title: "Aviso",
              description: "Informações atualizadas com sucesso",
            });
            setOpenDrawer(false);
          }
        }
      } else if (updateNewPassword) {
        if (userAPI) {
          const { data, status }: IBaseRestAPIResponse = await updateUserAPI(
            api,
            userAPI,
            confirmNewPassword,
            null
          );

          if (status === 200) {
            addToast({
              type: "success",
              title: "Senha",
              description: data,
            });
            setOpenDrawer(false);
          }
        }
      } else if (updateNewPhoto) {
        const data: IBaseRestAPIResponse = await postPhoto();

        if (userAPI) {
          await updateUserAPI(api, userAPI, null, photoNameAux);
        }

        if (data.status === 200) {
          await alterlocalStorage();
          addToast({
            type: "success",
            title: "Aviso",
            description: data.data,
          });

          updateNewPassword = false;
          updateNewPhoto = false;
          setOpenDrawer(false);
        } else {
          addToast({
            type: "error",
            title: "Aviso",
            description: data.data,
          });
          updateNewPassword = false;
          updateNewPhoto = false;
          return;
        }
      }

      if (updateNewPassword === false && updateNewPhoto === false) {
        addToast({
          type: "warning",
          title: "Aviso",
          description: "Nada alterado",
        });
      }

      setOpenDrawer(false);
      setLoading(false);
    } catch {
      addToast({
        type: "error",
        title: "Aviso",
        description: "Ocorreu um erro inesperado",
      });
    } finally {
      updateNewPassword = false;
      updateNewPhoto = false;
      setLoading(false);
    }
  };

  const postPhoto = async () => {
    if (img === "" && file === undefined) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Escolha uma imagem ou tire uma foto",
      });

      const objReturn: IBaseRestAPIResponse = {
        data: "Escolha uma imagem ou tire uma foto",
        status: 400,
      };

      return objReturn;
    }

    let dataPhoto: any;
    let statusPhoto: number;

    if (file !== undefined) {
      const { data, status }: IBaseRestAPIResponse = await submitFile(file);
      dataPhoto = data.message;
      statusPhoto = status;
    } else {
      const { data, status }: IBaseRestAPIResponse = await submitFile(file);
      dataPhoto = data.message;
      statusPhoto = status;
    }

    const successAPI: IBaseRestAPIResponse = {
      data: dataPhoto,
      status: statusPhoto,
    };

    return successAPI;
  };

  async function submitFile(file: any) {
    try {
      const customerId = userUtils.getCustomerIdByUser();
      const user = getValueFromlocalStorage("@Kenta:user");
      const parsedUser = user ? JSON.parse(user) : null;
      const userId = parsedUser?.id;

      const extension = file.name.match(/\.[a-z0-9A-Z]{2,4}/i);
      const filename = `${v4()}_${moment().format(
        "DDMMYYYYHHmmss"
      )}${extension}`;
      const fileName = `audiencias\\${new Date().getFullYear()}\\unidade_${customerId}\\photo\\${filename}`;
      const fileItem = new FormData();
      fileItem.append("content", file, fileName);

      const userCustomerService = userCustomerServices(api);
      const { data, status }: IBaseRestAPIResponse =
        await userCustomerService.uploadPhoto(fileItem, userId ?? 0);

      if (status === 200) {
        if (data.ok) {
          setContainerName(data.data.container);
          photoNameAux = data.data.fileName;
          setPhotoName(data.data.fileName);
          setChangePicture(changePicture + 1);
        }
      }

      return { data, status };
    } catch (error) {
      addToast({
        type: "error",
        title: "Aviso",
        description: "Falha ao enviar o arquivo",
      });

      return { data: null, status: 500 };
    }
  }

  async function alterlocalStorage() {
    const useStorage = getValueFromlocalStorage("@Kenta:user");
    const user = useStorage ? JSON.parse(useStorage) : null;

    if (user) {
      const userCustomerService = userCustomerServices(api);
      const data = await userCustomerService.getUserCustomer(user?.id);

      const formatData = data.data.data;

      const newUser = {
        ...formatData,
        picture: formatData?.picture?.replace(/\\/gm, "/"),
        hash: user.hash,
      };

      insertTolocalStorage("@Kenta:user", JSON.stringify(newUser));
      dispatch(updateUser(newUser));

      setPhotoName(photoNameAux);
      setChangePicture(changePicture + 1);
    }
  }

  return (
    <>
      <MyAccountTemplate
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        addToast={addToast}
        handleApplyChangeMyAccount={handleApllyButton}
        img={img}
        setImg={setImg}
        setFileImg={setFile}
        containerNameUnit={containerName}
        storageBaseUrl={storageBaseUrl}
        userPicture={pictureName}
        userStorage={getValueFromlocalStorage("@Kenta:user") ?? ""}
        currentPassword={currentPassword}
        setCurrentPassword={setCurrentPassword}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        confirmNewPassword={confirmNewPassword}
        setConfirmNewPassword={setConfirmNewPassword}
        errors={errors}
        errorsCurrentPassword={errorsCurrentPassword}
      />
      {loading && (
        <LoadingContainer>
          <LoadingComponent loading={loading} />
        </LoadingContainer>
      )}
    </>
  );
};
