import { UserInterface } from './index';
import { AttendantService } from "../../../services/attendant";
import { LogOperation } from "../../../utils/entities/logOperation";

interface createNewUserprops {
  name: string
  email: string,
  userCustomerId: number,
  isActive: boolean,
  userCustomerProfile: {
    placeId: number
  }
}

interface updateUserPutprops {
  element?: UserInterface
  userCustomerId: number
  isActive: boolean
  name: string
  email: string
  userCustomerProfile: {
    placeId: number
  }
  userId: number
}

interface createNewUnityprops {
  userId: number
  unitId: any
  profileId: number
  expiredDate: any
  active: boolean
  userCustomerProfile: {
    placeId: number
  }
}

interface updateUnityPutprops {
  updateItemId?: number
  userId: number
  unitId?: number
  profileId: number
  expiredDate: any
  active: boolean
  userCustomerProfile: {
    placeId: number
  }
}

export const createNewUserPost = async ({ name, email, userCustomerId, isActive, userCustomerProfile }: createNewUserprops) => {

  const bodyCreate = {
    id: 0,
    name: name,
    email: email,
    customerId: userCustomerId,
    active: isActive,
    password: null,
    createIn: new Date(),
    updateIn: new Date(),
    device: LogOperation.getDevice(),
    ip: LogOperation.getMachineIP(),
    logPoliceUnitId: userCustomerProfile?.placeId,
    logUserId: LogOperation.getUserId(),
  }

  const response = await AttendantService.post(bodyCreate)
  return response
}

export const updateUserPut = async ({ element, userCustomerId, isActive, name, email, userCustomerProfile, userId }: updateUserPutprops) => {

  const bodyUpdate = {
    id: userId,
    customerId: userCustomerId,
    active: isActive,
    password: null,
    name,
    email,
    createIn: element?.createIn,
    updateIn: new Date(),
    emailValidated: element?.emailValidated,
    termAccepted: element?.termAccepted,
    picture: element?.picture,
    device: LogOperation.getDevice(),
    ip: LogOperation.getMachineIP(),
    logPoliceUnitId: userCustomerProfile?.placeId,
    logUserId: LogOperation.getUserId(),
  }

  const response = await AttendantService.upDate(bodyUpdate, userId);
  return response
}

export const createNewUnityPost = async ({ userId, unitId, profileId, expiredDate, active, userCustomerProfile }: createNewUnityprops) => {

  const bodyCreateunity = [{
    userId,
    placeId: unitId,
    userCustomerProfileId: profileId,
    expireIn: expiredDate ? expiredDate : null,
    active: active,
    device: LogOperation.getDevice(),
    ip: LogOperation.getMachineIP(),
    logPoliceUnitId: userCustomerProfile?.placeId,
    logUserId: LogOperation.getUserId(),
  }]

  const response = await AttendantService.postUnit(bodyCreateunity)
  return response.data
}

export const updateUnityPut = async ({ updateItemId, userId, unitId, profileId, expiredDate, active, userCustomerProfile }: updateUnityPutprops) => {

  const bodyUpdateUnit = {
    id: updateItemId,
    userId,
    placeId: unitId,
    userCustomerProfileId: profileId,
    expireIn: expiredDate !== "Invalid Date" ? expiredDate : null,
    active: active,
    device: LogOperation.getDevice(),
    ip: LogOperation.getMachineIP(),
    logPoliceUnitId: userCustomerProfile?.placeId,
    logUserId: LogOperation.getUserId(),
  }

  const response = await AttendantService.put(bodyUpdateUnit);
  return response.data
}