import React from "react";
import { BaseLabel, Multiline, SimpleToast } from "@kentarepo/kcf-assets";
import kentaLogo from "../../../src/assets/kenta.svg";
import { toastOption } from "../../global/types";
import { Axios, AxiosError } from "axios";
import {
  customerCertificateServices,
  formatDateTime,
} from "@kentarepo/kcf-services";

import {
  Body,
  ContainerImportCertificateLogged,
  ContainerInfosCertificate,
  DivLogo,
} from "./styles";

import {
  ICustomerCertificateGetResponse,
  ICustomerCertificateResponse,
} from "./interface";

const titleLabel = (chiildren: string) => {
  return (
    <BaseLabel
      children={chiildren}
      fontWeight="700"
      color="#44546a"
      fontSize="20px"
      marginBottom="0px"
      marginTop="0px"
      marginLeft="0px"
      marginRight="0px"
    />
  );
};

const subTitleLabel = (children: string) => {
  return (
    <BaseLabel
      children={children}
      fontWeight="700"
      color="#000000"
      fontSize="16px"
      marginBottom="0px"
      marginTop="5px"
      marginLeft="0px"
      marginRight="0px"
    />
  );
};

export const returnHtml = (
  projectName: string,
  modality: string,
  amount: string,
  extraAmount: string,
  startDate: string,
  finishDate: string
) => {
  return (
    <ContainerImportCertificateLogged>
      <Body>
        <ContainerInfosCertificate>
          <DivLogo>
            <img src={kentaLogo} />
            <BaseLabel
              children={projectName}
              fontWeight="700"
              color="#000000"
              fontSize="26px"
              marginTop="8px"
              marginLeft="0px"
              marginRight="0px"
            />
          </DivLogo>
        </ContainerInfosCertificate>
        <ContainerInfosCertificate style={{ marginTop: "58px" }}>
          {titleLabel("Modalidade")}
          {subTitleLabel(modality)}
        </ContainerInfosCertificate>
        <ContainerInfosCertificate>
          {titleLabel("Limite")}
          {subTitleLabel(amount)}
        </ContainerInfosCertificate>
        <ContainerInfosCertificate>
          {titleLabel("Extra")}
          {subTitleLabel(extraAmount)}
        </ContainerInfosCertificate>
        <ContainerInfosCertificate>
          {titleLabel("Data início")}
          {subTitleLabel(formatDateTime.getFullDate(new Date(startDate)))}
        </ContainerInfosCertificate>
        <ContainerInfosCertificate>
          {titleLabel("Data término")}
          {subTitleLabel(formatDateTime.getFullDate(new Date(finishDate)))}
        </ContainerInfosCertificate>
      </Body>
    </ContainerImportCertificateLogged>
  );
};

export const textAreaComponente = (
  label: string,
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  error: boolean,
  toastTypeForm: toastOption,
  toastBodyForm: string,
  toastTriggerForm: number
) => {
  return (
    <>
      <SimpleToast
        id="attendantRoleToastId"
        title={label}
        type={toastTypeForm}
        description={toastBodyForm}
        handleCloseToast={() => {}}
        trigger={toastTriggerForm}
      />
      <Multiline
        label={label}
        name="importCertificateName"
        onChange={(e) => setValue(e)}
        value={value}
        error={error}
        required
      />
    </>
  );
};

export const handleApplyButton = async (api: Axios, value: string) => {
  const customerCertificate = customerCertificateServices(api);

  const data: ICustomerCertificateResponse =
    await customerCertificate.importCertificate({
      textCrypted: value,
    });

  return data;
};

export async function onLoadList(customerId: number, api: Axios) {
  try {
    const customerCertificate = customerCertificateServices(api);

    const data = await customerCertificate.getCustomerCertificateByCustomerId({
      customerId: customerId,
    });

    return data;
  } catch (error) {
    const errorReturn = error as AxiosError;
    const dataReturn = errorReturn.response
      ?.data as ICustomerCertificateGetResponse;
    return dataReturn;
  }
}
