/* eslint-disable @typescript-eslint/no-explicit-any */
import { userCreateDistrictTypes } from "./actios.types";

export interface SetUserCreateDistrict {
  type: userCreateDistrictTypes.SET_USERCREATE_DISTRICT;
  payload: userCreateDistrictTypes;
}

export const SetUserCreateDistrict = (
  userCreateDistrict: any
): SetUserCreateDistrict => ({
  type: userCreateDistrictTypes.SET_USERCREATE_DISTRICT,
  payload: userCreateDistrict,
});

export const setUserCreateUnit = (userCreateUnit: any) => ({
  type: userCreateDistrictTypes.SET_USERCREATE_UNIT,
  payload: userCreateUnit,
});
