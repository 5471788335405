/* eslint-disable @typescript-eslint/no-explicit-any */

import ReactApexChart from 'react-apexcharts';

interface SeriesData {
    name: string;
    data: number[];
}
interface ApexColumnChartProps {
    categories: string[];
    series: SeriesData[];
}

const ApexColumnChart: React.FC<ApexColumnChartProps> = ({ series, categories }) => {
const columnChartOptions = {
    chart: {
        type: 'bar',
        height: '90%',
        width: '90%',
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '95%',
            borderRadius: 10,
            borderRadiusApplication: 'end'
        }
    },
    colors: ['#8DC572', '#337AB7'],
    dataLabels: {
        enabled: true,
        offsetY: 2,
        style: {
            fontSize: '16px',
            colors: ["#ffffff"]
        },
        formatter: function(val: number) {
            return `${val}`;
        },
    },
    
    xaxis: {
        categories,
        labels: {
            style: {
                fontSize: '16px', 
                fontWeight: '700',
                colors: '#7887A9' 
            }
        }
    },

    yaxis: {
        labels: {
            show: false
        }
    },

    grid: {
        show: false
    },

    fill: {
        opacity: 1
    },

    tooltip: {
        enabled: true,
        style: {
            fontSize: '18px',
        },
        marker: {
            show: true,
            size: 8
        },
        y: {
            formatter(val: number) {
                return `${val}`;
            }
        }
    },
    legend: {
        show: true,
        fontSize: '16px',
        fontWeight: 700,
        position: 'top',
        horizontalAlign: 'center',
        labels: {
            colors: '#7887A9',
            useSeriesColors: false 
        },
        markers: {
            width: 16,
            height: 16,
            radius: 20
        },
        itemMargin: {
            horizontal: 15,
            vertical: 8
        }
    },
    responsive: [
        {
            breakpoint: 600,
            options: {
                yaxis: {
                    show: false
                }
            }
        }
    ]
};

    return (
        <div id="chart" style={{ width: '100%', height: '100%' }}>
            <ReactApexChart options={columnChartOptions} series={series} type="bar"/>
        </div>
    );
};

export default ApexColumnChart;
