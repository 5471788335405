import Transcription from "./Transcription";
import { ITranscription } from "./Transcription/interface";
import { ISummary } from "./Summary/interface";
import Summary from "./Summary";
import Mark from "./Mark";
import { IMark } from "./Mark/interface";
import { IAta } from "./Ata/interface";
import Ata from "./Ata";

export const menu = ({
  transcription,
  summary,
  mark,
  ata,
}: {
  transcription: ITranscription;
  summary: ISummary;
  mark: IMark;
  ata: IAta
}) => {
  return {
    pageDefault: 0,
    menusList: [
      {
        id: 0,
        value: "Marcações",
      },
      {
        id: 1,
        value: "Transcrição",
      },
      {
        id: 2,
        value: "Resumo",
      },

      {
        id: 3,
        value: "Ata",
      },
    ],
    bodyList: [
      {
        id: 0,
        Component: () => <Mark {...mark} />,
      },
      {
        id: 1,
        Component: () => <Transcription {...transcription} />,
      },
      {
        id: 2,
        Component: () => <Summary {...summary} />,
      },

      {
        id: 3,
        Component: () => <Ata {...ata} />,
      },
    ],
  };
};
