import { AES, SHA1, enc } from "crypto-js";

export const getValueFromlocalStorage = (key: string, passName: string = "kentaPass"): string | null => {
    const encryptedKey = SHA1(passName + key).toString();
    const encryptedValue = localStorage.getItem(encryptedKey);
  
    if (encryptedValue) {
      const decryptedValue = AES.decrypt(encryptedValue, passName).toString(enc.Utf8);
      return decryptedValue;
    }
  
    return null;
  };