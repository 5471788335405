/* eslint-disable @typescript-eslint/no-explicit-any */
import { PermissionTypes } from "../actions/actios.types";

interface PlayerState {
  marking: any | null;
}

const initialState: PlayerState = {
  marking: null,
};

const playerReducer = (state = initialState, action: any): PlayerState => {
  switch (action.type) {
    case PermissionTypes.SET_CONTROLS:
      return {
        ...state,
        marking: action.payload,
      };
    default:
      return state;
  }
};
export default playerReducer;
