import React from "react";

import { IListItem, LeftMenu } from "@kentarepo/kcf-templates";
import { useLocation, useNavigate } from "react-router-dom";

export const Documents: React.FC = () => {
  const currentLocale = useLocation();
  const navigate = useNavigate();

  const list: Array<IListItem> = [
    {
      title: "Cadastros",
      navigation: [
        {
          menuTitle: "Administração Teste",
          locale: "/administration",
          read: true,
        },
        {
          menuTitle: "Documentos",
          locale: "/administration/documents",
          read: true,
        },
        {
          menuTitle: "Origem do procedimento",
          locale: "/dashboard/admin",
          read: true,
        },
        {
          menuTitle: "Participantes",
          locale: "/administration/participantes",
          read: true,
        },
        {
          menuTitle: "Tipos de Audiências",
          locale: "/dashboard/props",
          read: true,
        },
        // {
        //   menuTitle: "Tipos de participantes",
        //   locale: "/dashboard/props",
        // },
        {
          menuTitle: "Tipos de procedimento",
          locale: "/dashboard/procedureType",
          read: true,
        },
      ],
    },
    {
      title: "Configurações",
      navigation: [
        {
          menuTitle: "Unidades de polícia",
          locale: "/dashboard/props",
          read: true,
        },
        {
          menuTitle: "Documentos",
          locale: "/dashboard/props",
          read: true,
        },
        {
          menuTitle: "Origem do procedimento",
          locale: "/dashboard/admin",
          read: true,
        },
        {
          menuTitle: "Participantes",
          locale: "/dashboard/props",
          read: true,
        },
        {
          menuTitle: "Tipos de Audiências",
          locale: "/dashboard/props",
          read: true,
        },
        // {
        //   menuTitle: "Tipos de participantes",
        //   locale: "/dashboard/props",
        // },
        {
          menuTitle: "Tipos de procedimento",
          locale: "/dashboard/props",
          read: true,
        },
      ],
    },
  ];

  return (
    <LeftMenu
      currentLocale={currentLocale.pathname}
      type="administration"
      menuTitle="Administração"
      menuList={list}
      navigate={navigate}
    />
  );
};
