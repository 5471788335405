import React from "react";
import { FormOneLabel } from "./FormOneLabel";
import { Axios } from "axios";
import { httpType } from "../../global/types";
import { IFormOneLabel } from "./FormOneLabel/interface";
import { crudForm } from "./constants";

export type optionForm = "FormOneLabel" | "testeForm" | "CreateHearing";

export interface IModelFormBase {
  objectRetorned: any;
  setObjectRetorned: React.Dispatch<any>;
  api: Axios;
  list: any;
  httpType: httpType;
  setHttpType: React.Dispatch<React.SetStateAction<httpType>>;
  onSubmitForm: number;
  setOnSubmitForm: React.Dispatch<React.SetStateAction<number>>;
  endPoint: string;
}

export const modelForm = (
  optionForm: optionForm,
  modelFormBase: IModelFormBase,
  customProsForm: IFormOneLabel,
) => {
  switch (optionForm) {
    case crudForm.FORM_ONE_LABEL:
      return (
        <FormOneLabel
          displayLabel={customProsForm.displayLabel}
          id={customProsForm?.id}
          active={customProsForm.active}
          customerId={customProsForm?.customerId}
          name={customProsForm?.name}
          modelFormBase={modelFormBase}
          titleToast={customProsForm.titleToast}
        />
      );
    case crudForm.TESTE_FORM:
      return <h1>Não desenvolvido</h1>;
    default:
      return <h1>default</h1>
  }
};
