import {
  IconClockRecord,
  IconFile,
  IconFileCheck,
  IconFileUnknown,
  IconBell,
  IconTrendingUp,
  IconExclamationCircle
} from "@tabler/icons-react";

//import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

export interface IScheduleMenuList {
  id: number;
  title: string;
}

export const scheduleMenuList: IScheduleMenuList[] = [
  { id: 1, title: "Semana" },
  { id: 2, title: "Mês" },
  { id: 3, title: "Ano" },
];

export function capitalizeFirstLetter(text: string): IIcon {
  if (text.length === 0) {
    return text as IIcon; // Return the string as is if it's empty
  }
  return (text.charAt(0).toUpperCase() +
    text.slice(1).toLocaleLowerCase()) as IIcon;
}

export type IIcon =
  | "Ativas"
  | "Duração média"
  | "Inativas"
  | "Notificadas"
  | "Gravadas"
  | "Registradas"
  | "Em andamento"
  | "Com erro";

export const ShowIcon = ({ description }: { description: IIcon }) => {
  switch (description) {
    case "Registradas":
      return <IconFile stroke={2} />;
    case "Ativas":
      return <IconFileCheck stroke={2} />;
    case "Inativas":
      return <IconFileUnknown stroke={2} />;
    case "Notificadas":
      return <IconBell stroke={2} />;
    case "Gravadas":
      return <VideocamOutlinedIcon />;
    case "Em andamento":
      return <IconTrendingUp stroke={2} />;
    case "Com erro":
      return <IconExclamationCircle stroke={2} />;
    case "Duração média":
      return <IconClockRecord stroke={2} />;
    default:
      return <IconClockRecord stroke={2} />;
  }
};
