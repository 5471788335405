/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { Container, ContainerBody } from "./styles";
import "./styles.css";
import { DropdownGlobal } from "../../DropdownGlobal";
import { CardAdminItem } from "../../../features/CardAdminItem";
import { usePermissions } from "../../../hooks/permission";
import { LoadSpinner } from "../../../features/LoadSpinner";
import { GeneralInput, PageHeader } from "@kentarepo/kcf-assets";
import { ReactPaginateKenta } from "../../../features/cruds/ReactPaginate";

type AdmProps = {
  title: string;
  InputSearchLabel: string;
  InputSearchOnChange?: (text: string) => any;
  list: Array<any>;
  headerAddButtonOnClick?: () => unknown;
  headerTrashButtonOnClick?: () => unknown;
  onClick: (item: any) => void;
  setOpenModal: any;
  searchText: string;
  permissionName: string;
  focusInputSearch?: boolean;
  onChangeSituation?: any;
  displayAtivoFilter?: boolean;
  showStatus?: boolean;
  description?: boolean;
  showSearch?: boolean;
  setItemReturnClick?: any;
  setIsEdit?: (isEdit: boolean) => any;
  loading?: boolean;
};

export function AdminArea({
  title,
  InputSearchLabel,
  InputSearchOnChange,
  list,
  headerAddButtonOnClick,
  onClick,
  searchText,
  onChangeSituation,
  displayAtivoFilter = true,
  showStatus = true,
  description = false,
  showSearch = true,
  loading = false,
  permissionName,
  setIsEdit,
}: AdmProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { read, create, edit } = usePermissions(permissionName);

  const options = [
    {
      id: 1,
      name: "Ativo",
      datacy: "ativo",
    },
    {
      id: 0,
      name: "Inativo",
      datacy: "inativo",
    },
    {
      id: 2,
      name: "Todos",
      datacy: "todos",
    },
  ];

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const listComponent =
    read &&
    list &&
    list?.map((item: any, index: number) => (
      <CardAdminItem
        key={index}
        id={item?.id}
        name={description ? item.description : item?.name}
        active={item.active}
        data-cy={item?.name}
        onClickEditAction={() => (edit ? onClick(item) : null)}
        disable={edit ? false : true}
        showStatus={showStatus}
        setIsEdit={setIsEdit}
      />
    ));

  return (
    <Container>
      <PageHeader
        quantity={read && list?.length > 0 ? list.length : 0}
        id="header"
        data-cy="header"
        label={title}
        background="secondary"
        addClick={
          create && headerAddButtonOnClick ? headerAddButtonOnClick : undefined
        }
        disabled={create ? false : false}
      />
      <ContainerBody>
        {showSearch ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-center",
              alignItems: "flex-center",
              marginTop: "8px",
              marginBottom: "22px",
            }}
          >
            <div style={{ width: "400px" }}>
              <GeneralInput
                id="busca"
                data-cy="busca"
                name={`${InputSearchLabel}Name`}
                label={InputSearchLabel}
                value={searchText}
                onChange={(e) => InputSearchOnChange && InputSearchOnChange(e)}
                height="52px"
              />
            </div>
            {displayAtivoFilter ? (
              <div style={{ marginLeft: 40, marginTop: 7 }}>
                <DropdownGlobal
                  labelName="Situaçāo"
                  data-cy="situacao"
                  options={options}
                  setSelected={(value: any) => onChangeSituation(value)}
                  defaultValue={"Todos"}
                  showDefaultValue
                  height="24px"
                  width="136px"
                  required={false}
                  withoutMarginBottom={true}
                />
              </div>
            ) : null}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-center",
              alignItems: "flex-center",
              marginTop: "8px",
              marginBottom: "22px",
            }}
          ></div>
        )}

        {loading ? (
          <LoadSpinner loading={loading} />
        ) : (
          <ReactPaginateKenta
            list={listComponent}
            title={title}
            onChangePage={() => {}}
          />
        )}
      </ContainerBody>
    </Container>
  );
}
