import styled, { css } from "styled-components";

interface ISelectLevelOne {
  isSelectable: boolean;
  selected: boolean;
}

interface ISelectLevelTwo {
  selected: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif, "Courier New", Courier, monospace;
`;

export const ContainerSelected = styled.button`
  display: flex;
  background: #fff;
  border: none;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  cursor: pointer;
`;

export const SelectedTitle = styled.label`
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter", sans-serif, "Courier New", Courier, monospace;
  margin-right: 10px;
  cursor: pointer;
`;
export const ListContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  border: 2px solid #d1dbe3;
  position: absolute;
  top: 65px;
  padding: 4px;
  z-index: 9999;
`;
export const ContainerTitle = styled.div`
  min-width: 200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  span {
    margin-right: 50px;
  }

`;

export const ListLevelOne = styled.div<ISelectLevelOne>`
  color: #000;
  margin-bottom: 8px;
  font-family: "Inter", sans-serif, "Courier New", Courier, monospace;
  font-weight: 600;
  padding: 2px 8px;

  cursor: default;

  ${(props) =>
    props.isSelectable &&
    css`
      &:hover {
        background: #e4f1fa;
        cursor: pointer;
      }

      ${props.selected &&
      css`
        background: #e4f1fa;
      `}
    `}
`;

export const ListLevelTwo = styled.div<ISelectLevelTwo>`
  padding: 8px 12px;
  margin-top: 2px;
  font-family: "Inter", sans-serif, "Courier New", Courier, monospace;
  font-weight: 500;

  &:hover {
    background: #e4f1fa;
    cursor: pointer;
  }

  ${(props) =>
    props.selected &&
    css`
      background: #e4f1fa;
    `}
`;
