import { AES, enc, SHA1 } from "crypto-js";

// Function to insert a key-value pair in session storage
export const insertTolocalStorage = (key: string, value: string, passName: string="kentaPass"): void => {
  const encryptedKey = SHA1(passName + key).toString();
  const encryptedValue = AES.encrypt(value, passName).toString();

  localStorage.setItem(encryptedKey, encryptedValue);
};

// Function to retrieve a value from session storage
export const getValueFromlocalStorage = (key: string, passName: string = "kentaPass"): string | null => {
  const encryptedKey = SHA1(passName + key).toString();
  const encryptedValue = localStorage.getItem(encryptedKey);

  if (encryptedValue) {
    const decryptedValue = AES.decrypt(encryptedValue, passName).toString(enc.Utf8);
    return decryptedValue;
  }

  return null;
};



export const setValueInLocalStorage = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value)); // Convertendo o valor para uma string JSON antes de armazenar
  } catch (error) {
    console.error("Error while storing data in localStorage:", error);
  }
};
// Function to delete a key-value pair from session storage
export const deleteFromlocalStorage = (key: string, passName: string="kentaPass"): void => {
  const encryptedKey = SHA1(passName + key).toString();

  localStorage.removeItem(encryptedKey);
};
