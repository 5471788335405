import moment from "moment";

export const formatYmd = (date: Date | null) =>
  date?.toISOString().slice(0, 24);

export const formatDDMMYYYY = (date: string) => moment(date).format("DD/MM/YYYY")

export const formatMMDDYYYY = (date: Date) => moment(date).format("MM/DD/YYYY")

export const convertFileSize = (sizeInKB: any) => {
  const ONE_MB = 102400;
  const ONE_GB = 102400 * 102400;

  if (sizeInKB < ONE_MB) {
    return `${sizeInKB} KB`;
  } else if (sizeInKB >= ONE_MB && sizeInKB < ONE_GB) {
    const sizeInMB = (sizeInKB / ONE_MB).toFixed(2);
    return `${sizeInMB} MB`;
  } else {
    const sizeInGB = (sizeInKB / ONE_GB).toFixed(2);
    return `${sizeInGB} GB`;
  }
};

export const convertType = (type: number) => {
  switch (type) {
    case 5:
      return "Vídeo";
    case 3:
      return "Foto";
    case 2:
      return "Áudio";
    case 4:
      return "Documento";
  }
};

export const maskPhone = (value:string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};