import { SET_GLOBAL_CONFIGURATION } from "../actions/actions.types";

const INITIAL_STATE: any = {
  globalConfigurations: [],
};

const globalConfigurationsReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_GLOBAL_CONFIGURATION:
      return { ...state, globalConfigurations: action.globalConfigurations };
    default:
      return state;
  }
};

export default globalConfigurationsReducer;
