/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dropdown from "../../components/Dropdown";
import {
  ContainerFormOneLabelSwitch,
  ContainerLabelSwitch,
  Drawer,
} from "../../pages/app/StyleCSS/bodyCreateEdit";
import { profilesService } from "../../services/profiles";
import { Container } from "./styles";
import { useLoadingProfileContext } from "../../pages/app/Profiles/profile-hook";
import { getValueFromlocalStorage } from "../../utils/sessionStorageEncrypt";
import { GeneralInput, Switch } from "@kentarepo/kcf-assets";

type IProfileBodyModal = {
  active: boolean;
  onChangeSwitch: (active: boolean) => unknown;
  name?: any;
  description?: any;
  formsListData?: any;
  nameError: boolean;
  descriptionError: boolean;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  isEdit: boolean;
  userCustomerProfile?: number;
  adminSwitchValue?: any;
  onAdminSwitchChange?: any;
};
interface IFormAction {
  systemActionId: number;
  soUserCustomerProfileActionId: any;
  action: string;
  active: boolean;
}

interface IForm {
  userCustomerProfileId: any;
  systemObjectCustomerId: number;
  soUserCustomerProfileId?: any;
  systemObjectUserCustomerProfileId?: any;
  description: string;
  actionsList: IFormAction[];
}

interface IIdPair {
  optionId: string | number;
  actionId: string | number;
  soUserCustomerProfileActionId?: string | number | null;
  systemObjectUserCustomerProfileActionId?: string | number | null;
  soUserCustomerProfileId?: string | number;
}

const ProfileBodyModal: React.FC<IProfileBodyModal> = ({
  active,
  onChangeSwitch,
  name,
  description,
  setName,
  setDescription,
  nameError,
  descriptionError,
  isEdit,
  userCustomerProfile,
  adminSwitchValue,
  onAdminSwitchChange,
}) => {
  const { userCreateProfile } = useSelector(
    (state: any) => state.userCreateProfile
  );
  const [options, setOptions] = useState<any[]>([]);
  const [formListAction, setFormListAction] = useState<IIdPair[]>([]);
  const [formListReport, setFormListReport] = useState<IIdPair[]>([]);
  const [errorNameRequired, setErrorNameRequired] = useState(nameError);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [errorDescriptionRequired, setErrorDescriptionRequired] =
    useState(descriptionError);
  const { userCreateUnit } = useSelector(
    (state: any) => state.userCreateDistrict
  );
  const { loadingProfile } = useLoadingProfileContext();

  const authToken = getValueFromlocalStorage("@Kenta:access_Token");

  async function getData() {
    setLoadingUpdate(true);
    const response = await profilesService.getFormList(
      userCreateProfile?.participantItem?.id
        ? userCreateProfile?.participantItem?.id
        : 0
    );

    if (response?.body?.formsList) {
      const dataFormatted: any[] = response?.body?.formsList?.map(
        (item: IForm) => {
          return {
            title: item.description,
            id: item.systemObjectCustomerId,
            userCustomerProfileId: item.userCustomerProfileId,
            systemObjectCustomerId: item.systemObjectCustomerId,
            soUserCustomerProfileId:
              item.soUserCustomerProfileId ||
              item.systemObjectUserCustomerProfileId,
            systemObjectUserCustomerProfileId:
              item.systemObjectUserCustomerProfileId,
            actionsList: item.actionsList,
          };
        }
      );
      setOptions(dataFormatted);
      let dataProfile = {
        name: name,
        description: description,
      };
      localStorage.setItem("dataProfile", JSON.stringify(dataProfile));

      setOptions(dataFormatted);

      dataProfile = {
        name: "",
        description: "",
      };

      localStorage.setItem("dataProfile", JSON.stringify(dataProfile));
    }

    setLoadingUpdate(false);
  }

  const handleOptionClick = async (
    idPair: IIdPair,
    optionList: any[],
    setOptionList: Dispatch<any>
  ) => {
    let optionAlreadyPresent = optionList.some(
      (optionInList) =>
        optionInList.optionId === idPair.optionId &&
        optionInList.actionId === idPair.actionId
    );
    let newItem = false;
    let newDeleteItem = false;

    if (isEdit && authToken) {
      if (idPair.systemObjectUserCustomerProfileActionId) {
        //chamar o update
        const { status } = await profilesService.deleteAction(
          authToken,
          idPair.systemObjectUserCustomerProfileActionId
        );
        if (status) {
          idPair.soUserCustomerProfileActionId = -1;
          newDeleteItem = true;
        }
      } else {
        if (authToken && idPair.systemObjectUserCustomerProfileActionId) {
          const action = {
            soUserCustomerProfileId: Number(
              idPair.systemObjectUserCustomerProfileActionId
            ),
            systemActionId: Number(idPair.actionId),
          };
          //pegar a action criada
          const { status, data } =
            await profilesService.createActionByProfileRelationAction(
              authToken,
              action
            );
          idPair.systemObjectUserCustomerProfileActionId = data;
          if (status) {
            optionAlreadyPresent = false;
            newItem = true;
          }
        } else {
          if (userCustomerProfile) {
            try {
              if (!idPair.soUserCustomerProfileId) {
                const { status, data } =
                  await profilesService.createRelationProfileAction(
                    authToken,
                    Number(userCustomerProfile),
                    Number(idPair.optionId)
                  );
                idPair.soUserCustomerProfileId = data;
                if (status && data) {
                  const action = {
                    soUserCustomerProfileId: data,
                    systemActionId: Number(idPair.actionId),
                  };
                  const response =
                    await profilesService.createActionByProfileRelationAction(
                      authToken,
                      action
                    );
                  if (response.status && response.data) {
                    idPair.soUserCustomerProfileActionId = response.data;
                    newItem = true;
                    optionAlreadyPresent = false;
                  }
                }
              } else {
                const action = {
                  soUserCustomerProfileId: Number(
                    idPair.soUserCustomerProfileId
                  ),
                  systemActionId: Number(idPair.actionId),
                };
                const response =
                  await profilesService.createActionByProfileRelationAction(
                    authToken,
                    action
                  );
                if (response.status && response.data) {
                  idPair.soUserCustomerProfileActionId = response.data;
                  newItem = true;
                  optionAlreadyPresent = false;
                }
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
      }

      await getData();
    }

    const newOptionList = optionAlreadyPresent
      ? optionList.filter(
        (optionInList) =>
          !(
            optionInList.optionId === idPair.optionId &&
            optionInList.actionId === idPair.actionId
          )
      )
      : [...optionList, idPair];

    if (newItem || newDeleteItem) setOptionList([idPair]);
    else setOptionList(newOptionList);
  };

  useEffect(() => {
    setErrorNameRequired(nameError);
    setErrorDescriptionRequired(descriptionError);
  }, [nameError, descriptionError]);

  useEffect(() => {
    if (userCreateUnit?.errorName) {
      setErrorNameRequired(true);
    } else {
      setErrorNameRequired(false);
    }
    if (userCreateUnit?.errorEmail) {
      setErrorDescriptionRequired(true);
    } else {
      setErrorDescriptionRequired(false);
    }
  }, [userCreateUnit]);

  useEffect(() => {
    if (userCreateProfile?.participantItem) {
      setName(userCreateProfile?.participantItem?.name);
      setDescription(userCreateProfile?.participantItem?.description);
    }

    (async () => {
      setLoading(true);
      await getData();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    let foiDelete = false;
    const newOptionList = options.map((optionInList) => {
      //ajustar o id do id quando null
      let soUserCustomerProfileId = null;
      if (!optionInList.soUserCustomerProfileId) {
        const resultForFormAction = formListAction.filter(
          (item) => item.optionId == optionInList?.id
        );
        const resultForFormReport = formListReport.filter(
          (item) => item.optionId == optionInList?.id
        );
        if (resultForFormAction.length > 0) {
          soUserCustomerProfileId =
            resultForFormAction[0].soUserCustomerProfileId;
        } else if (resultForFormReport.length > 0) {
          soUserCustomerProfileId =
            resultForFormReport[0].soUserCustomerProfileId;
        }
      } else {
        soUserCustomerProfileId = optionInList.soUserCustomerProfileId;
      }

      // formListAction

      const updatedOption = {
        ...optionInList,
        soUserCustomerProfileId,
        actionsList: optionInList.actionsList.map((actionInList: any) => {
          let isRelatorio = false;
          if (optionInList.title == "Relatórios") {
            isRelatorio = true;
          }

          const valorFormReport = () => {
            if (
              formListReport.length > 0 &&
              formListReport[0].soUserCustomerProfileActionId == -1 &&
              foiDelete === false &&
              actionInList.systemActionId === formListReport[0].actionId
            ) {
              const novaFormListReportZero = [...formListReport];
              novaFormListReportZero[0].soUserCustomerProfileActionId = null;
              setFormListReport([...novaFormListReportZero]);
              foiDelete = true;
              return null;
            }
            if (
              formListReport.length === 0 &&
              actionInList.soUserCustomerProfileActionId === null
            ) {
              return null;
            }

            if (
              formListReport.length === 0 &&
              actionInList.soUserCustomerProfileActionId !== null
            ) {
              return actionInList.soUserCustomerProfileActionId;
            }

            return formListReport.length > 0 &&
              actionInList.systemActionId === formListReport[0].actionId &&
              optionInList?.id === formListReport[0].optionId
              ? formListReport.length > 0
                ? formListReport[0].soUserCustomerProfileActionId
                : null
              : actionInList.soUserCustomerProfileActionId;
          };

          const valorFormAction = () => {
            if (
              formListAction.length > 0 &&
              formListAction[0].soUserCustomerProfileActionId == -1 &&
              foiDelete === false &&
              actionInList.systemActionId === formListAction[0].actionId
            ) {
              const novaFormListActionZero = [...formListAction];
              novaFormListActionZero[0].soUserCustomerProfileActionId = null;
              setFormListAction([...novaFormListActionZero]);
              foiDelete = true;
              return null;
            }

            if (
              formListAction.length == 0 &&
              actionInList.soUserCustomerProfileActionId === null
            ) {
              return null;
            }

            if (
              formListAction.length == 0 &&
              actionInList.soUserCustomerProfileActionId !== null
            ) {
              return actionInList.soUserCustomerProfileActionId;
            }

            return formListAction.length > 0 &&
              actionInList.systemActionId === formListAction[0].actionId &&
              optionInList?.id === formListAction[0].optionId
              ? formListAction.length > 0
                ? formListAction[0].soUserCustomerProfileActionId
                : null
              : actionInList.soUserCustomerProfileActionId;
          };
          let updatedAction;

          if (optionInList.soUserCustomerProfileId == null) {
            updatedAction = {
              ...actionInList,
              active: [...formListAction, ...formListReport].some(
                (itemInList: IIdPair) => {
                  if (
                    optionInList.title == "Relatórios" &&
                    itemInList.optionId === 19
                  ) {
                    isRelatorio = true;
                  }
                  const itemIsPresent =
                    itemInList.optionId === optionInList?.id &&
                    itemInList.actionId === actionInList.systemActionId;
                  return itemIsPresent;
                }
              ),
            };
          } else {
            updatedAction = {
              ...actionInList,
              active: isRelatorio
                ? valorFormReport() != null
                : valorFormAction() != null,
              soUserCustomerProfileActionId: isRelatorio
                ? valorFormReport()
                : valorFormAction(),
            };
          }

          return updatedAction;
        }),
      };

      return updatedOption;
    });

    setOptions(newOptionList);
  }, [formListAction, formListReport]);

  return (
    <Container disableInteractions={loadingProfile}>
      <ContainerFormOneLabelSwitch>
        <Switch
          checked={active}
          onChange={() => onChangeSwitch(!active)}
          label="Ativo"
        />
      </ContainerFormOneLabelSwitch>
      <GeneralInput
        name={"Nome"}
        label={"Nome"}
        value={name}
        error={errorNameRequired}
        autofocus={true}
        maxLength={24}
        required={true}
        onChange={(e: string) => setName(e)}
      />

      <div style={{ marginTop: 16 }}>
        <GeneralInput
          name={"Descricao"}
          label={"Descrição"}
          value={description}
          error={errorDescriptionRequired}
          autofocus={false}
          maxLength={45}
          required={true}
          onChange={(e: string) => setDescription(e)}
        />
      </div>

      <ContainerLabelSwitch>
        <Switch
          label="Administrador"
          checked={adminSwitchValue}
          onChange={() => onAdminSwitchChange(!adminSwitchValue)}
        />
      </ContainerLabelSwitch>

      <Drawer style={{ marginTop: 32 }} />

      <Dropdown
        title="Ações"
        loadingUpdate={loadingUpdate}
        options={options}
        loading={loading}
        onChange={(value: IIdPair) => {
          handleOptionClick(value, formListAction, setFormListAction);
        }}
        isCreateNewProfile={!isEdit}
      />
    </Container>
  );
};

export default ProfileBodyModal;
