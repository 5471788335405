/* eslint-disable @typescript-eslint/no-explicit-any */
import { IUpload } from "../interface";
import {
  ADD_NEW_ATTACHMENT,
  SET_ATTACHMENT_LIST,
  REMOVE_ATTACHMENT_FILE,
  ADD_ATTACHMENT_UPLOADED,
  CLEAN_ATTACHMENT_UPLOADS,
  CLOSE_MODAL_ATTACHMENT_FILE,
  EDIT_ATTACHMENT_FILE,
  ADD_WITH_FILE_ID,
  CLEAN_ATTACHMENT_DELETE_LIST,
  REMOVE_ONE_BY_ONE_ATTACHMENT_TO_DELETE
} from "./actios.types";

export const addNewAttachment = (attachment: IUpload) => {
  return {
    type: ADD_NEW_ATTACHMENT,
    attachment,
  };
};

export const setAttachmentList = (attachmentList: Array<any>) => {
  return {
    type: SET_ATTACHMENT_LIST,
    attachmentList: attachmentList,
  };
};

export const addUploadedFile = (attachment: IUpload) => {
  return {
    type: ADD_ATTACHMENT_UPLOADED,
    attachment: attachment,
  };
};

export const cleanUpload = () => {
  return {
    type: CLEAN_ATTACHMENT_UPLOADS,
  };
};

export const removeAttachmentFile = (id: string) => {
  return {
    type: REMOVE_ATTACHMENT_FILE,
    id,
  };
};

export const closeModalAttachmentFile = (closeModal: boolean) => {
  return {
    type: CLOSE_MODAL_ATTACHMENT_FILE,
    closeModal,
  };
};

export const editAttachmentFile = (editFile: any) => {
  return {
    type: EDIT_ATTACHMENT_FILE,
    editFile,
  };
};

export const addUploadWithFileId = (item: any) => {
  return {
    type: ADD_WITH_FILE_ID,
    item
  }
}
export const cleanAttachmentDeleteList = () => {
  return {
    type: CLEAN_ATTACHMENT_DELETE_LIST,
  }
}

export const removeOneByOneAttachmentToDelete = (filename: string) => {
  return {
    type: REMOVE_ONE_BY_ONE_ATTACHMENT_TO_DELETE,
    filename
  }
}