/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from "axios";
import api from "../api";
import { returnResponse } from "../utils";
import { getValueFromlocalStorage } from "../utils/sessionStorageEncrypt";
import { LogOperation } from "../../utils/entities/logOperation";

export const AttendantRoleService = {
  getAll: async (customerId: number, status?: 0 | 1 | null) => {
    try {
      const { data } = await api.get<any>(
        `/AttendantRole/GetAllByCustomerId?customerId=${customerId}${status === 0 || status === 1 ? `&active=${status}` : ""
        }`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message:
            error?.response?.data.message ||
            error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getAttendantRoleByHearingType: async (hearingTypeId: number) => {
    try {
      const { data } = await api.get<any>(
        `/AttendantRole/GetAllByHearingTypeId?hearingTypeId=${hearingTypeId}`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message:
            error?.response?.data.message ||
            error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  getAllCustomerId: async (customerId: number, status?: 0 | 1 | null) => {
    try {
      const { data } = await api.get<any>(
        `/UserCustomer/GetAll?customerId=${customerId}${status !== null ? `&active=${status}` : ""
        }`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (err) {
      return {
        status: false,
        data: null,
        message: "Houve um erro ao consultar os registros.",
      };
    }
  },
  getAllByUser: async (userId: number) => {
    try {
      const { data } = await api.get<any>(
        `/UserCustomerProfile/GetUserCustomerProfilesByUserId?UserId=${userId}`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getAllPlace: async (customerId: number, status?: 0 | 1 | null) => {
    try {
      const { data } = await api.get<any>(
        `UserCustomerProfile/GetAllByCustomerIdWithMaster?customerId=${customerId}${status === 0 || status === 1 ? `&active=${status}` : ""
        }`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message:
            error?.response?.data.message ||
            error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  getAllByCustomerId2: async (customerId: number, userId?: number, type?: number) => {
    const userIdSelected = userId ? `&userId=${userId}` : ''
    const typeSelected = type ? `&type=${type}` : ''
    try {
      const user = getValueFromlocalStorage("@Kenta:user");

      const userParsed = user ? JSON.parse(user) : null;

      if (userParsed) {
        const { data } = await api.get(
          `/Place/GetAllToBindByUserIdAndTypeDTO?customerId=${customerId}${userIdSelected}${typeSelected}`
        );

        return {
          status: true,
          body: data.data,
        };
      } else {
        throw new Error("user not set on local storage");
      }
    } catch (error: any) {
      return {
        status: true,
        body: error.response,
      };
    }
  },
  getById: async (id: number) => {
    try {
      const { data } = await api.get<any>(`/AttendantRole/Get?id=${id}`);

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  getAllByCustomer: async (id: number) => {
    try {
      const { data } = await api.get<any>(
        `Place/GetAllByCustomerIdUserId?customerId=1&userId=${id}`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  //@ts-ignore
  post: async (authToken: string | null, attendantRole: any) => {
    try {
      const body = {
        ...attendantRole,
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
      };
      const { data } = await api.post<any>(`/AttendantRole/Add`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  //@ts-ignore
  put: async (authToken: string | null, attendantRole: any) => {
    try {
      const body = {
        ...attendantRole,
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
      };
      const { data } = await api.put<any>(`/AttendantRole/Update`, body);

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }
      let severity: "error" | "warning" | "success";
      severity = "error";
      return {
        status: false,
        message: "Error",
        data: null,
        severity: severity,
      };
    }
  },

  //@ts-ignore
  delete: async (authToken: string | null, attendantRoleId: number) => {
    try {
      const body = [
        {
          op: "replace",
          value: false,
          path: "active",
        },
      ];

      const { data } = await api.patch(
        `/AttendantRole/update?id=${attendantRoleId}`,
        body
      );

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};
