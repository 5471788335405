/* eslint-disable @typescript-eslint/no-explicit-any */
interface responseType {
  status: boolean;
  severity: "warning" | "success" | "error";
  data?: any;
  message?: string;
}

export const SEVERITY: "warning" | "success" | "error" = "success";

interface params {
  statusCode: number | undefined;
  message: string | undefined;
  data: any | undefined;
}

export function returnResponse({
  statusCode,
  message,
  data,
}: params): responseType {
  if (!statusCode || (!message && !data) || !data) {
    return {
      status: false,
      message: "Error",
      severity: "error",
      data: null,
    };
  }

  if (statusCode >= 200 && statusCode < 300) {
    return {
      severity: "success",
      message: data.message,
      status: true,
      data: data,
    };
  }

  if (statusCode >= 400 && statusCode < 500) {
    const messageReplaced = data && data.message? data.message : data;
    return {
      severity: "warning",
      message: messageReplaced,
      status: false,
      data: data,
    };
  }

  if (statusCode >= 500) {
    return {
      severity: "error",
      message: message,
      status: false,
      data: data,
    };
  }

  return {
    status: false,
    message: "Error",
    severity: "error",
    data: null,
  };
}

export const listSearch = (status: true | false | undefined) => {
  switch (status) {
    case true:
      return 1;
    case false:
      return 0;
    default:
      return null;
  }
};
