import { HIDE_LOADING, LOGIN_USER, SELECT_PROFILE, SHOW_LOADING, USER_DATA } from './actios.types';

export const loginUser = (user: unknown) => {
  return {
    type: LOGIN_USER,
    user,
  };
};

export const selectProfile = (profile: unknown) => {
  return {
    type: SELECT_PROFILE,
    profile,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const hideLoading = () => {
  return {
    type: HIDE_LOADING,
  };
};

export const userDAta = () =>{
  return{
    type: USER_DATA,
  };
};
