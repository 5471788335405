import styled from "styled-components";

export const Container = styled.div`
    display: flex;
`

export const ButtonAdd = styled.button`
width: 40px;
height: 40px;
padding: 10px;
background-color: #ababfe;
border-radius: 20px;
cursor: pointer;
border: none;
`