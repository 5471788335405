/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LogOperation } from "../../utils/entities/logOperation";
import api from "../api";
import { postUnitBody } from "../interface";

import { returnResponse } from "../utils";
import { AxiosError } from "axios";

export const AttendantService = {
  getAllUserCoustomer: async (customerId: number, active?: number) => {
    if (active !== 2) {
      return await api.get<any>(
        `/UserCustomer/GetAll?customerId=${customerId}&active=${active}`
      );
    } else {
      return await api.get<any>(
        `/UserCustomer/GetAll?customerId=${customerId}`
      );
    }
  },

  getAllByCostumerId: async (customerId: number, active?: number) => {
    try {
      const config = {
        timeout: 10000
      };
      if (active !== 2) {
        return await api.get<any>(
          `/Attendant/GetAllByCustomerId?customerId=${customerId}&active=${active}`,
          config
        );
      } else {
        return await api.get<any>(
          `/Attendant/GetAllByCustomerId?customerId=${customerId}`,
          config
        );
      }
    } catch (error) {
      console.error("Erro ao buscar os participantes:", error);
      throw new Error("Não foi possível carregar os participantes. Tente novamente mais tarde.");
    }
  },
  postNewAttendant: async (payload: any) => {
    return await api.post<any>(`Attendant/Add`, payload);
  },
  putNewAtendant: async (payload: any) => {
    return await api.put<any>(`Attendant/Update`, payload);
  },
  deleteAttendant: async (id: number) => {
    return await api.delete<any>(`Attendant/Delete?id=${id}`);
  },



  removeUnit: async (id: number) => {
    return await api.delete<any>(`/UserCustomerPlace/Delete?id=${id}`);
  },

  upDate: async (body: any, userId: number) => {
    try {
      const { data } = await api.put<any>(
        `/UserCustomer/Update?id=${userId}`,
        body
      );

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  put: async (userUnit: any) => {    
    try {
      const body = {
        ...userUnit,
      };
      const { data } = await api.put<any>(
        `/UserCustomerPlace/Update`,
        body
      );

      return {
        status: true,
        data: data,
      };
    } catch (error:any) {
      return  {
        status: false,
        data: error.response,
      }
    }
  },

  putUnit: async (registerId: number, value: boolean) => {
    try {
      const body = [
        {
          op: "replace",
          value: value,
          path: "active",
        },
      ];

      const { data } = await api.patch(
        `/UserCustomerPlace/Update?id=${registerId}`,
        body
      );

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  post: async (UserUnit: any) => {
    try {      
      const body = {
        ...UserUnit,
      };
      const { data } = await api.post<any>(`/UserCustomer/Add`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message:
            error?.response?.data?.message ??
            error?.response?.data?.errors?.[0]?.message,
        });

        return {
          severity,
          status,
          message: message ?? "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  postUnit: async (unitList: postUnitBody[]) => {
    try {
      const { data } = await api.post<any>(
        `/UserCustomerPlace/AddAll`,
        unitList
      );

      return {
        status: true,
        data: data,
      };
    } catch (error:any) {
      return  {
        status: false,
        data: error.response.data,
      }
     }
  },


  postUnitUnique: async (unitList: any) => {
    try {
      const { data } = await api.post<any>(
        `/UserCustomerPlace/Add`,
        unitList
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) { }
  },
};

interface IVinculeAttendant {
  attendantId: number;
  hearingId: string;
  device: string,
  ip: string,
  logPoliceUnitId: number,
  logUserId: number
}

interface ResponseTypeVincule {
  status: boolean;
  message: string;
  severity: string;
  data: null;
}

export const AttendandoViculeService = {
  vincule: async (body: IVinculeAttendant): Promise<ResponseTypeVincule> => {
    try {
      const { data } = await api.post("/HearingAttendant/Add", body);

      return {
        status: true,
        message: data.data.message,
        data: data.data,
        severity: "success",
      };
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Sucesso",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};

export const procedureCustomerAttendantService = {
  unbinding: async (procedureCustomerAttendantId: number) => {
    try {
      const { data } = await api.delete(
        `/HearingAttendant/Delete?id=${procedureCustomerAttendantId}`
      );
      await LogOperation.postLogOperation("HearingAttendant", 3, 3);
      return {
        success: true,
        body: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data.message,
        });

        return {
          success: false,
          severity,
          status,
          body: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  getAttendantByProcedureRoleType: async ({
    hearingId,
  }: {
    hearingId: number;
  }) => {
    try {
      const { data } = await api.get(
        `/HearingAttendant/GetAllAttendantInfoByHearingId?hearingId=${hearingId}`
      );
      return {
        success: true,
        body: data.data,
      };
    } catch (error) {
      return {
        success: false,
        body: {},
      };
    }
  },

  getAllToBindByHearingId: async ({ hearingId }: { hearingId: number }) => {
    try {
      const { data } = await api.get(
        `/Attendant/GetAllToBindByHearingId?hearingId=${hearingId}`
      );
      return {
        status: true,
        data: data,
      };
    } catch (error) {
      return {
        status: false,
        data: {},
      };
    }
  },
};
