/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from "axios";
import api from "../api";
import { returnResponse } from "../utils";

export interface ChartItem {
  chart: string;
  description: string;
  total: string;
}

export interface ChartItemResponse {
  data: ChartItem[];
  errors: string[];
  message: string;
  ok: boolean;
}

export const dataAnalysisServices = {
  GetDashboardInformation: async (placeId: number, scheuduledType: number) => {
    try {
      const { data } = await api.get<ChartItemResponse>(
        `/DataAnalysis/GetDashboardInformation?placeId=${placeId}&scheuduledType=${scheuduledType}`
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  GetDashboardMoreInformation: async (
    placeId: number,
    schedule: number,
    description: string
  ) => {
    try {
      const { data } = await api.get(
        `DataAnalysis/GetHearingsByType?placeId=${placeId}&scheuduledType=${schedule}&description=${description}`
      );

      return {
        success: true,
        data: data.data,
      };
    } catch (error: any) {
      return {
        success: true,
        data: error.response.data.message,
      };
    }
  },

  GetAllForDashboardMaster: async (
    customerId: number
  ) => {
    try {
      const { data } = await api.get(
        `DataAnalysis/GetAllForDashboardMaster?customerId=${customerId}`
      );
      
      return {
        success: true,
        data: data.data,
      };
    } catch (error: any) {
      return {
        success: true,
        data: error.response.data.message,
      };
    }
  },
  
  GetUsedTranscriptionAmount: async (
    cnpj: string,
    month: string
  ) => {
    try {
      const { data } = await api.get(
        `/DataAnalysis/GetUsedTranscriptionAmount?cnpj=${cnpj}&month=${month}`
      );            
      return {
        success: true,
        data: data,
      };
    } catch (error: any) {
      return {
        success: false,
        data: error.response?.data?.message || 'Erro na requisição',
      };
    }
  },
};

