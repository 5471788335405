import { useEffect, useState } from "react";
import { DropdownGlobal } from "../../../../../components/DropdownGlobal";
import { InputGeneral } from "../../../../../components/Input";
import { InputAuth } from "@kentarepo/kcf-assets";
import { typesInput } from "../../interface";
import { ContainerContent } from "./styles";

interface StorageProviderCustomProps {
  description: string
  selectedStorageProviderList: any
  setEditedValue: (e: string) => void
  getNameIfIdMatches: any
  editedValue: string
  fieldError: boolean
  passwordType: typesInput
  setPasswordType: (e: typesInput) => void
}

const StorageProviderCustom = ({ description, selectedStorageProviderList, setEditedValue, getNameIfIdMatches, editedValue, fieldError, passwordType, setPasswordType }: StorageProviderCustomProps) => {

  const initialConfig = JSON.parse(editedValue);

  const [storageProvider, setStorageProvider] = useState<number>(initialConfig.StorageProvider);
  const [azureName, setAzureName] = useState<string>(initialConfig.StorageName);
  const [azureStringConnection, setAzureStringConnection] = useState<string>(initialConfig.StorageStringConnection);
  const [azureURL, setAzureURL] = useState<string>(initialConfig.AzureURL);
  const [fileSystemDomain, setFileSystemDomain] = useState<string>(initialConfig.FileSystemDomain);
  const [fileSystemUser, setFileSystemUser] = useState<string>(initialConfig.FileSystemUser);
  const [fileSystemPassword, setFileSystemPassword] = useState<string>(initialConfig.FileSystemPassword);
  const [fileSystemPath, setFileSystemPath] = useState<string>(initialConfig.FileSystemPath);

  const updateConfigString = () => {
    const configObject = {
      StorageProvider: storageProvider,
      StorageName: azureName,
      StorageStringConnection: azureStringConnection,
      AzureURL: azureURL,
      FileSystemDomain: fileSystemDomain,
      FileSystemUser: fileSystemUser,
      FileSystemPassword: fileSystemPassword,
      FileSystemPath: fileSystemPath,
    };
    return JSON.stringify(configObject);
  };

  useEffect(() => {
    const newConfigString = updateConfigString();
    setEditedValue(newConfigString)
  }, [storageProvider, azureName, azureStringConnection, azureURL, fileSystemDomain, fileSystemUser, fileSystemPassword, fileSystemPath]);


  return (
    <>
      <DropdownGlobal
        labelName={description}
        options={selectedStorageProviderList}
        error={fieldError}
        setSelected={(e: any) => setStorageProvider(e)}
        required={false}
        editDropdown={getNameIfIdMatches(selectedStorageProviderList, storageProvider)}
        defaultValue={getNameIfIdMatches(selectedStorageProviderList, storageProvider)}
        withoutMarginBottom
      />

      {storageProvider === 0 ? (
        <ContainerContent height={55}>
          <InputGeneral
            error={fieldError}
            label={"Nome do armazenamento"}
            value={azureName}
            onChange={(e) => setAzureName(e)}
            required={true}
            width={381}
            maxLength={499}
            mt="1.3"
          />
          <InputGeneral
            error={fieldError}
            label={"String de conexão do storage"}
            value={azureStringConnection}
            onChange={(e) => setAzureStringConnection(e)}
            required={true}
            width={381}
            maxLength={499}
            mt="1.3"
            id="name_attendant"
            type="text"
          />
          <InputGeneral
            error={fieldError}
            label={"URL do servidor do storage"}
            value={azureURL}
            onChange={(e) => setAzureURL(e)}
            required={true}
            width={381}
            maxLength={499}
            mt="1.3"
          />
        </ContainerContent>
      ) : (
        <ContainerContent height={70}>
          <InputGeneral
            error={fieldError}
            label={"Domínio"}
            value={fileSystemDomain}
            onChange={(e) => setFileSystemDomain(e)}
            required={true}
            width={381}
            maxLength={499}
            startFocus={true}
            mt="1.3"
          />
          <InputGeneral
            error={fieldError}
            label={"Usuário de autenticação do file system"}
            value={fileSystemUser}
            onChange={(e) => setFileSystemUser(e)}
            required={true}
            width={381}
            maxLength={499}
            startFocus={false}
            mt="1.3"
          />
          <InputAuth
            error={fieldError}
            label={"Senha de autenticação do file system"}
            value={fileSystemPassword}
            onChange={(e) => setFileSystemPassword(e)}
            required={true}
            width={381}
            setType={(type: typesInput) => setPasswordType(type)}
            icon={true}
            type={passwordType}
            maxLength={499}
            marginTop="1.3rem"
          />
          <InputGeneral
            error={fieldError}
            label={"Caminho do file system"}
            value={fileSystemPath}
            onChange={(e) => setFileSystemPath(e)}
            required={true}
            width={381}
            maxLength={499}
            startFocus={true}
          />
        </ContainerContent>
      )}
    </>
  )
}

export default StorageProviderCustom;