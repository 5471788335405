import styled from "styled-components";
import { IAuthLeftTemplate } from "../interface";
import { globalStyle, colors } from "@kentarepo/kcf-assets";

export const Container = styled.div<IAuthLeftTemplate>`
  width: 100%;
  height: 100vh;
  background: url(${(params) => params.backgroundImage}) no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 870px) {
    display: none;    
  }

`;

export const ContainerTextCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  strong {
    font-size: 36px;
    color: ${colors.neutral_white};
    text-align: center;
    font-family: ${globalStyle.fontFamily};
    font-weight: 700;
  }
  span {
    font-size: 28px;
    color: ${colors.neutral_white};
    text-align: center;
    font-family: ${globalStyle.fontFamily};
    font-weight: 700;
  }
`;

export const TopBar = styled.div`
  width: 136px;
  height: 4px;
  background: ${colors.primary_default};
  margin-bottom: 24px;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border: 3px solid ${colors.primary_default};
  background: none;
  border-radius: 50%;
  margin-right: 16px;
`;