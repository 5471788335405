import api from "../api";
import { ICreateTemplate, ICustomerTemplate, IKeywords, IKeywordsHearingFile } from "./interface";

export const customerTemplateService = {
  getAllByCustomerId: async ({
    customerId,
    active,
    type,
  }: ICustomerTemplate) => {
    try {
      const isActive = active !== null ? `&active=${active}` : ""
      const isType = type ? `&type=${type}` : ""
      const { data } = await api.get(
        `/CustomerTemplate/GetAllByCustomerId?customerId=${customerId}${isActive}${isType}`
      );
      return {
        success: true,
        message: "",
        body: data.data,
      };
    } catch (error: any) {
      return {
        success: false,
        message: error.response?.data?.message,
        body: null,
      };
    }
  },
  createTemplate: async (params: ICreateTemplate) => {
    try {
      const { data } = await api.post("/CustomerTemplate/Add", params);

      return {
        success: true,
        body: data,
        message: data.message,
      };
    } catch (error: any) {
      return {
        success: false,
        body: {},
        message:
          error?.response?.data.message ||
          error?.response?.data?.errors[0]?.message,
      };
    }
  },
  updateTemplate: async (param: ICreateTemplate) => {
    try {
      const { data } = await api.put("/CustomerTemplate/Update", param);

      return {
        success: true,
        body: data.data,
        message: data?.message,
      };
    } catch (error: any) {
      return {
        success: false,
        body: {},
        message: error?.response?.data?.message,
      };
    }
  },
  SetKeywords: async ({ hearingId, customerTemplateId }: IKeywords) => {
    try {
      const { data } = await api.get(
        `/CustomerTemplate/SetKeywords?hearingId=${hearingId}&customerTemplateId=${customerTemplateId}`
      );

      return {
        success: true,
        body: data.data,
        message: "",
      };
    } catch (error: any) {
      return {
        success: false,
        body: {},
        message: error?.response?.data?.message,
      };
    }
  },
  SetKeywordsByHearingFile: async ({ hearingFileId, customerTemplateId }: IKeywordsHearingFile) => {
    try {
      const { data } = await api.get(
        `/CustomerTemplate/SetKeywords?hearingFileId=${hearingFileId}&customerTemplateId=${customerTemplateId}`
      );

      console.log("data SetKeywordsByHearingFile", data)

      return {
        success: true,
        body: data.data,
        message: "",
      };
    } catch (error: any) {
      return {
        success: false,
        body: {},
        message: error?.response?.data?.message,
      };
    }
  },
};
