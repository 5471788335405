import React from "react";
import { Container } from "./styles";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { LeftTemplate } from "../../../features/auth/LeftTemplate";
import { Form } from "../../../features/forms/Form";

export const ImportCertificate: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <LeftTemplate backgroundImage="default" />
      <Form
        variant="certificate"
        navigate={navigate}
        api={api}
      />
    </Container>
  );
};
