import {
  systemNotificationService,
  ISystemNotificationrBody,
} from "@kentarepo/kcf-services";
import { Axios } from "axios";

export const getAllNotifications = async (
  api: Axios,
  userId: number
): Promise<ISystemNotificationrBody[]> => {
  const notificationsService = systemNotificationService(api);
  const list = await notificationsService.getAllByUser(userId);

  if (list.status === 200) {
    return list.data.data;
  } else {
    return [];
  }
};

export const readNotification = async (api: Axios, notificationId: number) => {
  const notificationsService = systemNotificationService(api);
  return await notificationsService.setReaded(notificationId);
};

export const readAllNotifications = async (
  api: Axios,
  list: Array<ISystemNotificationrBody>
) => {
  const notificationsService = systemNotificationService(api);
  return await notificationsService.setReadedAll(list);
};
