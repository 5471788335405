import React from "react";
import { IHearingFilter } from "./interface";
import { CreateRecordConstants } from "./contants";
import { Autocomplete, TextField } from "@mui/material";
import {
  BaseLabel,
  DateInput,
  DrawerDefault,
  GeneralInput,
  Switch,
} from "@kentarepo/kcf-assets";

import {
  ContainerAllFilter,
  ContainerFormFilter,
  ContainerFormFilterSwitch,
  ContainerTwoComponents,
  SimpleDropDownDiv,
} from "./styles";

export const HearingFilter: React.FC<IHearingFilter> = ({
  title,
  hearingNumber,
  setHearingNumber,
  startDate,
  setStartDate,
  finishDate,
  setFinishDate,
  valueOptionDropDown,
  setValueOptionDropDown,
  active,
  setActive,
  showDrawer,
  setShowDrawer,
  anchor = "right",
  onClickSearch,
  onClickClear,
  options,
  clearDates,
  startDateError,
  finishDateError,
  onClose,  
}) => {
  const [inputValue, setInputValue] = React.useState<string>("");

  return (
    <>
      <DrawerDefault
        onClose={onClose}
        onClickSearch={onClickSearch}
        onClickClear={onClickClear}
        title={title}
        haveFooter={false}
        openDrawer={showDrawer}
        setOpenDrawer={setShowDrawer}
        anchor={anchor}
        haveFooterFilter={true}
      >
        <ContainerAllFilter>
          <ContainerFormFilter>
            <GeneralInput
              label={CreateRecordConstants.HEARING_NUMBER}
              name="hearingNumberFilter"
              onChange={setHearingNumber}
              value={hearingNumber}
              marginRight="0px"
              maxLength={90}
              required={false}
            />
            <ContainerTwoComponents>
              <DateInput
                label={CreateRecordConstants.START_DATE}
                setValue={setStartDate}
                value={startDate}
                required={false}
                id="startDateFilterId"
                clear={clearDates}
                error={startDateError}
              />
              <DateInput
                label={CreateRecordConstants.FINISH_DATE}
                setValue={setFinishDate}
                value={finishDate}
                required={false}
                id="finishDateFilterId"
                clear={clearDates}
                error={finishDateError}
              />
            </ContainerTwoComponents>
            <SimpleDropDownDiv>
              <BaseLabel
                children={CreateRecordConstants.STATUS}
                marginLeft="0px"
                color="#666666"
              />
              <Autocomplete
                value={valueOptionDropDown}
                onChange={(_event: any, newValue: string | null) => {
                  setValueOptionDropDown(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(_event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={options}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    variant="outlined"
                    placeholder="Selecione"
                  />
                )}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px",
                    "& fieldset": {
                      borderWidth: "2px",
                      borderColor: "#D1DBE3 !important",
                    },
                    "&:hover fieldset": {
                      borderColor: "#CBCBCB !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1D9BF0 !important",
                    },
                  },
                }}
              />
            </SimpleDropDownDiv>
            <ContainerFormFilterSwitch>
              <Switch
                onChange={() => setActive(!active)}
                checked={active}
                label={CreateRecordConstants.ACTIVE}
                id="switchFilter"
              />
            </ContainerFormFilterSwitch>
          </ContainerFormFilter>
        </ContainerAllFilter>
      </DrawerDefault>
    </>
  );
};
