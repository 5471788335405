import { Box } from "@mui/material";
import styled from "styled-components";

export const ContainerCheckbox = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: 100%;
height: 85%;
padding: 0px 40px;
`;

export const ContainerAdmin = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
`;