import { Box } from "@mui/material";
import styled from "styled-components";

interface IBodyList {
  height?: string;
}

export const Body = styled(Box)`
  padding-left: 24px;
  padding-right: 44px;
`;

export const BodyFilter = styled(Box)`
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
  align-items: center;
  gap: 40px;
  
`;


export const BodyList = styled(Box)<IBodyList>`
  height: 100%;
  overflow-y: auto;

  @media screen and (max-height: 1440px) {
    //background-color: blue;
    height: ${(props) => (props.height ? props.height : "75vh")} !important;
  }
  @media screen and (max-height: 1080px) {
    //background-color: red;
    height: ${(props) => (props.height ? props.height : "62vh")} !important;
  }
  @media screen and (max-height: 768px) {
    //background-color: orange;
    height: ${(props) => (props.height ? props.height : "55vh")} !important;
  }
  @media screen and (max-height: 720px) {
    //background-color: green;
    height: ${(props) => (props.height ? props.height : "42vh")} !important;
  }
  @media screen and (max-height: 480px) {
    //background-color: yellow;
    height: ${(props) => (props.height ? props.height : "28vh")} !important;    
  }
`;

export const GeneralInputDiv = styled(Box)`
  width: 400px;
  margin-top: 8px;
`;

export const SimpleDropDownDiv = styled(Box)`
  width: 136px;
  padding-top: 19px;
`;

