/* eslint-disable @typescript-eslint/no-explicit-any */
import { AttendantCard, ScheduleCard } from "@kentarepo/kcf-assets";
import { hearingTokensService } from "../../services/hearingTokens";
import { ToastMessage } from "../../hooks/toast";

export const validTitle = (title: string, maxSize: number = 8) => {
  return title.substring(0, maxSize) + (title.length > maxSize ? "..." : "");
};

interface IListItemSchedule {
  listSchadule: Array<any>;
  navigate: any;
  navigateTo: string;
  disabledDelete: boolean;
  disabledEdit: boolean;
  disabledRecording: boolean;
  onClickDelete: (e: any) => unknown;
  onClickEdit: (e: any) => unknown;
  onClickCopy: (e: any) => unknown;
  onClickRecoding?: (audiencias: any) => unknown;
}

export const listItemSchadule = ({
  listSchadule,
  navigate,
  navigateTo,
  disabledDelete,
  disabledEdit,
  disabledRecording,
  onClickRecoding,
  onClickDelete,
  onClickEdit,
  onClickCopy,
}: IListItemSchedule) =>
  listSchadule.map((item: any, index) =>
    item.expectedDate !== null ? (
      <ScheduleCard
        id={`record_schedule_${index}`}
        key={item?.id}
        title={`Agendado ${index + 1}`}
        subTitle={"custodia"}
        day={item.expectedDate}
        time={item.expectedDate}
        onClickDelete={() => onClickDelete(item)}
        onClickEdit={() => onClickEdit(item)}
        onClickRecord={
          onClickRecoding
            ? () => onClickRecoding(item)
            : () => navigate(`${navigateTo}/${item?.id}`)
        }
        disabledDelete={!disabledDelete}
        disabledEdit={!disabledEdit}
        disabledRecord={!disabledRecording}
        copyTooltip="Copiar link"
        onClickCopy={() => onClickCopy(item?.id)}
        squareWidth={100}
        showText={false}
      />
    ) : null
  );

export const listAttendants = (
  listAttendant: Array<any>,
  onClickDetach: (id: number) => unknown,
  permissionDetach: boolean
) =>
  listAttendant.map((item, index) => (
    <AttendantCard
      id={`card_attedants_${index}`}
      key={item?.id}
      title={item?.name}
      subTitle={item.role}
      icon={item.role.includes("Advogad") ? "avatar" : "record"}
      setSelectAttendant={
        !permissionDetach ? () => {} : () => onClickDetach(item?.id)
      }
      item={item}
    />
  ));

  export const handleCopyMeetingLink = async (e: number, addToast: (messages: Omit<ToastMessage, "id">) => void) => {
    try {
      const { body, success } =
        await hearingTokensService.getOneByHearingFileId(e);
 
      if (success && body && body?.externalLink) {
        await navigator.clipboard.writeText(body.externalLink);
       
        addToast({
          type: "success",
          title: "Link copiado",
          description: "O link foi copiado com sucesso",
        });
      } else {
        addToast({
          type: "error",
          title: "Copiar link",
          description: "Não foi possivel copiar o link",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  