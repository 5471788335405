import { BaseDrawer, DrawerFormRight } from '@kentarepo/kcf-assets';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;


export const ContainerTwoComponents = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
`

export const BaseDrawerModified = styled(BaseDrawer as any)`
  z-index: -1;
`

export const DrawerFormRightModified = styled(DrawerFormRight as any)`
  z-index: -1;
`