/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from "axios";
import { returnResponse } from "../utils";
import api from "../api";

export const HearingRoleService = {

  getById: async (id: number) => {
    try {
      const { data } = await api.get<any>(`/hearing/Get?id=${id}`);

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  post: async (authToken: string | null, attendantRole: any) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível criar a sala de video sem o token de autenticação.",
      };
    }
    try {
      const body = {
        ...attendantRole,
      };
      const { data } = await api.post<any>(`/Hearing/Add`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  getAll: async (customerId: number, status?: 0 | 1 | null) => {
    try {
      const { data } = await api.get<any>(
        `/HearingType/GetAllByCustomerId?customerId=${customerId}${
          status === 0 || status === 1 ? `&active=${status}` : ""
        }`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message:
            error?.response?.data.message ||
            error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

}