import { ClickLocationText, ContainerDivDropzone, ContainerLogo, DropFileContainer, DropFileInput, DropLocationText } from './styles';
import { DropdownGlobal } from '../../../../../components/DropdownGlobal';
import Dropzone from "react-dropzone";
import { listYesOrNoAux } from '../../constants';

interface LogoCustomProps {
  setEditedValue: (e: any) => void
  setCustomerLogo:any
  editedValue: string
  onChangeFiles: any
  customerLogo: any
  fieldError:boolean
  selectedYesOrNoName:any
}

const LogoCustom = ({ setEditedValue,setCustomerLogo, editedValue, onChangeFiles, customerLogo,selectedYesOrNoName,fieldError }: LogoCustomProps) => {
  return (
    <ContainerLogo>
      <DropdownGlobal
        labelName={"Adicionar logo"}
        options={listYesOrNoAux}
        error={fieldError}
        setSelected={(e: any) => {
          setEditedValue(String(e));
          e === "Não" && setCustomerLogo(null);
        }}
        required={false}
        editDropdown={selectedYesOrNoName}
        defaultValue={selectedYesOrNoName}
      />
      {editedValue !== "0" && editedValue !== null && (
        <DropFileContainer error={false}>
          <Dropzone
            accept={{ "image/*": [".png", ".svg", ".jpeg", ".jpg"] }}
            onDrop={(acceptedFiles) => {
              onChangeFiles(acceptedFiles[0]);
            }}
          >
            {({
              getRootProps,
              getInputProps,
              isDragActive,
            }) => (
              <ContainerDivDropzone
                {...getRootProps()}
                isDragActive={isDragActive}
                error={false}
              >
                {isDragActive ? (
                  <ClickLocationText>Solte aqui</ClickLocationText>
                ) : (
                  <>
                    {customerLogo && (
                      <img
                        src={customerLogo}
                        style={{
                          marginBottom: 10,
                          height: 50,
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                    )}
                    <DropLocationText>
                      Solte o arquivo aqui ou
                    </DropLocationText>
                    <ClickLocationText>
                      clique para localizar
                    </ClickLocationText>
                  </>
                )}
                <DropFileInput {...getInputProps()} />
              </ContainerDivDropzone>
            )}
          </Dropzone>
        </DropFileContainer>
      )}
    </ContainerLogo>
  )
}

export default LogoCustom;