/* eslint-disable @typescript-eslint/no-explicit-any */
import { PermissionTypes } from "./actios.types";

export interface SetPermissionAction{
  type: PermissionTypes.SET_PERMISSION,
  payload: any[]
}

export const setPermissions = (permissions: any[]): SetPermissionAction => ({
    type: PermissionTypes.SET_PERMISSION,
    payload: permissions
});


