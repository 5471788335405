import { DropdownGlobal, IDropDown } from '../../../../../components/DropdownGlobal';

interface Item {
  id: number;
  name: string;
}

interface WatermarkProviderProps {
  listWaterMarkProvider: Array<IDropDown>;
  fieldError: boolean;
  setEditedValue: (e: string) => void;
  getNameIfIdMatches: (array: Item[], editedValue: string) => string;
  editedValue: string;
}

const WatermarkProvider = ({
  listWaterMarkProvider,
  fieldError,
  setEditedValue,
  getNameIfIdMatches,
  editedValue,

}: WatermarkProviderProps) => {
  return (
    <>
      <DropdownGlobal
        labelName={"Provedor de marca d'água"}
        options={listWaterMarkProvider}
        error={fieldError}
        setSelected={setEditedValue}
        required={false}
        editDropdown={getNameIfIdMatches(listWaterMarkProvider, editedValue)}
        defaultValue={getNameIfIdMatches(listWaterMarkProvider, editedValue)}
      />
    </>
  );
};

export default WatermarkProvider;
