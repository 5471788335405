import styled, { css } from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

interface Props {
  active?: boolean;
  disabled?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const Wrapper = styled.div<Props>`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;

  ${(props) =>
    props.disabled && props.disabled === true
      ? css`
          border: 1px solid ${stylesGlobalDefault.grayLight};
          border-radius: 4px;
          background: ${stylesGlobalDefault.grayLight};
          cursor: default;
          pointer-events: none;
        `
      : css`
          border: 1px solid
            ${props.active
              ? stylesGlobalDefault.blue
              : stylesGlobalDefault.grayLight};
          border-radius: 4px;
          background: ${props.active
            ? stylesGlobalDefault.blue
            : stylesGlobalDefault.white};
          cursor: pointer;
          pointer-events: initial;
        `}
`;
export const Title = styled.text<Props>`
  ${(props) =>
    props.disabled && props.disabled === true
      ? css`
          color: ${stylesGlobalDefault.grayLight};
        `
      : css`
          color: ${stylesGlobalDefault.black};
        `}

  margin-left: 12px;
  font-weight: 400;
  font-size: 14px;
`;
