/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from "react";
import { IManagementListsAdmin } from "./interface";
import { Body, BodyFilter, GeneralInputDiv, SimpleDropDownDiv } from "./styles";
import { handleStatus } from "./constants";
import { ReactPaginateKenta } from "../ReactPaginate";
import { LoadingContainer } from "../../auth/styles";
import { CardAdminItem } from "./CardAdminItem";
import {
  GeneralInput,
  LoadingComponent,
  SimpleDropDown,
} from "@kentarepo/kcf-assets";

export const ManagementListsAdmin: React.FC<IManagementListsAdmin> = ({
  list,
  permitions,
  updateList,
  loading,
  setClickToEdit,
  setObjectRetorned,
  setQuantity,
  isAudience,
}) => {
  const [listToShow, setListToShow] = useState<Array<any>>(list || []);
  const [searchText, setSearchText] = useState<string>("");
  const [valueOptionSimpleDropDown, setValueOptionSimpleDropDown] =
    useState<number>(1);

  const [itemClicked, setItemClicked] = useState<any>(null);

  const options = [
    {
      value: 1,
      active: true,
      label: "Ativo",
      datacy: "ativo",
    },
    {
      value: 2,
      active: false,
      label: "Inativo",
      datacy: "inativo",
    },
    {
      value: 3,
      active: null,
      label: "Todos",
      datacy: "todos",
    },
  ];

  useEffect(() => {
    search();
  }, [list, updateList, valueOptionSimpleDropDown, searchText]);


  useEffect(() => {
    if (itemClicked !== null) {
      setObjectRetorned(itemClicked);
      const plus = Math.floor(Math.random() * (100 - 1 + 1)) + 1;
      setClickToEdit(plus);
    }
    setItemClicked(null);
  }, [itemClicked]);

  const handleQuantity = (listFiltered: Array<any>) => {
    if (listFiltered !== undefined && Array.isArray(listFiltered)) {
      setQuantity(listFiltered.length);
    } else {
      setQuantity(0);
    }
  };

  const getIsActiveByDropdownValue = () => {
    return options.find((o) => o.value === valueOptionSimpleDropDown);
  };

  const search = () => {
    if (getIsActiveByDropdownValue()?.active === null && !searchText) {
      handleQuantity(list || []);
      return setListToShow(list);
    }

    if (getIsActiveByDropdownValue()?.active === null && searchText) {
      const listFiltered = list.filter((i) =>
        i?.name?.toLowerCase().includes(searchText.toLowerCase())
      );
      handleQuantity(listFiltered || []);
      return setListToShow(listFiltered);
    }

    if (
      (getIsActiveByDropdownValue()?.active ||
        getIsActiveByDropdownValue()?.active === false) &&
      !searchText
    ) {
      const listFiltered = list.filter(
        (i) => i.active === getIsActiveByDropdownValue()?.active
      );
      handleQuantity(listFiltered || []);
      return setListToShow(listFiltered);
    }

    if (
      (getIsActiveByDropdownValue()?.active ||
        getIsActiveByDropdownValue()?.active === false) &&
      searchText
    ) {
      const listFiltered = list.filter(
        (i) =>
          i.active === getIsActiveByDropdownValue()?.active &&
          i?.name?.toLowerCase().includes(searchText.toLowerCase())
      );
      handleQuantity(listFiltered || []);
      return setListToShow(listFiltered);
    }

    handleStatus(valueOptionSimpleDropDown);
    setListToShow(list);
    handleQuantity(list || []);
  }

  const listComponent =
    listToShow &&
    listToShow?.map((item: any, index: number) => (
      <CardAdminItem
        key={index}
        id={item?.id}
        data-cy={item?.name}
        name={item?.name}
        active={item.active}
        onClickEditAction={
          permitions.toEdit
            ? () => setItemClicked(item)
            : () => {
                return null;
              }
        }
        disable={permitions.toEdit ? false : true}
        showStatus={true}
      />
    ));

  return (
    <>
      <Body>
        {permitions.toRead ? (
          <Fragment>
            <BodyFilter>
              <GeneralInputDiv>
                <GeneralInput
                  id="pesquisa"
                  data-cy="pesquisa"
                  label="Buscar por"
                  name="buscaPor"
                  onChange={(e: any) => setSearchText(e)}
                  value={searchText}
                  height="52px"
                />
              </GeneralInputDiv>
              <SimpleDropDownDiv>
                <SimpleDropDown
                  label="Situação"
                  onChange={(e) =>
                    setValueOptionSimpleDropDown(Number(e?.target?.value))
                  }
                  options={options}
                  required={false}
                  height="52px"
                />
              </SimpleDropDownDiv>
            </BodyFilter>
            {loading ? (
              <LoadingContainer>
                <LoadingComponent loading={loading} />
              </LoadingContainer>
            ) : (
              <ReactPaginateKenta
                list={listComponent}
                title={isAudience}
                onChangePage={() => {}}
              />
            )}
          </Fragment>
        ) : null}
      </Body>
    </>
  );
};
