import React from "react";
import api from "../../../services/api";
import { Administration } from "../Administration";
import { ImportCertificate } from "../../../features/ImportCertificate";

import {
  ContainerAdmin,
  ContainerItemAdmin,
  ContainerLeftMenu,
} from "../Administration/styles";
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { usePermissions } from "../../../hooks/permission";
import { permitionsCrud } from "../../../features/cruds";

export const CustomerCertificate: React.FC = () => {

  const { read, create, edit } = usePermissions(FORMS_ADMIN.CERTIFICATE);

  const permitions: permitionsCrud = {
    toCreate: create,
    toEdit: edit,
    toRead: read,
    inactivate: false,
  }

  return (
    <>
      <ContainerAdmin>
        <ContainerLeftMenu>
          <Administration />
        </ContainerLeftMenu>
        <ContainerItemAdmin>
          <ImportCertificate
            api={api}
            projectName="Kenta Audiências"
            title="Certificado"
            permissions={permitions}
          />
        </ContainerItemAdmin>
      </ContainerAdmin>
    </>
  );
};
