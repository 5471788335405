import { useEffect, useState } from "react";
import { LogOperation } from "../../../utils/entities/logOperation";
import { storageKentaService } from "../../../services/StorageKenta";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { LoadSpinner } from "../../LoadSpinner";
import {
  ButtonClickCard,
  DrawerDefault,
  SearchEmpty,
} from "@kentarepo/kcf-assets";
import { usePermissions } from "../../../hooks/permission";
import { FORMS_HEARING } from "../../../utils/entities/permission";
import { useToast } from "../../../hooks/toast";

interface Props {
  isOpenDownloadsModal: boolean;
  setIsOpenDownloadsModal: (isOpen: boolean) => void;
}

export function DownloadAppModal({
  isOpenDownloadsModal,
  setIsOpenDownloadsModal,
}: Props) {
  const [listOfDownloads, setListOfDownloads] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const { export: exportFiles } = usePermissions(FORMS_HEARING.HEARING_FILE);

  async function findFiles() {
    if (exportFiles) {
      dispatch(showLoading());
      try {
        const { success, body } = await storageKentaService.GetAllBlobs();

        if (success && body?.length > 0) {
          setListOfDownloads(body);
        } else {
          addToast({
            type: "warning",
            title: "Nenhum arquivo encontrado",
            description: "Ocorreu um problema ao buscar os arquivos. Tente novamente mais tarde.",
          });
        }
      } catch (error) {
        addToast({
          type: "error",
          title: "Erro ao buscar os arquivos",
          description: "Verifique sua conexão e tente novamente mais tarde.",
        });
      } finally {
        dispatch(hideLoading());
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (isOpenDownloadsModal) findFiles();
  }, [isOpenDownloadsModal]);

  const tamanho = (bytes: number) => {
    const total = (bytes / 1024).toFixed(2);
    if (Number(total) > 1000) {
      return (bytes / 1048576).toFixed(2) + " MB ";
    }
    return total + " KB";
  };

  const clicouEmBaixar = () => {
    LogOperation.postLogOperation("HearingFile", 6, 0);
  };

  return (
    <>
      {loading ? (
        <LoadSpinner loading={loading} />
      ) : (
        <DrawerDefault
          openDrawer={isOpenDownloadsModal}
          setOpenDrawer={() => {}}
          onClose={() => setIsOpenDownloadsModal(!isOpenDownloadsModal)}
          title={"Aplicativos e documentações"}
          haveFooter={false}
          paddingleft={28}
          paddingright={32}
          children={
            <>
              {exportFiles ? (
                listOfDownloads && listOfDownloads.length > 0 ? (
                  listOfDownloads.map((item: any) => {
                    return (
                      <ButtonClickCard
                        title={item?.name}
                        subTitle={tamanho(item.filesize)}
                        typeButton={"download"}
                        typeIcon={"file"}
                        onClick={() => {
                          clicouEmBaixar();
                          window.open(item.url);
                        }}
                      />
                    );
                  })
                ) : (
                  <SearchEmpty
                    typeIcon="file"
                    textMessage={"Nenhum arquivo para download"}
                  />
                )
              ) : (
                <SearchEmpty
                  typeIcon="file"
                  textMessage={"Sem permissão para seu perfil"}
                />
              )}
            </>
          }
        />
      )}
    </>
  );
}
