import { Box } from "@mui/material";
import styled from "styled-components";

export const ContainerAdmin = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ContainerLeftMenu = styled(Box)`
  width: 400px;
`;

export const ContainerItemAdmin = styled(Box)`
  width: 100%;
`;
