import styled from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";


export const Container = styled.div`
 display: block; 
 flex-direction: column;
 padding: 30px;
 background-color: ${stylesGlobalDefault.backgroundListItemColor};
`;

export const HeaderBox = styled.div`
display: flex;
background-color: ${stylesGlobalDefault.white};
height: 135px;
border-radius: 10px ;
margin-bottom: 30px;
@media (max-width: 1200px) {
  flex-wrap: wrap;
  margin-bottom: 220px;
  width: 100%;
  background-color: ${stylesGlobalDefault.white};
}
`;

export const Box = styled.div`
 display: flex;
 align-items: center;
 width: 100%;
 @media (max-width: 1200px) {
  width: 100%;
  margin-bottom: 10px;
  background-color: ${stylesGlobalDefault.white};
  border-radius: 10px ;
  }
`;

export const BoxData = styled.div`
display: flex;
flex-direction: column;
`;

export const Title = styled.div`
color: ${stylesGlobalDefault.titleMenuColor};
font-size: 20px;
font-weight: 900;
`;

export const TitleData = styled.div`
color: ${stylesGlobalDefault.orange};
font-size: 20px;
font-weight: 900;
`;



export const Icon = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-color: ${stylesGlobalDefault.backgroundListItemColor};
margin: 30px;
height: 60px;
width: 60px;
border: ${stylesGlobalDefault.inputsBorderFocusOff} 4px solid;
border-radius: 40px ;
`;

export const Divider = styled.div`
background-color: #D9D9D9;
height: 135px;
width: 2px;
`;

export const DividerCustom = styled.div`
background-color: #D9D9D9;
height: 135px;
width: 2px;
@media (max-width: 1200px) {
    display: none;
  }
`;

export const Graphs = styled.div`
display: flex;
justify-content: space-between;
flex-wrap: wrap;
width: 100%;
`;

export const Graphbox = styled.div`
background-color: ${stylesGlobalDefault.white};
border-radius: 10px ;
width: 60%;
@media (max-width: 1200px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const GraphPiebox = styled.div`
border-radius: 10px ;
width: 38%;
display: flex;
flex-wrap: wrap;
gap: 20px;

@media (max-width: 1200px) {
    width: 100%; 

  }
`;

export const PieChartBox = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
background-color: ${stylesGlobalDefault.white};
border-radius: 10px;
width: 40vw;
@media (max-width: 1200px) {
    width: 100%; 

  }
`;
export const BarChartBox = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
background-color: ${stylesGlobalDefault.white};
padding: 20px;
border-radius: 10px;
width: 100%;
box-sizing: border-box;
`;

export const GraphHeader = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
color: ${stylesGlobalDefault.titleMenuColor};
background-color: ${stylesGlobalDefault.white};
padding-left: 30px;
padding-right: 30px;
font-size: 18px;
font-weight: 700;
height: 70px;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
border-bottom: ${stylesGlobalDefault.grayLight} 2px solid;
width: 100%;
box-sizing: border-box;
`;

export const DateFilter = styled.div`
display: none ;
gap: 5px;

`;
export const DateCells = styled.div`
display: flex;
align-items: center;
justify-content: center;
background-color: #ECF0F3;
font-size: large;
font-weight: 500;
width: 100px;
height: 50px;
cursor: pointer;
`;

export const PieGraphHeader = styled.div`
padding-top: 40px;
padding-bottom: 40px;
`;

export const GraphLegends = styled.div`
width: 59%;
margin: 10px;
font-size: 14px;
color: ${stylesGlobalDefault.titleMenuColor};
@media (max-width: 1200px) {
    display: none;
  }
`;

export const OnGoing = styled.div`
width: 100%;
padding-bottom: 60px;
border-radius: 10px;
background-color: ${stylesGlobalDefault.white};
@media (max-width: 1200px) {
  margin-top: 20px;
  }
`;
export const Processes = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
border-bottom: ${stylesGlobalDefault.inputsBorderFocusOff} 2px solid;
`;
export const ProcessesLabel = styled.div`
display: flex;
align-items: center;
color: ${stylesGlobalDefault.titleMenuColor};
font-size: 16px;
`;

export const SmallIcon = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-color: ${stylesGlobalDefault.backgroundListItemColor};
margin: 12px;
height: 40px;
width: 40px;
border: ${stylesGlobalDefault.inputsBorderFocusOff} 3px solid;
border-radius: 40px ;
`;

export const ProcessData = styled.div`
display: flex;
align-items: center;
`;