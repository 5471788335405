import { useEffect, useState } from 'react';
import { DropdownGlobal, IDropDown } from '../../../../../components/DropdownGlobal';
import { CheckBoxGlobal } from '../../../../../components/CheckBoxGlobal';
import { useSelector } from 'react-redux';


interface Item {
  id: number;
  name: string;
}
interface TranscriptionProviderProps {
  editConfig: Array<IDropDown>
  fieldError: boolean
  setEditedValue: (e:string)=>void
  getNameIfIdMatches:  (array: Item[], editedValue: string) => string;
  editedValue: string
  withIndexing: boolean
  setWithIndexing: (e:boolean)=>void
}

const TranscriptionProvider = ({
  editConfig,
  fieldError,
  setEditedValue,
  getNameIfIdMatches,
  editedValue,
  withIndexing,
  setWithIndexing,
}: TranscriptionProviderProps) => {
  const [showCheckbox, setShowCheckbox] = useState(false)
  const { globalConfigurations } = useSelector(
    (state: any) => state.configurations
  );

  const TranscriptionTimeIndex = globalConfigurations.find((item: any) => {
    return item.name === "TranscriptionTimeIndex"
  })

  const initialCheck = () => {
    if (!TranscriptionTimeIndex || TranscriptionTimeIndex.value === '0') {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    if (getNameIfIdMatches(editConfig, editedValue) === 'OpenAI') {
      setShowCheckbox(true)
    } else {
      setShowCheckbox(false)
      setWithIndexing(false)
    }
  }, [editedValue])


  return (
    <>
      <DropdownGlobal
        labelName={"Provedor de transcrição"}
        options={editConfig}
        error={fieldError}
        setSelected={setEditedValue}
        required={false}
        editDropdown={getNameIfIdMatches(editConfig, editedValue)}
        defaultValue={getNameIfIdMatches(editConfig, editedValue)}
      />
      {showCheckbox &&
        <CheckBoxGlobal
          checked={initialCheck()}
          id={1}
          label={'Transcrição com indexação'}
          onChecked={() => setWithIndexing(!withIndexing)}
        />
      }
    </>
  )
}

export default TranscriptionProvider;