import styled, { keyframes } from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

interface IProps {
  type: "success" | "warning" | "error";
}

const color: { [key: string]: string } = {
  success: stylesGlobalDefault.activedItem,
  warning: "#F0CA68",
  error: "#EB5861",
};

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(125%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div<IProps>`
  width: 300px;
  display: flex;
  justify-content: flex-end;
  padding: 4px;
  border-radius: 8px;
  background: ${(props: any) => color[props.type]};
  margin-bottom: 8px;
  animation: ${appearFromLeft} 0.5s ease-out;
  -webkit-animation: ${appearFromLeft} 0.5s ease-out;
  animation-fill-mode: forwards;
  will-change: transform, opacity;
  z-index: 9999;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 248px;
  border-radius: 0 8px 8px 0;
  background: ${stylesGlobalDefault.white};
  padding: 24px;

  span {
    font-family: ${stylesGlobalDefault.fontFamily};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-top: 14px;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  strong {
    font-family: ${stylesGlobalDefault.fontFamily};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }

  img {
    cursor: pointer;
    padding-left: 1px;
  }
`;