/* eslint-disable @typescript-eslint/no-explicit-any */
// import { PageHeader, SearchEmpty } from "@kentarepo/kcf-assets";
import React from "react";
import { AdminDashboard } from "../../../features/AdminDashboard";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../themes";
import { useSelector } from "react-redux";
import { PageHeader } from "@kentarepo/kcf-assets";
import { MasterDashboard } from "../../../features/MasterDashboard";

export const Dashboard: React.FC = () => {
  const customization = useSelector((state: any) => state.customization);
  const { profileName } = useSelector((state: any) => state.profile);

  return profileName === "Master" ? (
    <>
      <PageHeader label="Dashboard" background="secondary" />
      <MasterDashboard />
    </>
  ) : (
    <ThemeProvider theme={theme(customization)}>
      <AdminDashboard isFull />
    </ThemeProvider>
  );
};
