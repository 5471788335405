/* eslint-disable @typescript-eslint/no-explicit-any */
import { DragAndDrop } from "@kentarepo/kcf-assets";
import { InputGeneral } from "../../components/Input";
import { Container, ContainerBody, ContainerInputs, ContainerLoading, TextLoading } from "./style";
import { useEffect } from "react";
import UploadFileInBlock from "../../services/uploadFileInBlock";
import { useToast } from "../../hooks/toast";
import { useUploadingFile } from "./context";

interface ValidationProps {
  value: string;
  fileError: boolean;
  formatFileSize: (size: number) => string;
  file: any;
  setFile: (e: any) => void;
  editItem: null | any;
  setValidateVideo: (isValid: boolean) => void;
  uploadingFile: boolean,
  setUploadingFile: (e: boolean) => void
}

const ValidationModal = ({ value, fileError, formatFileSize, file, setFile, editItem, setValidateVideo, uploadingFile, setUploadingFile }: ValidationProps) => {
  const { addToast } = useToast();
  const { loadingUpload, setLoadingUpload } = useUploadingFile();

  const handleFileChange = (files: File[]) => {
    if (files.length > 0) {
      setFile(files[0]);
    }
  };

  useEffect(() => {

    if (file && !uploadingFile) {
      setUploadingFile(true);
      setLoadingUpload(true);
      UploadFileInBlock({ file, endpoint: 'UploadLargeFile' }).then((response) => {
        if (response?.ok) {
          addToast({
            type: "success",
            title: "upload vídeo",
            description: response.message,
          });
          setValidateVideo(false);
        } else {
          addToast({
            type: "error",
            title: "uploads",
            description: response.message,
          });
        }
        setLoadingUpload(false);
        setUploadingFile(false);
      });
    }
  }, [file]);

  useEffect(() => {
    if (!file) {
      setValidateVideo(true);
    }
  }, [file]);

  return (
    <Container>
      <ContainerBody>
        <ContainerInputs>
          <InputGeneral
            value={value}
            maxLength={100}
            onChange={() => { }}
            type="text"
            label="Número do Processo"
            disabled
          />
        </ContainerInputs>
        <DragAndDrop
          editItem={{ ...editItem, name: editItem?.filename }}
          fileError={fileError}
          formatFileSize={formatFileSize}
          isEdit={false}
          onDrop={(files) => handleFileChange(files)}
          file={file}
          setFile={setFile}
          typeSelected={{ "video/*": [".mp4"] }}
          type={{ "video/*": [".mp4"] }}
          multiple={false}
        />
      </ContainerBody>
      {loadingUpload &&
        <ContainerLoading>
          <TextLoading>Enviando arquivos...</TextLoading>
        </ContainerLoading>
      }
    </Container>
  );
}

export default ValidationModal;
