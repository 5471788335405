import styled from "styled-components";

export interface IStyledAuthScreen {
  marginTop?: string;
}


export const LeftFormContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: 380px;
    padding: 0px 140px;
  }
`;

export const Logo = styled.img`
  align-self: center;
  //margin-bottom: 16px;
  margin-bottom: 31px;
`;

export const LinkContainer = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  background: none;
  border: none;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const DivLogo = styled.div`
  align-self: center;
  margin-bottom: 31px;
  height: 40px;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const DivEmail = styled.div<IStyledAuthScreen>`
  margin-top: ${(props) => props.marginTop ?? "22px"};
  margin-bottom: 30px;
`;

