import React, { useState, Fragment, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import CameraIcon from "../../../assets/svg/camera-arquivos.svg";

import { BoxWebCanImage, ButtonTakePhoto, WebCanContainer } from "./styles";

import ModalSelectOrTakePicture from "../ModalSelectOrTakePicture";
import Avatar from "react-avatar";
import { IPhotoWebCam } from "./interface";
import { getCameras } from "../../../utils/camera";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

export const PhotoWebCam: React.FC<IPhotoWebCam> = ({
  img,
  setFileImg,
  setImg,
  userStorage,
  storageBaseUrl,
  containerNameUnit,
  userPicture,
  addToast,
}) => {
  const [showMyPicture, setShowMyPicture] = useState(true);
  const [showModalPicture, setShowModalPicture] = useState(false);
  const [preview, setPreview] = useState("");
  const [userItem, setUserItem] = useState<any>();
  const [cameras, setCameras] = useState<any[]>([]); // Lista de câmeras disponíveis
  const [currentCameraIndex, setCurrentCameraIndex] = useState(0); // Índice da câmera atual
  const videoRef = useRef<any>(null); // Referência para o elemento <video>


  const webcamRef = React.useRef<any>(null);

  useEffect(() => {
    if (userStorage) {
      const passedUser = JSON.parse(userStorage);
      setUserItem(passedUser);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const cameraList = await getCameras()

      setCameras(cameraList)
    })()
  }, [])

  useEffect(() => {
    if (cameras.length) { 
      switchCamera() 
    }
  }, [])


  const startStream = async (deviceId: any) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: deviceId }
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error('Erro ao iniciar o stream da câmera:', err);
    }
  };

  const switchCamera = () => {
    const nextCameraIndex = (currentCameraIndex + 1) % cameras.length;
    setCurrentCameraIndex(nextCameraIndex);
    const nextCameraId = cameras[nextCameraIndex].deviceId;
    startStream(nextCameraId);
  };


  const capture = React.useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();

    if (!webcamRef?.current?.state?.hasUserMedia) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Camera não encontrada",
      });
      return;
    }

    try {
      const canvas = document.createElement("canvas");
      const image = new Image();

      image.onload = function () {
        canvas.width = image.width;
        canvas.height = image.height;

        const context = canvas.getContext("2d");
        context?.drawImage(image, 0, 0, image.width, image.height);

        canvas.toBlob(async (blob) => {
          if (blob) {
            const file = new File([blob], "capturaImage.png", {
              type: "image/png",
            });

            setFileImg(file);
          }
        }, "image/png");
      };
      image.src = imageSrc;
    } catch (err) {
      console.log("err", err);
    }
    setImg(imageSrc);
  }, [webcamRef]);

  const updateFotoByComputer = (file: File) => {
    if (!file) {
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setImg(file);
    setFileImg(file);

    setPreview(objectUrl);
  };

  return (
    <>
      <BoxWebCanImage>
        {showMyPicture ? (
          <Fragment>
            <WebCanContainer>
              {img ? (
                <img
                  src={
                    img
                      ? preview
                      : `${storageBaseUrl}/${containerNameUnit}/${userPicture}`
                  }
                  alt=""
                  style={{
                    height: "150px",
                    width: "150px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              ) : userItem && userItem.picture ? (
                <img
                  src={
                    img
                      ? preview
                      : `${storageBaseUrl}/${containerNameUnit}/${userPicture}`
                  }
                  alt=""
                  style={{
                    height: "150px",
                    width: "150px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <Avatar
                  name={userItem ? userItem?.name : "Avatar"}
                  size="150"
                  round={true}
                  style={{
                    height: "150px",
                    width: "150px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              )}
              <ButtonTakePhoto>
                <img
                  src={CameraIcon}
                  alt=""
                  onClick={() => setShowModalPicture(!showModalPicture)}
                  style={{
                    height: "40px",
                    backgroundColor: "white",
                    borderRadius: "50px",
                  }}
                />
                {showModalPicture && (
                  <ModalSelectOrTakePicture
                    takePicture={() => setShowMyPicture(!showMyPicture)}
                    onClose={() => setShowModalPicture(false)}
                    setFileImg={updateFotoByComputer}
                    addToast={addToast}
                  />
                )}
              </ButtonTakePhoto>
            </WebCanContainer>
          </Fragment>
        ) : img !== "" ? (
          <>
            <WebCanContainer>
              <img
                src={img}
                alt=""
                style={{
                  height: "150px",
                  width: "150px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            </WebCanContainer>
            <ButtonTakePhoto>
              <img
                src={CameraIcon}
                alt=""
                onClick={() => setImg("")}
                style={{
                  height: "40px",
                  backgroundColor: "white",
                  borderRadius: "50px",
                }}
              />
            </ButtonTakePhoto>
          </>
        ) : (
          <>
            <WebCanContainer>
              <Webcam
                audio={false}
                height={"150px"}
                ref={webcamRef}
                screenshotQuality={1024}
                screenshotFormat="image/jpeg"
                width={"150px"}
                videoConstraints={videoConstraints}
                style={{ borderRadius: "50%", objectFit: "cover" }}
              />
            </WebCanContainer>
            <ButtonTakePhoto>
              <img
                src={CameraIcon}
                alt=""
                onClick={capture}
                style={{
                  height: "40px",
                  backgroundColor: "white",
                  borderRadius: "50px",
                }}
              />
            </ButtonTakePhoto>
          </>
        )}
      </BoxWebCanImage>
    </>
  );
};
export default PhotoWebCam;
