import React from "react";
import { Editor } from "@tinymce/tinymce-react";

import {
  Container,
  FooterContainer,
  ButtonContainer,
  ContainerTiny,
} from "./styles";
import { Button } from "@kentarepo/kcf-assets";
import { ISummary } from "./interface";
import { StatusContainer, StatusText } from "../styles";
import { TranscriptionStatus } from "../utils";
import { editorInit } from "../../../../services/utils/editor.config";
import { usePermissions } from "../../../../hooks/permission";
import { FORMS_HEARING } from "../../../../utils/entities/permission";

const Summary: React.FC<ISummary> = ({
  buttonTitle,
  buttonFunction,
  disabledButton,
  status,
  ataRef,
  transcription
}) => {
  const { export: exportDownloads } = usePermissions(FORMS_HEARING.HEARING_FILE);
  return (
    <Container>
      <ContainerTiny id="container_tiny">
        {status === 6 ? (
          <Editor
            apiKey={import.meta.env.VITE_APP_TINY_KEY}
            onInit={(_, editor) => (ataRef ? (ataRef.current = editor) : null)}
            init={editorInit(exportDownloads, false)}
            initialValue={
              transcription && transcription[0]
                ? transcription[0].textPlainSummary
                : ""
            }
            onCopy={(e) => e.preventDefault()}
            onCut={(e) => e.preventDefault()}
          />
        ) : (
          <StatusContainer>
            {(status === 0 || status !== null) && status >= 0 ? (
              TranscriptionStatus(status)
            ) : (
              <StatusText>
                O resumo estará disponivel assim que a transcrição estiver
                concluída
              </StatusText>
            )}
          </StatusContainer>
        )}
      </ContainerTiny>
      <FooterContainer>
        <ButtonContainer>
          <Button disabled={disabledButton} onClick={buttonFunction}>
            {buttonTitle}
          </Button>
        </ButtonContainer>
      </FooterContainer>
    </Container>
  );
};

export default Summary;
