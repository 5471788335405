import React from "react";
import { ILoadSpinner } from "./interface";
import { LoadingContainer } from "./styled";
import { LoadingComponent } from "@kentarepo/kcf-assets";

export const LoadSpinner: React.FC<ILoadSpinner> = ({ loading, zindex }) => {
  return (
    <>
      {loading ? (
        <LoadingContainer zindex={zindex}>
          <LoadingComponent loading={loading} />
        </LoadingContainer>
      ) : null}
    </>
  );
};
