import { IItemDetailLine } from "./interface";
import { ContainerIconLabel, Icon, Label } from "./styles";

export const ItemDetailLine = ({
  disabled,
  onClick,
  icon,
  label,
  permissions
}: IItemDetailLine) => {
  return (
    <>
      {permissions ?  (
        <ContainerIconLabel disabled={disabled}>
          <Icon>{icon}</Icon>
          <Label
            onClick={() => {
              if (!disabled) onClick();
            }}
            disabled={disabled}
          >
            {label}
          </Label>
        </ContainerIconLabel>
      ): null}

    </>
  );
};