import { IWord } from "@kentarepo/kcf-assets";

export const handleChangeStartDate = (
  event: string | null,
  setStartDate: React.Dispatch<React.SetStateAction<string | null>>,
  setStartDateError: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (event !== null && event !== "Invalid Date") {
    setStartDate(event);
    setStartDateError(false);
  } else {
    setStartDate(null);
    setStartDateError(true);
  }
};

export const handleChangeFinishDate = (
  event: string | null,
  setFinishDate: React.Dispatch<React.SetStateAction<string | null>>,
  setFinishDateError: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (event !== null && event !== "Invalid Date") {
    setFinishDate(event);
    setFinishDateError(false);
  } else {
    setFinishDate(null);
    setFinishDateError(true);
  }
};

const getWidthToIndex = (num: number) => {
  let result;

  switch (num) {
    case 0:
      result = "11%%";
      break;
    case 1:
      result = "11%";
      break;
    case 2:
      result = "11%";
      break;
    case 3:
      result = "11%";
      break;
    case 4:
      result = "11%";
      break;
    case 5:
      result = "12%";
      break;
    case 6:
      result = "19%";
      break;
    case 7:
      result = "18%";
      break;
    case 8:
      result = "20%";
      break;
    case 9:
      result = "13%";
      break;
    default:
      result = "11%";
  }

  return result;
};

const getIsDate = (index: number) => {
  if (index === 4 || index === 5) {
    return true;
  }
  return false;
};

const collunmToShow = (index: number) => {
  if (index === 0 || index === 1 || index === 2 || index === 9 || index === 11) {
    return false;
  }
  return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleListFormat = (list: Array<any>) => {
  const arrayToShow: Array<Array<IWord>> = [];
  list.map((item) => {
    const listToCard: Array<IWord> = [];
    const wordsList = Object.values(item);
    
    wordsList.forEach((element, index) => {
      if (collunmToShow(index)) {
        const newWord = {
          label: element + "",
          date: getIsDate(index),
          width: getWidthToIndex(index),
        };
        listToCard.push(newWord);
      }
    });

    if (listToCard.length !== 0) arrayToShow.push(listToCard);
  });

  return arrayToShow;
};
