import React, { useEffect } from 'react';
import close from '../../assets/svg/close.svg';
import { useToast } from '../../hooks/toast';
import { Container, Content, TitleContainer } from './styles'

export type IAlertCard = {
  id: string,
  type: 'success' | 'warning' | 'error',
  title: string,
  description?: string,
  style?: any,
  timeLimitToast?: number
};

export const Toast: React.FC<IAlertCard> = ({ id, type, title, description, timeLimitToast = 4000 }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(id);
    }, timeLimitToast);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, id]);

  return (
    <Container id='toast' type={type}>
      <Content>
        <TitleContainer>
          <strong>{title}</strong>
          <img src={close} onClick={() => removeToast(id)} alt="" />
        </TitleContainer>
        {description && <span>{description}</span>}
      </Content>
    </Container>
  );
};
