import styled, { css } from "styled-components";
import { stylesGlobalDefault } from "../../../global/globalStyle";
import { shade } from "polished";

interface IDivImportCertificate {
  disabled?: boolean;
}

export const LeftFormContainer = styled.div`
margin-top: -4px;
`;
export const Form = styled.form`

    display: flex;
    flex-direction: column;
    width: 380px;
    padding: 0px 140px;
    margin-top: 4px;

`
export const ContainerLogo = styled.div`
  align-self: center;
  display: flex;
  justify-content: center;
  margin-bottom: 33px;
  /* background-color: red; */
`
export const Logo = styled.img``;



export const LinkContainer = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  background: none;
  border: none;
`;

export const Container = styled.div`
margin-top: 7px;
`
export const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;


export const DivForgotPassword = styled.div`
  //margin-bottom: 12px;
  //background-color: red;
`;

export const DivImportCertificate = styled.div<IDivImportCertificate>`
  padding-top: 19px;
  ${(props) => props.disabled && css`
      opacity: 0.6;
      cursor: default !important;

      span {
        cursor: default;
      }
  `}
`;

export const ContainerForgot = styled.div`
  margin-top: 30px;
`;


interface ButtonProps {
  backgroundButton?: "primary" | "secondary";
}


export const Button = styled.button<ButtonProps>`
  background: ${(props) =>
    //props.backgroundButton !== "secondary" ? "#1D9BF0" : "#FFF"};
    props.backgroundButton !== "secondary" ? stylesGlobalDefault.blue : stylesGlobalDefault.white};
  color: ${(props) =>
    //props.backgroundButton !== "secondary" ? "#FFF" : "#1D9BF0"};
    props.backgroundButton !== "secondary" ? stylesGlobalDefault.white : stylesGlobalDefault.blue};
  border: ${(props) =>
    //props.backgroundButton !== "secondary" ? 0 : "2px solid #1D9BF0"};
    props.backgroundButton !== "secondary" ? 0 : `2px solid ${stylesGlobalDefault.blue}`};
  width: 100%;
  height: 40px;
  border-radius: 50px;
  margin-top: 8px;
  //font-family: "Inter";
  font-family: ${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  text-align: center;
  transition: 0.3s;

  &:hover {
    //background: ${shade(0.2, "#0D77BF")};
    background: ${shade(0.2, stylesGlobalDefault.blueDark)};
    transition: 0.2s;
    //color: #fff;
    color: ${stylesGlobalDefault.white};
    cursor: pointer;
  }
`;