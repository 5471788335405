import React from "react";
import { ContainerSchedule, Scheduled, defaultColor } from "./styles";

type IBadge = {
  text?: string;
  textColor?: string;
  radius: string;
  color?: string;
  widthHeigt?: string;
};

export const CircleBadge: React.FC<IBadge> = ({
  text,
  textColor,
  radius,
  color = defaultColor,
  widthHeigt,
 } :IBadge) => {
  return (
    <ContainerSchedule
      color={color}
      itemProp={radius}
      itemType={widthHeigt}
    >
      <Scheduled color={textColor}>{text}</Scheduled>
    </ContainerSchedule>
  );
};
