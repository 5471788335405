/* eslint-disable @typescript-eslint/no-explicit-any */
import api from "../api";

export const hearingTokensService = {
  getOneByHearingFileId: async (hearingFileId: number) => {
    try {
      const { data } = await api.get(
        `/HearingTokens/GetByHearingFileId?hearingFileId=${hearingFileId}`
      );

      return {
        success: true,
        body: data.data,
      };
    } catch (error) {
      return {
        success: false,
        body: {},
      };
    }
  },
};
