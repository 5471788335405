import styled from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

export interface ExpandableLabelProps {
  children: React.ReactNode;
  title: string;
}
// Styled components
export const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Arrow = styled.div<{ expanded: boolean }>`
  width: 8px;
  height: 8px;
  border-left: 2px solid ${stylesGlobalDefault.black};
  border-bottom: 2px solid ${stylesGlobalDefault.black};
  transform: ${(props) =>
    props.expanded ? "rotate(-45deg)" : "rotate(220deg)"};
  transition: transform 0.3s ease;
`;

export const Label = styled.div`
  margin-left: 8px;
`;

export const ContentWrapper = styled.div`
  margin-top: 8px;
`;
