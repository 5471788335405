import { styled } from "styled-components";
import { ILoadSpinnerStyle } from "./interface";

export const LoadingContainer = styled.div<ILoadSpinnerStyle>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: ${(props) => props.zindex ? props.zindex : 3 };
`;
