import styled from "styled-components";

export const Container = styled.div`
  max-width: 360px;
  width: 100%;
  height: calc(100vh - 198px);;

  @media screen and (max-width: 821px) {
    height: 84vh;
  }
`;

export const Content = styled.div`
  padding: 28px 24px;
  height: 100%;
  background: #ecf0f3;
  overflow-y: overlay;
`;
