import styled from "styled-components";

export const Container = styled.div`
    display: flex;
`

export const FloatContainer = styled.div`
  border: 3px solid #fff;
  height: 75vh;
  overflow-y: auto;
`;

export const FloatChild = styled.div`
  width: 40%;
  float: left;
`;

export const FloatChildDropdown = styled.div`
  float: left;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HeigthChild = styled.div`
  margin-top: -3px !important;
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const PlusBetweenDrops = styled.div`
  height: 80px;
  align-content: "center";
  align-items: "center";
  font-size: 730px;
`;
export const ContainerSearch = styled.div`
  padding: 0 24px;
`;

export const Body = styled.div`
  padding: 24px;
  height: 60vh;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ContainerModalBody = styled.div`
  display: flex;
  width: 100%;
  height: 75vh;
  justify-content: center;

  span {
    font-size: 14px;
    font-family: "Inter";
  }
`;

export const FileTitle = styled.div`
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  color: #000;
`;


export const SizeText = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter";
  color: #666666;
  margin: 10px 0;
`;

export const ContainerDelete = styled.button`
  border: ${(props) =>
    props.disabled ? "2px solid #7887A980" : "2px solid #1d9bf0"};
  height: 40px;
  width: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

export const ContainerServiceIntegrationConfig = styled.div`
  width: 100%;
  height: 100%;
`;
