/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import * as S from "../styles";
import { IProfile } from "../interface";
import ModalLevels from "./ModalLevels";

const ModalSelectProfile: React.FC<{
  profiles: IProfile[];
  selected: {
    title: string;
    profileName: string;
    id: number;
  } | null;
  setShowSelector: (show: boolean) => void;
  handleSelectProfile: (profile: any) => void;
}> = ({ profiles, selected, handleSelectProfile, setShowSelector }) => {
  return (
    <S.ListContainer>
      {profiles.map((item) =>
        item && item?.active ? (
          <ModalLevels
            handleSelectProfile={handleSelectProfile}
            item={item}
            selected={selected}
            setShowSelector={setShowSelector}
          />
        ) : null
      )}
    </S.ListContainer>
  );
};

export default ModalSelectProfile;
