/* eslint-disable @typescript-eslint/no-explicit-any */
import { PoliceUnitTypes } from "../actions/actios.types";

interface PoliceUnitState {
  policeUnit: any | null;
}

const initialState: PoliceUnitState = {
  policeUnit: null,
};

const policeUnitReducer = (
  state = initialState,
  action: any,
): PoliceUnitState => {
  switch (action.type) {
    case PoliceUnitTypes.SET_POLICE_UNIT:
      return {
        ...state,
        policeUnit: action.payload,
      };
    default:
      return state;
  }
};
export default policeUnitReducer;
