import { formSimple } from "@kentarepo/kcf-services";
import { IFormPost } from "./interface";
import { Axios } from "axios";

interface IData {
  data: number;
  errors: [];
  message: string;
  ok: boolean;
}

export const post = async (api: Axios, item: IFormPost, endPoint: string) => {
  try {
    const formPostService = formSimple(api);
    const { data } = await formPostService.postSimpleForm({
      endPoint: `${endPoint}Add`,
      object: item,
    });

    const dataReturn: IData = data;
    if (dataReturn.errors.length === 0) {
      item.id = data.data;
      return item;
    } else {
      throw new Error(dataReturn.message);
    }
  } catch (error) {
    const dataReturn: IData = error as IData;
    throw new Error(dataReturn.message);
  }
};

export const put = async (api: Axios, item: IFormPost, endPoint: string) => {
  try {
    const formPostService = formSimple(api);
    const { data } = await formPostService.putSimpleForm({
      endPoint: `${endPoint}Update`,
      object: item,
    });

    const dataReturn: IData = data;
    if (dataReturn.errors.length === 0) {
      return item;
    } else {
      throw new Error(dataReturn.message);
    }
  } catch (error) {
    const dataReturn: IData = error as IData;
    throw new Error(dataReturn.message);
  }
};
