import { useEffect, useState } from "react";
import { IDefaultObject, IHearingTypeAttendantRoleItem } from "../index";

import {
  Container,
  ContainerBody,
  ContainerInputs,
} from "../../StyleCSS/bodyCreateEdit";

import { LogOperation } from "../../../../utils/entities/logOperation";
import { CheckBoxMultipleIds } from "../../../../components/CheckBoxMultipleIds";

//Toast
import { useToast } from "../../../../hooks/toast";
import { IResultCheckBox } from "../../../../components/CheckBoxGlobal";
import { hearingTypeAttendantRole } from "../../../../services/hearingTypeAttendantRole";

interface ICreateEditHearingTypeAttendantRole {
  isEdit: boolean;
  postOrPutTrue: boolean;
  setPostOrPutTrue: (e: boolean) => unknown;
  hearingTypeList: Array<IDefaultObject>;
  attendantRoleList: Array<IDefaultObject>;
  hearingTypeAttendantRoleItem?: IHearingTypeAttendantRoleItem;
  closeModal: boolean;
  hearingTypeId: any;
  setCloseModal: (e: boolean) => unknown;
}

export const CreateEditProcedureCustomerAttendantRole = (
  props: ICreateEditHearingTypeAttendantRole
): JSX.Element => {
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const { addToast } = useToast();

  const [listToShow, setListToShow] = useState<Array<IResultCheckBox>>([]);
  const [newListChecks, setNewListChecks] = useState<Array<IResultCheckBox>>(
    []
  );

  const relationshipOriginalList =
    props.hearingTypeAttendantRoleItem?.attendantRoleList ?? [];
  let listIdsToDelete: Array<number> = [];
  let listIdsToAdd: Array<number> = [];

  useEffect(() => {
    setIsLoad(true);

    if (props.isEdit) {
      setListToShow(
        convertTypeList(
          props.hearingTypeAttendantRoleItem?.attendantRoleList ?? [],
          false
        )
      );
    }
    setIsLoad(false);
  }, []);

  useEffect(() => {
    if (props.postOrPutTrue) {
      putUpdate();
      props.setPostOrPutTrue(false);
    }
  }, [props.postOrPutTrue]);

  useEffect(() => {
    props.setCloseModal(true);
  }, [props.closeModal]);

  function validationFields() {
    if (listIdsToDelete.length === 0 && listIdsToAdd.length === 0) {
      addToast({
        type: "warning",
        title: "Vinculo",
        description:
          "Para atualizar vinculos é necessário marcar e/ou desmarcar pelo menos um tipo de participante.",
      });
      return false;
    }
    return true;
  }

  async function putUpdate() {

    try {
      choiseAction();
      if (!validationFields()) return;
      //ADD
      if (listIdsToAdd.length > 0) {
        for (let i = 0; i < listIdsToAdd.length; i++) {
          await hearingTypeAttendantRole.vincularHearingTypeEAttendantRole(
            LogOperation.getCustomerId(),
            props.hearingTypeId,
            listIdsToAdd[i]
          );
        }
      }

      //DELETE
      if (listIdsToDelete.length > 0) {
        for (let i = 0; i < listIdsToDelete.length; i++) {
          await hearingTypeAttendantRole.deletarVinculoHearingTypeEAttendantRole(
            listIdsToDelete[i]
          );
        }
      }

      addToast({
        type: "success",
        title: "Vinculo",
        description: "Vinculo(s) atualizado(s) com sucesso",
      });

      listIdsToDelete = [];
      listIdsToAdd = [];
      props.setCloseModal(false)
    } catch (error) {
      addToast({
        type: "warning",
        title: "Vinculo",
        description:
          "Para criar um vinculo é necessário escolher um tipo de procedimento.",
      });
      return false;
    }
  }

  function convertTypeList(
    arrayAttendant: Array<IDefaultObject>,
    disabled: boolean
  ) {

    let listChecks = Array<IResultCheckBox>();
    props.attendantRoleList.forEach(function (item) {
      let newObj: IResultCheckBox = {
        id: item.attendantRoleId,
        attendantRoleList: item.attendantRoleId,
        isChecked:
          arrayAttendant.length === 0
            ? false
            : containsIds(arrayAttendant, item.attendantRoleId),
        label: item?.name,
        disabled:
          arrayAttendant.length === 0
            ? false
            : disabled &&
              disabled === true &&
              containsIds(arrayAttendant, item.attendantRoleId),
      };
      listChecks.push(newObj);
    });    
    return listChecks;
  }

  function containsIds(list: Array<IDefaultObject>, idVerify: number) {

    let exist = list.find((x) => x?.id === idVerify);
    if (exist) {
      return true;
    }
    return false;
  }

  function choiseAction() {   
    newListChecks.forEach((element) => {
      if (element.isChecked) {
        let exist = containsIds(relationshipOriginalList, element?.id);
        if (!exist) {
          listIdsToAdd.push(element?.id);
        }
      } else {
        let exist = containsIds(relationshipOriginalList, element?.id);
        if (exist) {
          let hearingTypeAttendantRoleID = relationshipOriginalList.find(
            (x) => x?.id === element?.id
          );
          if (
            hearingTypeAttendantRoleID &&
            hearingTypeAttendantRoleID.hearingTypeAttendantRoleId
          ) {
            listIdsToDelete.push(
              hearingTypeAttendantRoleID.hearingTypeAttendantRoleId
            );
          }
        }
      }
    });
  }

  return (
    <>
      {!isLoad ? (
        <Container>
          <ContainerBody>
            <ContainerInputs>             
              <h2>{props.hearingTypeAttendantRoleItem?.hearingTypeName}</h2>
            </ContainerInputs>
            <CheckBoxMultipleIds
              title="Tipo de audiência"
              listToShow={listToShow}
              getNewList={setNewListChecks}
            />
          </ContainerBody>
        </Container>
      ) : null}
    </>
  );
};
