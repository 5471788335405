import * as Sentry from "@sentry/react";

const initSentryDev = () => {
    Sentry.init({
        dsn: "https://d3f9d15b50fd28b3ef039f55985e8151@o4508173280935936.ingest.us.sentry.io/4508211699843072",
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
          "https://audienciadev.kenta.solutions/",
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
};
export default initSentryDev;
