import { IAuthLeftTemplate } from "./interface";
import backgroundDefaultLogin from "../../assets/background_login.png";

export const getBackgroundImage = ({ backgroundImage }: IAuthLeftTemplate) => {
  switch (backgroundImage) {
    case "default":
      return backgroundDefaultLogin;
    default:
      return backgroundDefaultLogin;
  }
};
