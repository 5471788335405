import { useEffect, useState } from "react";
import { CheckBoxGlobal, IResultCheckBox } from "../CheckBoxGlobal";
import {
  ContainerCheckBoxMultipleIds,
  TitleCheckBoxMultipleIds,
  SpaceCheckBoxMultipleIds,
} from "./styles";

interface ICheckBoxMultipleIds {
  title: string;
  listToShow: Array<IResultCheckBox>;
  getNewList: (e: Array<IResultCheckBox>) => unknown;
}

let arrayIds: Array<number> = [];

export const CheckBoxMultipleIds = (props: ICheckBoxMultipleIds) => {
  const [check, setCheck] = useState<IResultCheckBox>();

  useEffect(() => {
    if (check) {
      if (check?.isChecked) {
        arrayIds.push(check?.id);
      } else {
        var index = arrayIds.indexOf(check?.id);
        if (index > -1) {
          arrayIds.splice(index, 1);
        }
      }
      changeListChecks();
    }
  }, [check]);

  function changeListChecks() {
    
    let newListChecks = props.listToShow;
    newListChecks.forEach((element) => {
      if (element?.id === check?.id) {
        element.isChecked = check.isChecked;
      }
    });
    props.getNewList(newListChecks);
  }

  return (
    <>
      <ContainerCheckBoxMultipleIds>
        <TitleCheckBoxMultipleIds>
          <label>{props.title}</label>
        </TitleCheckBoxMultipleIds>

        {props.listToShow.map((element: IResultCheckBox) => {
          return (
            <SpaceCheckBoxMultipleIds>
              <CheckBoxGlobal
                key={element?.id}
                id={element?.id}
                label={element.label}
                onChecked={(e: IResultCheckBox) => setCheck(e)}
                checked={element.isChecked}
                disabled={element.disabled}
              />
            </SpaceCheckBoxMultipleIds>
          );
        })}
      </ContainerCheckBoxMultipleIds>
    </>
  );
};
