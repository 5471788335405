import React, { useEffect, useState } from "react";
import { toastOption } from "../../global/types";
import { stringFormat } from "@kentarepo/kcf-services";
import { userUtils } from "../../utils/user";

import {
  DrawerDefault,
  PageHeader,
  SearchEmpty,
  SimpleToast,
} from "@kentarepo/kcf-assets";

import {
  ICustomerCertificateGetResponse,
  IImportCertificate,
} from "./interface";

import {
  handleApplyButton,
  onLoadList,
  returnHtml,
  textAreaComponente,
} from "./constants";
import { LoadSpinner } from "../LoadSpinner";

export const ImportCertificate: React.FC<IImportCertificate> = ({
  title,
  projectName,
  api,
  permissions,
}) => {
  const [value, setValue] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [hasCertificate, setHasCertificate] = useState<boolean>(false);

  const [modality, setModality] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [extraAmount, setExtraAmount] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [finishDate, setFinishDate] = useState<string>("");

  //Toast
  const [toastTrigger, setToastTrigger] = useState<number>(0);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastType, setToastType] = useState<toastOption>("warning");
  const [loading, setLoading] = useState(false)

  const [toastTriggerForm, setToastTriggerForm] = useState<number>(0);
  const [toastBodyForm, setToastBodyForm] = useState<string>("");
  const [toastTypeForm, setToastTypeForm] = useState<toastOption>("warning");

  const [updatePage, setUpdatePage] = useState<number>(0);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setValue("");
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const customerId = userUtils.getCustomerIdByUser();

      if (customerId) {
        const response = await onLoadList(customerId, api);

        if (response) {
          if (response.ok) {
            const certificate = response as ICustomerCertificateGetResponse;

            setAmount(certificate.data.amount);
            setExtraAmount(certificate.data.extraAmount);
            setModality(certificate.data.description);
            setStartDate(certificate.data.startDate);
            setFinishDate(certificate.data.finishDate);
            setHasCertificate(true);
          } else {
            setHasCertificate(false);
          }
        }
      } else {
        setHasCertificate(false);
      }
      setLoading(false)
    };

    fetchData();
  }, [updatePage]);

  const handleImportCertificate = () => {
    setOpenDrawer(true);
    setValue("");
    setError(false);
    setToastTrigger(0);
    setToastTriggerForm(0);
  };

  const handleApplyImportCertificate = async () => {
    if (!value || stringFormat.isEmptyOrSpaces(value.trim())) {
      setToastBodyForm("Preencha o campo destacado");
      setToastTypeForm("warning");
      setError(true);
      setToastTriggerForm(toastTriggerForm + 1);
      return;
    } else {
      const returnPost = await handleApplyButton(api, value);

      if (returnPost) {
        if (returnPost.ok) {
          setToastType("success");
          setToastBody(returnPost.message);
          setError(false);
          setOpenDrawer(false);
          setToastTrigger(toastTrigger + 1);
          setUpdatePage(updatePage + 1);
        } else {
          setToastBodyForm(returnPost.message);
          setToastTypeForm("error");
          setError(true);
          setToastTriggerForm(toastTriggerForm + 1);
          return;
        }
      }
    }
  };

  return (
    <>
      <SimpleToast
        id="attendantRoleToastId"
        title={title}
        type={toastType}
        description={toastBody}
        handleCloseToast={() => { }}
        trigger={toastTrigger}
      />
      <PageHeader
        quantity={0}
        id="headerAdminArea"
        label={title}
        background="secondary"
        addClick={permissions.toCreate ? handleImportCertificate : undefined}
      />
      {permissions.toRead && hasCertificate ? (
        returnHtml(
          projectName,
          modality,
          amount,
          extraAmount,
          startDate,
          finishDate
        )
      ) : (
        <SearchEmpty
          textMessage={
            permissions.toRead
              ? "Nenhum certificado encontrado"
              : "Sem permissão"
          }
          typeIcon="file"
          marginTop="30px"
        />
      )}

      <DrawerDefault
        title={`Importar ${title.toLocaleLowerCase()}`}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        onClose={handleCloseDrawer}
        onApply={handleApplyImportCertificate}
        children={textAreaComponente(
          "Certificado",
          value,
          setValue,
          error,
          toastTypeForm,
          toastBodyForm,
          toastTriggerForm
        )}
      />
      <LoadSpinner loading={loading} zindex={1300} />
    </>
  );
};
