import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import { ILogo } from "./interface";
import { ButtonTabGroup } from "@kentarepo/kcf-assets";

export const Container = styled.div`
  #GroupDropdownObjectId {
    align-items: center;
    cursor: default;
  }

  #AccordionSummaryCustomId {
    cursor: default;
    z-index: 999;
  }

  #containerTitleAccordionId {
    cursor: default;
  }
  .MuiAccordionDetails-root {
    z-index: 999;
  }
  .css-kjd5re-MuiPaper-root-MuiAccordion-root {
    z-index: 999;
  }

  #accordionDetailsCustomId {
    z-index: 999;
  }
`;

export const BoxMenuLeftFullScrean = styled(Box)`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 24px;
  left: 0;
  flex-direction: row;
`;

export const DivLogo = styled.div`
  align-self: center;
  height: 55px;
  width: 120px;
`;

export const Logo = styled.img`
  align-self: center;
  width: 100px;
  max-width: 100%;
  max-height: 75%;
  padding-top: 7px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 0px;
`;

export const ContainerLeft = styled.div<ILogo>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-right: 24px;
  border-right: 1px solid #ccc;
  height: 60px;

  img {
    width: 100px;
    height: 20px;

    ${(props) =>
      props.hasOtherLogo &&
      css`
        height: 35px;
        width: 100%;
        object-fit: contain;
      `}
  }
`;

export const ButtonTabGroupFixed = styled(ButtonTabGroup)``;
