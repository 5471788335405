/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { baseURL } from "../api";
import { getValueFromlocalStorage } from "../utils/sessionStorageEncrypt";

interface UploadFileInBlockProps {
  file: File;
  endpoint: string
  hearingFileId?: number
  isMain?: boolean
}

const UploadFileInBlock = async ({ file, endpoint, hearingFileId, isMain, onProgress }: UploadFileInBlockProps & { onProgress?: (percentage: number) => void }) => {
  const token = getValueFromlocalStorage("@Kenta:access_Token");
  const blockSize = 5 * 1048576;
  const fileName = file.name;
  const isMainRecord = isMain ? `&mainVideo=${isMain}` : ''
  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        resolve(result.split(',')[1]);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const base64Data = await readFileAsBase64(file);
  const base64Length = base64Data.length;
  const blockNumber = Math.ceil(base64Length / blockSize);
  const hearingFileIdPath = hearingFileId ? `&hearingFileId=${hearingFileId}` : ''

  let currentBlock = 0;
  let start = 0;

  const config = {
    headers: {
      'Content-Type': "application/json; charset=utf-8",
      Authorization: `Bearer ${token}`,
    }
  };

  for (let index = currentBlock; index < blockNumber; index++) {
    const end = start + blockSize;
    const blockData = base64Data.slice(start, end);

    try {
      const { data } = await axios.post(
        `${baseURL}/HearingFile/${endpoint}?fileName=${fileName}&blockNumber=${currentBlock + 1}&blocks=${blockNumber}${hearingFileIdPath}${isMainRecord}`,
        JSON.stringify(blockData),
        config
      );
      const progress = Math.round(((currentBlock + 1) / blockNumber) * 100);
      if (onProgress) {
        onProgress(progress)
      }
      if (data.ok) {
        return data;
      }
    } catch (error:any) {
      console.error('Erro ao enviar o arquivo:', error);
      return error.response.data
    } finally {
      currentBlock++;
      start = end;
    }
  }
}

export default UploadFileInBlock;
