/* eslint-disable @typescript-eslint/no-explicit-any */
import { BrowserRouter } from "react-router-dom";
import { Router } from "./routers";
import GlobalStyle from "./global/styles";
import { useEffect } from "react";
import { LogOperation } from "./utils/entities/logOperation";

function App() {
  useEffect(() => {
    (async () => {
      await LogOperation.setMachineIP();
    })();
  }, []);

  return (
    <BrowserRouter>
      <Router />
      <GlobalStyle />
    </BrowserRouter>
  );
}

export default App;
