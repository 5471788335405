import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

import {
  Container,
  FooterContainer,
  ButtonContainer,
  ContainerTiny,
} from "./styles";
import { Button } from "@kentarepo/kcf-assets";
import { IAta } from "./interface";
import { editorInit } from "../../../../services/utils/editor.config";
import { ContainerTemplate } from "../Transcription/styles";
import { DropdownGlobal } from "../../../../components/DropdownGlobal";
import { customerTemplateService } from "../../../../services/costumerTemplate";
import { useQuery } from "../../../../utils/get";
import { FORMS_HEARING } from "../../../../utils/entities/permission";
import { usePermissions } from "../../../../hooks/permission";

const Ata: React.FC<IAta> = ({
  buttonTitle,
  buttonFunction,
  disabledButton,
  ataRef,
  ata,
  setSelectTemplate,
  selectTemplate,
  modelList,
  loadingAba,
  setChangedTextAta
}) => {
  const query = useQuery();

  const hearingId = query.get("hearingId");
  const hearingFileId = query.get("hearingFileId");
  const [tinyText, setTinyText] = useState("");
  const { export: exportDownloads } = usePermissions(
    FORMS_HEARING.HEARING_FILE
  );

  const selectedModel =
    ata && ata[0] && modelList.length
      ? modelList.find((x: any) => x.id === ata[0].customerTemplateId)
      : { name: "Selecione o modelo", template: "" };

  useEffect(() => {   
    if (ata && ata.length) {
      setTinyText(selectTemplate ? selectTemplate?.template : ata[0].content);
    }
  }, [ata]);

  useEffect(() => {
    (async () => {      
      //if (changedTextAta) {
        if (!(ata && ata.length && ata[0] && ata[0]?.content)) {
          if (selectTemplate) {           
            const { body } =
              await customerTemplateService.SetKeywordsByHearingFile({
                hearingFileId: Number(hearingFileId),
                customerTemplateId:
                  selectTemplate && selectTemplate?.id ? selectTemplate?.id : 0,
              });
           
            setChangedTextAta(false);
            setTinyText(body);
          }
        }
      //}
    })();
  }, [selectTemplate, hearingId]);

  const onClick = () => {
    setChangedTextAta(true);
    buttonFunction();
  }

  return (
    <Container>
      {!loadingAba ? (
        <ContainerTemplate>
          <DropdownGlobal
            labelName="Modelo"
            options={modelList}
            defaultValue={
              selectTemplate ? selectTemplate?.name : selectedModel?.name
            }
            showDefaultValue
            width="320px"
            setSelected={setSelectTemplate}
            wantAnObject
          />
        </ContainerTemplate>
      ) : (
        <ContainerTemplate></ContainerTemplate>
      )}
      <ContainerTiny>
        <Editor
          apiKey={import.meta.env.VITE_APP_TINY_KEY}
          onInit={(_, editor) => (ataRef ? (ataRef.current = editor) : null)}
          init={editorInit(exportDownloads, true)}
          initialValue={ata && ata[0] ? ata[0].content : ""}
          value={tinyText}
          onEditorChange={(e) => {
            setTinyText(e);
          }}
          onCopy={(e) => e.preventDefault()}
          onCut={(e) => e.preventDefault()}
        />
      </ContainerTiny>
      <FooterContainer>
        <ButtonContainer>
          <Button disabled={disabledButton} onClick={onClick}>
          {buttonTitle}
          </Button>
        </ButtonContainer>
      </FooterContainer>
    </Container>
  );
};

export default Ata;
