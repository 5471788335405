/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import * as S from "../../styles";
import { IProfile } from "../../interface";
import ArrowDown from "../../../../assets/svg/arrow-down.svg";
import ArrowUp from "../../../../assets/svg/arrow-up.svg";

const ModalLevels: React.FC<{
  item: IProfile;
  selected: any | null;
  handleSelectProfile: (profile: any) => void;
  setShowSelector: (profile: boolean) => void;
}> = ({ handleSelectProfile, item, selected, setShowSelector }) => {
  const [showSecondLevel, setShowSecondLevel] = useState(false);
  return (
    <S.ListLevelOne
      key={item.id}
      selected={selected?.id === item?.id}
      isSelectable={!(item?.places && item?.places?.length)}
    >
      <S.ContainerTitle onClick={
        item.profile.toLowerCase() !== "master"
          ? () => setShowSecondLevel(!showSecondLevel)
          : () => {
            handleSelectProfile({
              ...item?.places[0],
              profileName: item.profile,
              placeId: item?.places[0].id,
              title: item?.places[0]?.name,
              profileId: item.profileId,
              containerName: item.containerName,
            })
            setShowSelector(false);
          }
      }
      >
        <span>{`${item?.place} - ${item?.profile}`}</span>
        {item.profile.toLowerCase() !== "master" && <div >
          <img
            className="icon"
            src={showSecondLevel ? ArrowUp : ArrowDown}
            style={{ cursor: "pointer" }}
            alt=""
          />
        </div>}
      </S.ContainerTitle>
      {
        showSecondLevel &&
        item?.places &&
        item?.places?.length &&
        item?.places.map((itemPlace) => (
          <S.ListLevelTwo
            selected={selected?.id === itemPlace?.id}
            key={itemPlace?.id}
            onClick={() => {
              handleSelectProfile({
                ...itemPlace,
                profileName: item.profile,
                placeId: itemPlace?.id,
                title: itemPlace?.name,
                profileId: item.profileId,
                containerName: item.containerName,
              });
              setShowSelector(false);
            }}
          >
            {itemPlace?.name}
          </S.ListLevelTwo>
        ))
      }
    </S.ListLevelOne >
  );
};

export default ModalLevels;
