import styled from "styled-components";

interface IMargin {
  hasPermission: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;
  padding: 0 30px;
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
`;

export const ContainerInputsDate = styled.div<IMargin>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: ${(props) => (props.hasPermission ? 8 : 0)}px;
`;

export const ContainerWithButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
export const ContainerLoading = styled.div`
  position: absolute; 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  width: 100%; 
  height: 100%; 
  background: rgb(0, 0, 0,0.8);
`;
export const TextLoading = styled.p`
  font-size: 16px;
  color: #fff
`;
