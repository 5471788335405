import React from "react";

import {
  Container,
  ContainerTextCenter,
  Icon,
  IconsContainer,
  TopBar,
} from "./styles";
import { IAuthLeftTemplate } from "../interface";
import { getBackgroundImage } from "../utils";
import microIcon from "../../../assets/svg/micro.svg";
import videoIcon from "../../../assets/svg/video.svg";

export const LeftTemplate: React.FC<IAuthLeftTemplate> = ({
  backgroundImage,
}) => {
  return (
    <Container backgroundImage={getBackgroundImage({ backgroundImage })}>
      <ContainerTextCenter>
        <TopBar />
        <strong>Agilidade e segurança</strong>
        <span>na coleta de informações</span>
        <IconsContainer>
          <Icon>
            <img src={microIcon} alt="microIcon" />
          </Icon>
          <Icon>
            <img src={videoIcon} alt="videoIcon" />
          </Icon>
        </IconsContainer>
      </ContainerTextCenter>
    </Container>
  );
};
