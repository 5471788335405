/* eslint-disable @typescript-eslint/no-explicit-any */
import { CardContainer, LabelCard, StatusContainer, Wrapper } from "./style";

export function CardAdminItem({
  id,
  name,
  active,
  description,
  onClickEditAction,
  selected,
  showStatus = true,
  disable = false,
  setIsEdit,
}: any) {
  return (
    <CardContainer
      disabled={disable}
      isActive={selected}
      data-cy={name}
      key={id}
      onClick={() => {
        if (!disable) {
          onClickEditAction({ id, name, active, description });
          setIsEdit && setIsEdit(true);
        }
      }}
    >
      <Wrapper>
        <LabelCard>{name}</LabelCard>
        {description && (
          <LabelCard style={{ fontWeight: 300, marginTop: 10 }}>
            {" "}
            {description}
          </LabelCard>
        )}
      </Wrapper>
      {showStatus && (
        <StatusContainer active={active} disabled={false}>
          <strong>{active ? "Ativo" : "Inativo"}</strong>
        </StatusContainer>
      )}
    </CardContainer>
  );
}
