import { Box } from "@mui/material";
import styled from "styled-components";

export const ContainerImportCertificateLogged = styled.div`
  width: 100%;
  overflow-y: hidden;
`;

export const Body = styled(Box)`
  padding-right: 44px;
`;

export const DivLogo = styled(Box)`
  margin-bottom: 31px;
  height: 40px;

  align-items: left;
  justify-content: left;
`;

export const ContainerInfosCertificate = styled(Box)`
  margin: "30px 0px 0px 30px";
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  margin-left: 30px;

  margin-top: 20px;
`;

export const ContainerProjectName = styled(Box)`
  margin-top: 10px;
  margin-bottom: 10px;
`;
