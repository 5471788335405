import styled from "styled-components";

interface IListProps {
  disabled?: boolean;
}

export const Container = styled.div`
  width: calc(100% - 350px);
`;

export const Content = styled.div<IListProps>`
  display: flex;
  flex-direction: column;
  padding: 8px;
  height: calc(100vh - 170px);
  overflow-y: auto;
  overflow-x: hidden;
  gap: 4px;
`;
