import styled from "styled-components";
import { FullView } from "../../../features/fullview";

export const Container = styled.div`
  margin: 0;
  padding: 0;
  overflow: hidden;
  
  #container_tiny {
    height: calc(100vh - 279px);
  }
`;

export const FullViewComponente = styled(FullView)`
  z-index: -1;
`;
