/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureStore } from "@reduxjs/toolkit";
import attachmentReducer from "./modules/attachment/reducer";
import loadingReducer from "./modules/login/reducer";
import permissionReducer from "./modules/permission/reducer";
import playerReducer from "./modules/playercontrol/reducer";
import policeUnitReducer from "./modules/policeUnit/reducer";
import profileReducer from "./modules/profile/reducer";
import userProfileCreateReducer from "./modules/profileCreate/reducer";
import userReducer from "./modules/user/reducer";
import userCreateDistrict from "./modules/userCreateUnit/reducer";
import procedureReducer from "./modules/procedure/reducer";
import screenStatusReducer from "./modules/screen_controll/reducer";
import globalConfigurationsReducer from "./modules/globalConfiguration/reducer";
import customizationReducer from "./modules/costumizations/reducer";

export default configureStore({
  reducer: {
    attachment: attachmentReducer,
    user: userReducer,
    profile: profileReducer,
    loading: loadingReducer,
    permissions: permissionReducer,
    policeUnit: policeUnitReducer,
    player: playerReducer,
    userCreateDistrict: userCreateDistrict,
    userCreateProfile: userProfileCreateReducer,
    procedure: procedureReducer,
    screen: screenStatusReducer,
    configurations: globalConfigurationsReducer,
    customization: customizationReducer,
  },
});
