import React from "react";

import { Container, ContainerLoading } from "./styles";
import { MenuTabsKenta } from "@kentarepo/kcf-assets";
import { menu } from "./constants";
import { IMenuTab } from "./interface";
import Loading from "react-loading";

const RightTemplate: React.FC<IMenuTab> = ({
  defaultPage = 0,
  transcription,
  summary,
  ata,
  mark,
  setPage,
  loadingAba
}) => {
  const menuData = menu({ transcription, summary, mark, ata });
  return (
    <Container>
      {loadingAba && (
        <ContainerLoading>
          <Loading width={40} type="spin" color="#000" />
        </ContainerLoading>
      )}
      <MenuTabsKenta
        bodyList={menuData.bodyList}
        menuList={menuData.menusList}
        pageDefault={defaultPage}
        setPage={setPage}
      />
    </Container>
  );
};

export default RightTemplate;
