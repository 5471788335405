import React, { useEffect, useState } from "react";
import { CircleBadge } from "../Badges/Circle";

import {
  Container,
  ContainerInput,
  InputText,
  Label,
} from "./styles";


type IInput = {
  id?: string;
  width?: number;
  height?: number;
  marginLeft?: number;
  label: string;
  value?: string | number;
  icon?: boolean;
  maxLength?: number;
  mt?: string;
  type?: "file";
  error?: boolean;
  setType?: (e: any) => unknown;
  onChange: (e: File | null) => unknown;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  onClick?: any;
  startFocus?: boolean;
  ref?: any; //HTMLInputElement
  pattern?: any;
  onKeyPress?: (e: any) => unknown;
  defaultValue?: string;
  accept?: any;
};

export const InputGeneralFile: React.FC<IInput> = ({
  id,
  label,
  maxLength,
  onChange,
  value,
  defaultValue,
  disabled = false,
  error = false,
  type = "file",
  width,
  mt,
  height,
  required,
  marginLeft,
  placeholder,
  onClick,
  pattern,
  startFocus = true,
  onKeyPress,
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [file, setFile] = useState<any>();

  const activeFocus = () => {
    setFocus(true);
  };

  const inactiveFocus = () => {
    setFocus(false);
  };

  const inputRef = React.useRef<any>(null);

  useEffect(() => {
    if (startFocus) {
      focusInput();
    } else {
      blurInput();
    }
  }, []);

  function focusInput() {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  function blurInput() {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  }

  return (
    <Container marginTop={mt} style={{ marginLeft: marginLeft, width: width }}>
      <Label>{label}</Label>
      {required && !disabled && <CircleBadge radius="50px" widthHeigt="8px" />}
      <ContainerInput
        width={width}
        height={height}
        notError={!!value}
        error={error}
        focus={focus}
      >
        <InputText
          id={id}
          accept="image/png"
          maxLength={maxLength}
          onFocus={activeFocus}
          onBlur={inactiveFocus}
          autoComplete="on"
          autoCapitalize="off"
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          value={value}
          onClick={onClick}
          required={required}
          ref={inputRef}
          pattern={pattern}
          onChange={(e) => {
            setFile(e?.target && e?.target?.files && e?.target?.files[0]);
            onChange(e?.target && e?.target?.files && e?.target?.files[0]);
          }}
          onKeyPress={onKeyPress}
        />
        <span>{file ? file?.name : defaultValue}</span>
      </ContainerInput>
    </Container>
  );
};
