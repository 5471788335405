import { formatMMDDYYYY } from "./format";

export default function DateValidate (date: Date | string) {

  if (!date || date === 'Invalid Date') {
    return false
  }

  const selectedDate = new Date(formatMMDDYYYY(date as Date));

  const currentDate = new Date();


  let differenceTime = selectedDate.getTime() - currentDate.getTime();

  if (differenceTime <= 0) {
    return true
  } else {
    return false
  }
}

export function compareDatesIgnoringSeconds  (data: Date) {

  const dataAtual = new Date();

  data.setSeconds(0, 0);
  dataAtual.setSeconds(0, 0);

  return data >= dataAtual;
}

