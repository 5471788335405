import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import AppProvider from './hooks/index.tsx'
import storeRedux from "./store";
import { Provider } from 'react-redux'
import "./index.css"
import initSentryDev from '../sentryDev.ts';

initSentryDev();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <AppProvider>
    <Provider store={storeRedux}>
      <App />
    </Provider>
  </AppProvider>
)
