import { BaseLabel, Grid } from '@kentarepo/kcf-assets';
import { InputGeneral } from '../../../../../components/Input';

const generateExampleFromRegex = (regex: string) => {
  if (!regex) {
    return
  }
  return regex
    .replace(/\\d/g, '0')
    .replace(/\\/g, '')
};
interface ProcedureMaskProps {
  setEditedValue: (e: string) => void
  editedValue: string
}

const ProcedureMask = ({ setEditedValue, editedValue }: ProcedureMaskProps) => {
  const formattedExample = generateExampleFromRegex(editedValue);

  const handleChange = (e: string) => {
    const filteredValue = e.replace(/[^0-9./,-]/g, '');
    setEditedValue(filteredValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BaseLabel
          fontSize="18px"
          fontWeight="700"
          marginBottom="0px"
          marginTop="0px"
          marginLeft="0px"
          marginRight="0px"
          children={"Digite a máscara de exemplo"}
        />
      </Grid>
      <Grid item xs={12}>
        <InputGeneral
          error={false}
          label={"Digite a máscara que será usada no número do processo."}
          value={formattedExample}
          placeholder='ex: 000.000.000-00'
          onChange={(e) => handleChange(e)}
          required={false}
          width={381}
          maxLength={499}
          id="name_attendant"
          type="text"
        />
      </Grid>
    </Grid>
  );
};

export default ProcedureMask;
