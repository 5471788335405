import styled from "styled-components";
import { stylesGlobalDefault } from "../../global/globalStyle";

interface IContent {
  show: boolean;
}

export const Container = styled.div`
  position: fixed;
  right: 30px;
  bottom: 0;
  width: 350px;
  border-radius: 10px 10px 0 0;
  background: #fff;
  border: 0.4px solid #888;

`;

export const HeaderContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  background: ${stylesGlobalDefault.backgroundListItemColor};
  border: 0;
  cursor: pointer;
`;

export const HeaderTitle = styled.span`
  font-size: 14px;
  font-family: "Inter";
  font-weight: 700;
`;

export const Content = styled.div<IContent>`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  height:${(props) => props.show ? "auto" : "1px"};
`;


export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;

  img {
    width: 16px;
    height: 16px;
  }

  .icon-close {
    width: 14px;
    height: 14px;
  }
`;