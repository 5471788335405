import api from "../api";
import { AxiosError } from "axios";
import { returnResponse } from "../utils";
import { format } from "date-fns";

export const ReportsService = {
  getTranscriptionKentaAI: async (
    customerId: number,
    startDate: Date,
    finishDate: Date
  ) => {
    try {
      const startDateService = format(startDate, "yyyy-MM-dd") + " 00:00:00";
      const finishDateService = format(finishDate, "yyyy-MM-dd") + " 23:59:59";

      const { data } = await api.get(
        `/Reports/GetTranscriptionReport?customerId=${customerId}&startDate=${startDateService}&endDate=${finishDateService}`
      );
      
      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  async getAllLogErros(
    customerId: number,
    startDate: string,
    endDate: string,
    platform?: string | null
  ) {
    try {
      const { data } = await api.get<any>(
        `/LogError/GetAll?customerId=${customerId}&startDate=${startDate}&finishDate=${endDate}${
          platform ? `&platform=${platform}` : ""
        }`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (err) {
      return {
        status: false,
        data: null,
        message: "Houve um erro ao consultar os registros.",
      };
    }
  },

  async getAllLogOperations(
    customerId: number,
    startDate: string | null,
    endDate: string | null,
    operation: number | null,
    device: string | null
  ) {
    try {
      if (!operation && !device) {
        const { data } = await api.get<any>(
          `/LogOperation/GetAll?customerId=${customerId}&startDate=${startDate}&finishDate=${endDate}`
        );
        return {
          status: true,
          data: data,
          message: data.message,
        };
      }

      if (operation && !device) {
        const { data } = await api.get<any>(
          `/LogOperation/GetAll?customerId=${customerId}&startDate=${startDate}&finishDate=${endDate}&operation=${operation}`
        );
        return {
          status: true,
          data: data,
          message: data.message,
        };
      }

      if (!operation && device) {
        const { data } = await api.get<any>(
          `/LogOperation/GetAll?customerId=${customerId}&startDate=${startDate}&finishDate=${endDate}&device=${device}`
        );
        return {
          status: true,
          data: data,
          message: data.message,
        };
      }

      if (!device) {
        const { data } = await api.get<any>(
          `/LogOperation/GetAll?customerId=${customerId}&startDate=${startDate}&finishDate=${endDate}&operation=${operation}`
        );

        return {
          status: true,
          data: data,
          message: data.message,
        };
      }

      if (!operation) {
        const { data } = await api.get<any>(
          `/LogOperation/GetAll?customerId=${customerId}&startDate=${startDate}&finishDate=${endDate}&device=${device}`
        );
        return {
          status: true,
          data: data,
          message: data.message,
        };
      }

      const { data } = await api.get<any>(
        `/LogOperation/GetAll?customerId=${customerId}&startDate=${startDate}&finishDate=${endDate}&operation=${operation}&device=${device}`
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (err) {
      return {
        status: false,
        data: null,
        message: "Houve um erro ao consultar os registros.",
      };
    }
  },
};
