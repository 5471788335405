import { Route, Routes } from "react-router-dom";
import { SignIn } from "../pages/auth/signIn";
import { ProtectedRoute } from "./ProtectoredRoute";
import { Audiences } from "../pages/app/audiences";
import { Administration } from "../pages/app/Administration";
import { Documents } from "../pages/app/Documents";
import HearingRecord from "../pages/app/HearingRecord";
import { AttendantRole } from "../pages/app/AttendantRole";
import { HearingType } from "../pages/app/HearingType";
import { Dashboard } from "../pages/app/dashboard";
import Attendant from "../pages/app/Attendant";
import { ImportCertificate } from "../pages/auth/importCertificateLoggedOut";
import { CustomerCertificate } from "../pages/app/ImportCertificateLogged";
import { ChangePassword } from "../pages/auth/changePassword";
import { ForgotPassword } from "../pages/auth/forgotPassword";
import { NotFoundPage } from "../pages/NotFoundPage";
import { FullViewPage } from "../pages/app/FullView";
import CreateUsers from "../pages/app/Users";
import { Profiles } from "../pages/app/Profiles";
import { UploadingFileProvider } from "../features/ValidationModal/context";
import { Unit } from "../pages/app/Unit";
import { SystemConfiguration } from "../pages/app/systemConfiguration";
import { HearingTypeAttendantRole } from "../pages/app/HearingTypeAttendantRole";
import { ServiceIntegrationImportation } from "../pages/app/ServiceIntegrationImportation";
import { ReportMenu } from "../pages/app/ReportsKenta/ReportMenu";
import { TranscriptionReport } from "../pages/app/ReportsKenta/Transcriptions";
import { CustomerTemplate } from "../pages/app/CustomerTemplate";
import { LogErros } from "../pages/app/ReportsKenta/LogErros";
import { LogOperations } from "../pages/app/ReportsKenta/LogOperations";

export function Router() {
  return (
    <Routes>
      {/* logged out */}
      <Route path="/" element={<SignIn />} />
      <Route path="/importCertificate" element={<ImportCertificate />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/changePassword/guid/:guid" element={<ChangePassword />} />

      {/* logged */}
      <Route
        path="/audiences"
        element={
          <ProtectedRoute>
            <UploadingFileProvider>
              <Audiences />
            </UploadingFileProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/audiences/fullview"
        element={
          <ProtectedRoute>
            <FullViewPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <UploadingFileProvider>
              <Dashboard />
            </UploadingFileProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/administration"
        element={
          <ProtectedRoute>
            <Administration />
          </ProtectedRoute>
        }
      />
      <Route
        path="/administration/documents"
        element={
          <ProtectedRoute>
            <Documents />
          </ProtectedRoute>
        }
      />
      <Route
        path="/recording/una"
        element={
          <ProtectedRoute>
            <HearingRecord />
          </ProtectedRoute>
        }
      />
      <Route
        path="/administration/Attendant"
        element={
          <ProtectedRoute>
            <Attendant />
          </ProtectedRoute>
        }
      />
      {/* ROTAS PARA OS MENUS DE ADMINISTRAÇÃO */}
      <Route
        path="/administration/AttendantRole"
        element={
          <ProtectedRoute>
            <AttendantRole />
          </ProtectedRoute>
        }
      />

      <Route
        path="/administration/HearingType"
        element={
          <ProtectedRoute>
            <HearingType />
          </ProtectedRoute>
        }
      />

      <Route
        path="/administration/UserCustomerProfiles"
        element={
          <ProtectedRoute>
            <Profiles />
          </ProtectedRoute>
        }
      />

      <Route
        path="/administration/CustomerCertificate"
        element={
          <ProtectedRoute>
            <CustomerCertificate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/administration/Users"
        element={
          <ProtectedRoute>
            <CreateUsers />
          </ProtectedRoute>
        }
      />

      <Route
        path="/administration/Place"
        element={
          <ProtectedRoute>
            <Unit />
          </ProtectedRoute>
        }
      />

      <Route
        path="/administration/hearingTypeAttendantRole"
        element={
          <ProtectedRoute>
            <HearingTypeAttendantRole />
          </ProtectedRoute>
        }
      />

      <Route
        path="/administration/SystemConfiguration"
        element={
          <ProtectedRoute>
            <SystemConfiguration />
          </ProtectedRoute>
        }
      />

      <Route
        path="/administration/ServiceIntegrationImportation"
        element={
          <ProtectedRoute>
            <ServiceIntegrationImportation />
          </ProtectedRoute>
        }
      />

      <Route
        path="/administration/CustomerTemplate"
        element={
          <ProtectedRoute>
            <CustomerTemplate />
          </ProtectedRoute>
        }
      />

      <Route
        path="/*"
        element={
          <ProtectedRoute>
            <NotFoundPage />
          </ProtectedRoute>
        }
      />

      {/* ROTAS PARA RELATÓRIOS */}
      <Route
        path="/report"
        element={
          <ProtectedRoute>
            <ReportMenu />
          </ProtectedRoute>
        }
      />
      <Route
        path="/report/transcription"
        element={
          <ProtectedRoute>
            <TranscriptionReport />
          </ProtectedRoute>
        }
      />
      <Route
        path="/report/LogOperation"
        element={
          <ProtectedRoute>
            <LogOperations />
          </ProtectedRoute>
        }
      />
      <Route
        path="/report/LogErros"
        element={
          <ProtectedRoute>
            <LogErros />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
