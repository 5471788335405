import { colors } from "@kentarepo/kcf-assets";
import { styled } from "styled-components";

export const ContainerAllFilter = styled.div`
  height: 100%;

  background-color: ${colors.neutral_white};
`;

export const ContainerFormFilter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerFormFilterSwitch = styled.div`
  margin-bottom: 12px;
  display: flex;
  margin-top: 20px;
  margin-left: -9px;
`;

export const SimpleDropDownDiv = styled.div`
  width: 100%;
  padding-top: 15px;
`;

export const ContainerTwoComponents = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  padding-top: 20px;
`;
