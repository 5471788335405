import { Box } from "@mui/material";
import { styled } from "styled-components";

export const ContentList = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 4px;

  @media screen and (max-height: 1080px) {
    height: 70vh;
  }

  @media screen and (min-width: 1366px) and (max-width: 1366px) and (min-height: 768px) and (max-height: 768px) {
    height: 64vh;
  }
`;