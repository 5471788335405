import React, { useState, useEffect } from "react";
import { Container, ContainerBody, ContainerList } from "./styles";
import { DropdownGlobal } from "../../../../components/DropdownGlobal";
import { useToast } from "../../../../hooks/toast";
import { getValueFromlocalStorage } from "../../../../utils/sessionStorageEncrypt";

//Load
import {
  hideLoading,
  showLoading,
} from "../../../../store/modules/login/actions";
import { useDispatch } from "react-redux";
import { ReportsService } from "../../../../services/reportsService";
import {
  Button,
  DateInput,
  IWord,
  ListEmpty,
  PageHeader,
  ReportCard,
  SearchEmpty,
} from "@kentarepo/kcf-assets";
import { ReactPaginateKenta } from "@kentarepo/kcf-templates";
import { ContainerLeftMenu } from "../../Administration/styles";
import { ReportMenu } from "../ReportMenu";
import { usePermissions } from "../../../../hooks/permission";
import { FORMS_RELATORIOS } from "../../../../utils/entities/permission";
import { LoadSpinner } from "../../../../features/LoadSpinner";

interface reportFieldsProps {
  userName: string
  operation: string
  systemObject: string
  createIn: string
}

export function LogOperations() {
  const today = new Date().toISOString().split("T")[0];
  const [listOfLogs, setListOfLogs] = useState([]);
  const [initialDate, setInitialDate] = useState<any>(today);
  const [loading, setLoading] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<any>(today);
  const user = getValueFromlocalStorage("@Kenta:user");
  let customerId: any = null;
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const { read } = usePermissions(FORMS_RELATORIOS.LOG_OPERATION);

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }
  const [dispositivo, setDispositivo] = useState("Todos");
  const [operacao, setOperacao] = useState(12);
  const inputRefNew = React.useRef<HTMLInputElement>(null);
  const reportTitle: IWord[] = [
    { label: "Usuário", width: "46.5%", date: false },
    { label: "Operação", width: "17%", date: false },
    { label: "Objeto", width: "15.5%", date: false },
    { label: "Data", width: "19%", date: false },
  ];
  const reportFields = ({ userName, operation, systemObject, createIn }: reportFieldsProps) => {
    return [
      { label: userName, width: "48%", date: false },
      { label: operation, width: "17%", date: false },
      { label: systemObject, width: "16%", date: false },
      { label: createIn, width: "19%", date: true },
    ]
  };

  const options = [
    {
      id: 0,
      name: "API",
    },
    {
      id: 2,
      name: "Celular",
    },
    {
      id: 1,
      name: "PC",
    },
    {
      id: 3,
      name: "Todos",
    },
  ];
  const optionsOperation = [
    {
      id: 1,
      name: "Inserção",
    },
    {
      id: 2,
      name: "Atualização",
    },
    {
      id: 3,
      name: "Exclusão",
    },
    {
      id: 4,
      name: "Login",
    },
    {
      id: 5,
      name: "Logoff",
    },
    {
      id: 6,
      name: "Exportação",
    },
    {
      id: 7,
      name: "Importação",
    },
    {
      id: 8,
      name: "Gravação",
    },
    {
      id: 9,
      name: "Visualização",
    },
    {
      id: 10,
      name: "Publicação",
    },
    {
      id: 11,
      name: "Validação",
    },
    {
      id: 12,
      name: "Todos",
    },
  ];

  useEffect(() => {
    if (inputRefNew.current) {
      inputRefNew.current.focus();
    }
  }, []);

  const logsOperation = listOfLogs.map((item: any, index: number) => (
    <ReportCard
      id={"report-card" + index}
      selected={false}
      height={100}
      disabled={true}
      words={reportFields(item)}
      fontSize={"12px"}
      title={false}
      children={<></>}
    />
  ));

  async function searchReportLogs() {
    if (!customerId) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa de um customer id",
      });
      return;
    }
    if (!dispositivo) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa selecionar um dispositivo",
      });
      return;
    }

    if (!operacao) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa selecionar uma operação",
      });
      return;
    }

    if (initialDate > endDate) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Data inicial não pode ser maior que a data final",
      });
      return;
    }
    dispatch(showLoading());
    setLoading(true);
    let device = dispositivo === "Todos" ? null : dispositivo;
    let operation = operacao === 12 ? null : operacao;

    setListOfLogs([]);
    const response = await ReportsService.getAllLogOperations(
      customerId,
      initialDate + " 00:00",
      endDate + " 23:59",
      operation,
      device
    );

    if (response && response.data && response.data.data)
      setListOfLogs(response.data.data);

    dispatch(hideLoading());
    setLoading(false);
  }

  function onChangeDispositivo(value: any) {
    setDispositivo(value);
  }
  function onChangeOperacao(value: any) {
    setOperacao(value);
  }

  return (
    <Container>
      <ContainerLeftMenu>
        <ReportMenu />
      </ContainerLeftMenu>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <PageHeader
          quantity={listOfLogs.length > 0 ? listOfLogs.length : 0}
          id="header"
          label={"Logs de operações"}
          background="secondary"
          addClick={undefined}
          disabled={false}
        />
        <ContainerBody>
          {read ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "25px",
                }}
              >
                <>
                  <div style={{ marginLeft: 0 }}>
                    <DropdownGlobal
                      labelName="Dispositivo"
                      options={options}
                      setSelected={(value: any) => {
                        if (typeof value === "string")
                          onChangeDispositivo(value);
                      }}
                      defaultValue={"Todos"}
                      showDefaultValue
                      height="24px"
                      width="140px"
                      required={false}
                      withoutMarginBottom
                    />
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <DropdownGlobal
                      labelName="Operação"
                      options={optionsOperation}
                      defaultValue={"Todos"}
                      showDefaultValue
                      setSelected={(value: any) => {
                        if (typeof value === "number") onChangeOperacao(value);
                      }}
                      height="24px"
                      width="140px"
                      required={false}
                      withoutMarginBottom
                    />
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <DateInput
                      label="Data inicial"
                      required={false}
                      value={initialDate}
                      setValue={setInitialDate}
                      disabled={false}
                      width={200}
                    />
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <DateInput
                      label="Data Final"
                      required={false}
                      value={endDate}
                      setValue={setEndDate}
                      disabled={false}
                      width={200}
                    />
                  </div>
                </>
                <div style={{ marginLeft: 12, marginTop: 20 }}>
                  <Button onClick={() => searchReportLogs()}>
                    Gerar relatório
                  </Button>
                </div>
              </div>
              <ContainerList>
                {!loading ? (
                  listOfLogs && listOfLogs.length > 0 ? (
                    <>
                      <ReportCard
                        id="report-card-1"
                        selected={false}
                        height={100}
                        disabled={true}
                        words={reportTitle}
                        fontSize={"14px"}
                        fontWeight="700"
                        title
                        children={<></>}
                        background="#D1DBE3"
                      />
                      <ReactPaginateKenta
                        height="50vh"
                        list={logsOperation}
                        title="Logs de operações"
                        onChangePage={() => { }}
                      />
                    </>
                  ) : (
                    <ListEmpty title="relatório" typeImg="file" />
                  )
                ) : (
                  <LoadSpinner loading={loading} />
                )}
              </ContainerList>
            </>
          ) : (
            <SearchEmpty
              textMessage={"Sem permissão para seu perfil"}
              typeIcon={"avatar"}
              marginTop="30px"
            />
          )}
        </ContainerBody>
      </div>
    </Container>
  );
}
