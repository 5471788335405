/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from "react";

import { Container, LoadingContainer } from "./styles";
import {
  DragAndDrop,
  DrawerDefault,
  InputAuth,
  LoadingComponent,
} from "@kentarepo/kcf-assets";
import { DropdownGlobal } from "../../components/DropdownGlobal";
import { IAttachmentTemplate } from "./interface";
import { IUpload } from "../../store/modules/attachment/interface";
import moment from "moment";
import { v4 } from "uuid";
import { formatYmd } from "../../utils/format";
import { LogOperation } from "../../utils/entities/logOperation";
import { useToast } from "../../hooks/toast";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAttachment,
  closeModalAttachmentFile,
  setAttachmentList,
} from "../../store/modules/attachment/actions";
import { hearingFileService } from "@kentarepo/kcf-services";
import api from "../../services/api";
import { CheckBoxGlobal } from "../../components/CheckBoxGlobal";
import { hearingFileService as hearingFileApi } from "../../services/hearingFile";

const CreateAttachmentTemplate: React.FC<IAttachmentTemplate> = ({
  openModal,
  setOpenModal,
  hearingId,
}) => {
  const { userCustomerProfile, containerName } = useSelector(
    (store: any) => store.profile
  );

  const { closeModal, openEditModal, editItem, attachmentList } = useSelector(
    (store: any) => store.attachment
  );

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState<boolean>(false);
  const [type, setType] = useState<any>();
  const [typeError, setTypeError] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(editItem ? editItem : null);
  const [fileError, setFileError] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [isMainRecording, setIsMainRecording] = useState<boolean>(false);
  const [canBePrincipal, setCanBePrincipal] = useState<boolean>(false);
  const [_openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [typeSelected, setTypeSelected] = useState<any>({
    "video/*": [".avi", ".mp4", ".mpeg", ".wmv"],
  });
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const hearingFileServiceApi = hearingFileService(api);

  function formatFileSize(bytes: number) {
    if (bytes < 1024) {
      return bytes + " bytes";
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes < 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    } else if (bytes < 1024 * 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
    } else {
      return (bytes / (1024 * 1024 * 1024 * 1024)).toFixed(2) + " TB";
    }
  }

  const listTypes = [
    { id: 2, value: "Áudio", name: "Áudio" },
    { id: 4, value: "Documento", name: "Documento" },
    { id: 3, value: "Foto", name: "Foto" },
    { id: 5, value: "Vídeo", name: "Vídeo" },
  ];

  const onChangeType = (e: any) => {
    setType(e);

    if (e) {
      switch (e?.id) {
        case 2:
          setTypeSelected({
            "audio/*": [".wav", ".mp3", ".mp4"],
          });
          break;
        case 3:
          setTypeSelected({
            "image/*": [],
          });
          break;
        case 4:
          setTypeSelected({
            "application/pdf": [".pdf"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              [".docx"],
          });
          break;
        case 5:
          setTypeSelected({
            "video/*": [".avi", ".mp4", ".mpeg", ".wmv"],
          });
          break;

        default:
          setTypeSelected({
            "image/*": [".png", ".svg", ".jpeg", ".jpg"],
            "audio/*": [".wav", ".mp3", ".mp4"],
            "video/*": [".avi", ".mp4", ".mpeg", ".wmv"],
            "application/pdf": [".pdf"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              [".docx"],
          });
          break;
      }
    }
  };

  const handleCloseModal = () => {
    setTypeError(false);
    setNameError(false);
    setFileError(false);
    setOpenModal(false);
    setOpenDeleteModal(false);
    setName("");
    setType(null);
    setFile(null);

    dispatch(closeModalAttachmentFile(false));
  };

  useEffect(() => {
    if (closeModal) handleCloseModal();
  }, [closeModal]);

  useEffect(() => {
    if (editItem) {
      setName(editItem?.filename);
    }
  }, [editItem, openEditModal]);

  useEffect(() => {
    if (hearingId) {
      hearingFileApi.canBePrincipal(hearingId).then((response) => {
        if (response.success) {
          setCanBePrincipal(true)
        } else {
          setCanBePrincipal(false)
        }

      })
    }
  }, [type])


  const handleSaveAttachment = async () => {
    try {
      if (!type || !file || !name.trim()) {
        if (!type) {
          setTypeError(true);
        }

        if (!file) {
          setFileError(true);
        }

        if (!name.trim()) {
          setName(name.trim());
          setNameError(true);
        }

        return addToast({
          type: "warning",
          title: "Preencha todos os campos",
          description:
            "Para salvar um anexo é necessário preencher todos os campos",
        });
      }

      let typeData = "";
      const typeDataAudio = "";
      const date = new Date();
      const folder = type?.name
        .normalize("NFD")
        .replace(/[^a-zA-Z\s]/g, "")
        .toLocaleLowerCase();

      if (folder === "documento") {
        typeData = "application";
      } else if (folder === "foto") {
        typeData = "image";
      } else if (folder === "audio" && file.type.includes("video/mp4")) {
        typeData = "video";
      } else {
        typeData = folder;
      }

      const extension = file?.name?.match(/\.[a-z0-9A-Z]{2,4}/i);

      const filename = `${v4()}_${moment().format(
        "DDMMYYYYHHmmss"
      )}${extension}`;

      if (
        (file && !file.type.includes(typeData)) ||
        (file && !file.type.includes(typeDataAudio))
      ) {
        addToast({
          type: "warning",
          title: "Tipo de arquivo inválido",
          description: "Adicione um tipo de arquivo válido",
        });

        setTypeError(true);
        return;
      }

      setOpenModal(false);

      const payload = {
        type: type?.id,
        title: name,
        filename: `kentaaudiencia\\${date.getFullYear()}\\unidade_${userCustomerProfile?.id
          }\\${folder}\\${filename}`,
        duration: 0,
        fileOrder: 0,
        size: file.size,
        hash: v4(),
        createIn: formatYmd(new Date()),
        updateIn: formatYmd(new Date()),
        upload: true,
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: userCustomerProfile?.id,
        logUserId: LogOperation.getUserId(),
      };

      const fileItem = new FormData();
      fileItem.append("content", file);

      const uploadBody: IUpload = {
        id: payload.filename,
        hearingId: hearingId ? String(hearingId) : "0",
        file: fileItem,
        finished: false,
        payload: payload,
        containerName: containerName,
        isMainRecord: isMainRecording
      };

      dispatch(addNewAttachment(uploadBody));
      handleCloseModal();
    } catch (error) {
      console.log("error", error);
    }
    setIsMainRecording(false)
  };

  const handleUpdateFileName = async () => {
    if (!name.trim()) {
      setName(name.trim());
      setNameError(true);
      return addToast({
        type: "warning",
        title: "Preencha todos os campos",
        description:
          "Para salvar um anexo é necessário preencher todos os campos",
      });
    }

    if (editItem) {
      handleCloseModal();
      setShowLoading(true);
      await hearingFileServiceApi.patch(
        {
          op: "replace",
          path: "title",
          value: name,
        },
        editItem.hearingFileId
      );

      const newList = attachmentList.map((hearingFile: any) => {
        if (hearingFile.hearingFileId === editItem.hearingFileId) {
          return {
            ...editItem,
            filename: name,
          };
        }

        return hearingFile;
      });

      setShowLoading(false);
      dispatch(setAttachmentList(newList));

      return addToast({
        type: "success",
        title: "Anexo",
        description: "Anexo atualizado com sucesso",
      });
    }
  };

  return (
    <Fragment>
      <DrawerDefault
        openDrawer={openModal || openEditModal}
        haveFooter
        setOpenDrawer={() => { }}
        onClose={handleCloseModal}
        onApply={openEditModal ? handleUpdateFileName : handleSaveAttachment}
        title={`${openEditModal ? "Editar" : "Criar"} anexo`}
      >
        <Container>
          <InputAuth
            id="input_file_name"
            width={380}
            error={nameError}
            label="Nome do arquivo"
            required
            onChange={setName}
            value={name}
          />
          <DropdownGlobal
            labelName="Tipo de arquivo"
            required
            setSelected={(e: any) => onChangeType(e)}
            options={listTypes}
            showDefaultValue={!!editItem}
            disabled={openEditModal}
            defaultValue={
              editItem
                ? listTypes.find((x) => x?.id === editItem.type)?.name
                : ""
            }
            wantAnObject
            error={typeError}
          />
          {type && type.name === "Vídeo" && canBePrincipal &&
            <div style={{ margin: '15px 0' }}>
              <CheckBoxGlobal
                checked={isMainRecording}
                id={1}
                label={'É a gravação principal?'}
                onChecked={() => setIsMainRecording(!isMainRecording)}
              />
            </div>
          }
          <DragAndDrop
            editItem={{ ...editItem, name: editItem?.filename }}
            fileError={fileError}
            formatFileSize={formatFileSize}
            isEdit={openEditModal}
            onDrop={(file) => setFile(file[0])}
            file={editItem ? { ...file, name: editItem?.filename } : file}
            setFile={setFile as any}
            typeSelected={typeSelected}
            type={type}
          />
        </Container>
      </DrawerDefault>
      {showLoading && (
        <LoadingContainer>
          <LoadingComponent loading={showLoading} />
        </LoadingContainer>
      )}
    </Fragment>
  );
};

export default CreateAttachmentTemplate;
