/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from "react";
import { IContainerCrud } from "./interface";
import { ManagementListsAdmin } from "../ManagementListsAdmin";
import { clearStates, handleAddButton } from "./constants";
import { modelForm } from "../../forms/optionsForms";
import { toastOption } from "../../../global/types";

import {
  DrawerDefault,
  PageHeader,
  SimpleToast,
} from "@kentarepo/kcf-assets";

export const ContainerCrud: React.FC<IContainerCrud> = ({
  list,
  permissions,
  title,
  emptyListMessage,
  optionForm,
  customProsForm,
  modelFormBase,
  loading,
  isAudience,
}) => {
  const [listState, setListState] = useState<Array<any>>([]);

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [clickToEdit, setClickToEdit] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(list && list.length);
  const [updateList, setUpdateList] = useState<number>(0);

  //Toast
  const [toastTrigger, setToastTrigger] = useState<number>(0);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastType, setToastType] = useState<toastOption>("warning");

  useEffect(() => {
    if (
      modelFormBase.objectRetorned !== null &&
      modelFormBase.onSubmitForm > 0
    ) {
      const listToAddorUpdate = listState;
      if (!isEdit) {
        listToAddorUpdate.push(modelFormBase.objectRetorned);
        setListState(listToAddorUpdate);
        setUpdateList(updateList + 1);
        configureToast(`${title} foi criada com sucesso`, "success");
      } else {
        const index = listToAddorUpdate.findIndex(
          (obj) => obj?.id === modelFormBase.objectRetorned?.id
        );
        if (index !== -1) {
          listToAddorUpdate[index] = modelFormBase.objectRetorned;
          setListState(listToAddorUpdate);
          setUpdateList(updateList + 1);
          configureToast(`${title} foi atualizada com sucesso`, "success");
        } else {
          configureToast("Ocorreu um erro inesperado", "error");
        }
      }
      modelFormBase.list = listState;

      setOpenDrawer(false);
      setIsEdit(false);
      modelFormBase.setOnSubmitForm(0);
      modelFormBase.setObjectRetorned(null);
    }
  }, [modelFormBase.objectRetorned]);

  useEffect(() => {
    if (modelFormBase.objectRetorned !== null) {
      modelFormBase.setOnSubmitForm(0);
      setIsEdit(true);
      setOpenDrawer(true);

      customProsForm.modelFormBase.setHttpType("put");
    }
    modelFormBase.setOnSubmitForm(0);
  }, [clickToEdit]);

  useEffect(() => {
    if (list && list.length) setListState(list);
  }, [list]);

  const handleOpenDrawerAdd = () => {
    handleAddButton(
      setOpenDrawer,
      setIsEdit,
      customProsForm.modelFormBase.setHttpType
    );
    modelFormBase.setObjectRetorned(null);
    modelFormBase.setOnSubmitForm(0);
  };

  const handleCloseDrawer = () => {
    clearStates(setOpenDrawer, setIsEdit, modelFormBase.setObjectRetorned);
    modelFormBase.setOnSubmitForm(0);
  };

  const handleApplyButton = async () => {
    modelFormBase.setOnSubmitForm(modelFormBase.onSubmitForm + 1);
  };

  const configureToast = (
    toastBodyMessage: string,
    toastOption: toastOption
  ) => {
    setToastBody(toastBodyMessage);
    setToastType(toastOption);
    setToastTrigger(toastTrigger + 1);
  };

  return (
    <>
      <SimpleToast
        id="attendantRoleToastId"
        title={title}
        type={toastType}
        description={toastBody}
        handleCloseToast={() => { }}
        trigger={toastTrigger}
      />

      <Fragment>
        <PageHeader
          quantity={permissions.toRead ? quantity : 0}
          id="headerAdminArea"
          label={title}
          background="secondary"
          addClick={
            permissions.toCreate ? () => handleOpenDrawerAdd() : undefined
          }
        />
        <ManagementListsAdmin
          emptyMessage={emptyListMessage}
          list={listState}
          permitions={permissions}
          setObjectRetorned={modelFormBase.setObjectRetorned}
          setClickToEdit={setClickToEdit}
          setQuantity={setQuantity}
          updateList={updateList}
          clickToEdit={clickToEdit}
          titleToast={title}
          loading={loading}
          isAudience={isAudience}
        />
        <DrawerDefault
          title={isEdit ? `Editar ${title.toLowerCase()}` : `Cadastrar  ${title.toLowerCase()}`}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          onClose={handleCloseDrawer}
          onApply={handleApplyButton}
          children={modelForm(optionForm, modelFormBase, customProsForm)}
        />
      </Fragment>
    </>
  );
};
