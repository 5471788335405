// import styled from "styled-components";

import { Box } from "@mui/material";
import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 75.3vh;
  overflow-y: auto;
`;

export const ContainerBody = styled.div`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 12px;
  margin-bottom: 22px;
  margin-top: 20px;
`;
export const CheckContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const ContainerSwitch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  margin-top: 25px;
`;
export const Drawer = styled.div`
  width: 100%;
  border: 1px solid #d1dbe3;
  margin-bottom: 26px;
`;
export const Title = styled.label`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
`;

export const ListProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;


export const ContainerFormOneLabel = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const ContainerFormOneLabelSwitch = styled(Box)`
  margin-bottom: 12px;
  display: flex;
  margin-top: -10px;
  margin-left: -9px;
`;
export const ContainerLabelSwitch = styled(Box)`
  margin-bottom: 12px;
  display: flex;
  margin-top: 10px;
  margin-left: -9px;
`;
