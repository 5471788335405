import styled, { css } from "styled-components";
import { Typography as TypographyMui } from "@mui/material";
import { IDashboard } from "./interface";

interface IListProps {
  disabled?: boolean;
}

interface IBoxTime {
  id: number;
  selected: boolean;
}

export const Container = styled.div<IDashboard>`
  width: ${(props) => (props.isFull ? "100%" : "calc(100% - 350px)")};
  text-transform: capitalize !important;
  background: #eef2f6;
  button {
    background: none;
    outline: none;
    border: none;

    cursor: pointer;
  }
`;

export const Content = styled.div<IListProps>`
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  padding: 8px;
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  gap: 8px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 20px);
  height: 40px;
  border-bottom: 2px solid #eee;
  padding: 10px;
  background: #fff;
  border-radius: 10px;

  strong {
    color: #7887a9;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ContainerByDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BoxTime = styled.div<IBoxTime>`
  width: 64px;
  text-align: center;
  border: 1px solid #3e424e66;
  font-family: "Inter", sans-serif;
  background: #fff;
  font-weight: 500;
  font-size: 14px;
  padding: 10px;
  ${(props) => {
    switch (props?.id) {
      case 1:
        return css`
          border-radius: 8px 0 0 8px;
        `;
      case 2:
        return css`
          border-left: 0;
          border-right: 0;
        `;
      case 3:
        return css`
          border-radius: 0 8px 8px 0;
        `;
      default:
        break;
    }
  }}

  ${(props) =>
    props.selected &&
    css`
      background: #1d9bf0;
      color: #fff;
    `}

  &:hover {
    background: #00000022;
    cursor: pointer;
  }
`;

export const Typography = styled(TypographyMui)`
  text-transform: capitalize;
`;

export const BottomContainer = styled.div`
  width: 100%;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
  padding-bottom: 6px;

  div {
    display: flex;
    align-items: center;
  }
`;

export const BodyBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #eee;

  strong {
    color: #3e424e66;
  }

  span {
    color: #ff7300;
    font-weight: 700;
    padding: 10px 20px;
  }
`;

export const BodyBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 190px;
  overflow-y: auto;
`;
